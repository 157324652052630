/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SharedTable from "../../Table";
import { getCurrencyString } from "../../../utils";
import RightCaretSvg from "../../../assets/RightCaret.svg";
import styles from "./styles.module.css";

function LedgerTable({ data, headers }) {
    const navigate = useNavigate();

    const rows = data.map((entry) => {
        const { ledger_name, proportion, amount_cents, ledger_id } = entry;
        const onClick = () => {
            navigate(`ledgers/${ledger_id}`);
        };
        return [
            <span className={styles.category}>{ledger_name}</span>,
            `${proportion.toFixed(1)}%`,
            getCurrencyString(amount_cents),
            <div className={styles.arrowContainer}>
                <img
                    className={styles.arrow}
                    src={RightCaretSvg}
                    alt="RightCaretSvg"
                    onClick={onClick}
                />
            </div>,
        ];
    });

    return <SharedTable headers={headers} rows={rows} />;
}

LedgerTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default LedgerTable;
