/* eslint-disable camelcase */
import React, { useRef } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "../Button";
import raiseError from "../../redux/slices/error/thunks/raiseError";
import useLazyGetPlaidLinkTokenQuery from "../../hooks/queriesAndMutations/useLazyGetPlaidLinkTokenQuery";
import useAddPlaidItemMutation from "../../hooks/queriesAndMutations/useAddPlaidItemMutation";
import useUpdatePlaidItemMutation from "../../hooks/queriesAndMutations/useUpdatePlaidItemMutation";

function PlaidLinkButton({ plaidItemId, color }) {
    const plaidHandlerRef = useRef(null);

    const [triggerAddPlaidItemMutation] = useAddPlaidItemMutation();
    const [triggerUpdatePlaidItemMutation] = useUpdatePlaidItemMutation();

    const onLinkSuccess = (publicToken) => {
        if (plaidItemId) {
            triggerUpdatePlaidItemMutation(plaidItemId);
        } else {
            triggerAddPlaidItemMutation(publicToken);
        }

        plaidHandlerRef.current.destroy();
    };

    const dispatch = useDispatch();

    const onExit = (error) => {
        if (error) {
            Sentry.captureException(error);
        }
        plaidHandlerRef.current.destroy();
    };

    const [triggerGetPlaidLinkToken] = useLazyGetPlaidLinkTokenQuery();

    const onClick = async () => {
        let linkToken;
        try {
            const result = await triggerGetPlaidLinkToken(plaidItemId).unwrap();
            linkToken = result.link_token;
        } catch (error) {
            // We don't need to raiseException here because middleware will handle exceptions
            return;
        }

        try {
            const config = {
                token: linkToken,
                onSuccess: onLinkSuccess,
                onExit,
            };
            // eslint-disable-next-line no-undef
            const handler = Plaid.create(config);
            plaidHandlerRef.current = handler;
            plaidHandlerRef.current.open();
        } catch (error) {
            dispatch(raiseError(error));
        }
    };

    const buttonText = plaidItemId ? "Restore Connection" : "Add Connection";

    return (
        <Button color={color} onClick={onClick}>
            {buttonText}
        </Button>
    );
}

PlaidLinkButton.propTypes = {
    plaidItemId: PropTypes.string,
    color: PropTypes.string,
};

PlaidLinkButton.defaultProps = {
    plaidItemId: undefined,
    color: "primary",
};

export default PlaidLinkButton;
