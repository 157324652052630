import React from "react";
import dayjs from "dayjs";
import Alert from "../../Alert";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";

function ValidUpUntil() {
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    if (!validUpUntilData) {
        return null;
    }

    const validUpUntilDate = dayjs(validUpUntilData.valid_up_until);
    const formattedDate = validUpUntilDate.format("MMMM D, YYYY");
    const nextMonth = validUpUntilDate.add(1, "month").format("MMMM YYYY");
    const title = `Your books are valid up until ${formattedDate}`;
    const body = `Our bookkeepers have reviewed your books up until ${formattedDate}. You won't see any data for ${nextMonth} until we close your books for the month.`;

    return (
        <Alert severity="info" variant="outlined" title={title} body={body} />
    );
}

export default ValidUpUntil;
