import React from "react";
import Button from "../Button";
import { useSavePlaidItemsMutation } from "../../redux/slices/api";
import SharedConnections from "../Connections";
import useGetPlaidItemsQuery from "../../hooks/queriesAndMutations/useGetPlaidItemsQuery";
import Alert from "../Alert";
import SectionTitle from "../SectionTitle";
import styles from "./styles.module.css";

function ConnectPlaidItems() {
    const [savePlaidItems] = useSavePlaidItemsMutation();

    const onSaveAndContinue = () => {
        savePlaidItems();
    };

    const { data: plaidItems } = useGetPlaidItemsQuery();

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>
                    Please connect your business bank accounts and corporate
                    cards
                </SectionTitle>
            </div>
            <div className={styles.subtitle}>
                Once you&apos;ve connected all business bank accounts and
                corporate cards, please click &quot;Save & Continue&quot;.
            </div>
            <div className={styles.alertContainer}>
                <Alert
                    severity="info"
                    variant="outlined"
                    title="Multiple business entities?"
                    body="If you have more than one business entity, please connect the business bank accounts and corporate cards for all entities."
                />
            </div>
            <SharedConnections />
            <div className={styles.saveAndContinueButton}>
                <Button
                    color="secondary"
                    onClick={onSaveAndContinue}
                    disabled={!plaidItems || plaidItems.length === 0}
                >
                    Save & Continue
                </Button>
            </div>
        </div>
    );
}

export default ConnectPlaidItems;
