import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

function TextInput({ name, label, control, error, placeholder }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    variant="standard"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label={label}
                    error={error.message !== undefined}
                    helperText={error.message}
                    placeholder={placeholder}
                />
            )}
        />
    );
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    placeholder: PropTypes.string,
};

TextInput.defaultProps = {
    error: {},
    placeholder: "",
};

export default TextInput;
