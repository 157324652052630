import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../PageTitle";
import Button from "../../../Button";
import WomanSmilingPng from "../../../../assets/WomanSmiling.png";
import Bullet from "./Bullet";
import styles from "./styles.module.css";

const bullets = [
    {
        number: 1,
        description:
            "Schedule a free intro call with Rivet to get a quote and timeline for the filing.",
    },
    {
        number: 2,
        description:
            "Flychain works directly with Rivet to share your financials and any other required documentation.",
    },
    {
        number: 3,
        description:
            "Your taxes are filed seamlessly to minimize the work on your end while maximizing your tax savings.",
    },
];

function GettingStarted({ onClick }) {
    return (
        <div className={styles.container}>
            <div className={styles.leftSectionContainer}>
                <img
                    className={styles.image}
                    src={WomanSmilingPng}
                    alt="WomanSmiling"
                />
            </div>
            <div className={styles.rightSectionContainer}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>How To Get Started</PageTitle>
                </div>
                {bullets.map((bullet) => (
                    <div key={bullet.number} className={styles.bulletContainer}>
                        <Bullet
                            number={bullet.number}
                            description={bullet.description}
                        />
                    </div>
                ))}
                <div>
                    <Button color="secondary" onClick={onClick}>
                        Get Started
                    </Button>
                </div>
            </div>
        </div>
    );
}

GettingStarted.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default GettingStarted;
