import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LeftArrowSvg from "../../../../assets/LeftArrow";
import RightArrowSvg from "../../../../assets/RightArrow";
import COLORS from "../../../../styles/colors";
import styles from "./styles.module.css";

function YearPicker({ year, onChange, maxDate }) {
    const onLeftClick = () => {
        onChange(year - 1);
    };

    const disableRightArrow = year >= maxDate.year();
    const onRightClick = () => {
        if (!disableRightArrow) {
            onChange(year + 1);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.arrowIconContainer} onClick={onLeftClick}>
                <LeftArrowSvg fill={COLORS.BLUE_PURPLE} />
            </div>
            <div className={styles.year}>{year}</div>
            <div
                className={classNames(styles.arrowIconContainer, {
                    [styles.disabled]: disableRightArrow,
                })}
                onClick={onRightClick}
            >
                <RightArrowSvg
                    fill={disableRightArrow ? "#F0F0F0" : COLORS.BLUE_PURPLE}
                />
            </div>
        </div>
    );
}

YearPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default YearPicker;
