import React from "react";
import { useParams } from "react-router-dom";
import LiveAdvancedPaymentOnClaims from "./LiveAdvancedPaymentOnClaims";
import { useGetLineForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/adminApi";
import AdvancedPaymentOnClaimsLanding from "../../Capital/AdvancedPaymentOnClaims/Landing";

const displayLiveOrLanding = (line) => {
    if (line.id) {
        return <LiveAdvancedPaymentOnClaims />;
    }
    return <AdvancedPaymentOnClaimsLanding />;
};

function AdvancedPaymentOnClaims() {
    const { providerId } = useParams();
    const { data: line } =
        useGetLineForAdvancedPaymentOnClaimsQuery(providerId);
    return line ? displayLiveOrLanding(line) : null;
}

export default AdvancedPaymentOnClaims;
