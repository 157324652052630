import React from "react";
import useGetExpensesByMerchantQuery from "../../../../hooks/queriesAndMutations/useGetExpensesByMerchantQuery";
import Table from "./Table";

function ExpensesByMerchant() {
    const { data } = useGetExpensesByMerchantQuery();

    return data ? <Table data={data} /> : null;
}

export default ExpensesByMerchant;
