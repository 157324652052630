import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../PageTitle";
import Button from "../../Button";
import styles from "./styles.module.css";

function ConfirmSuccessfulSchedule({ onClose }) {
    return (
        <div className={styles.container}>
            <PageTitle>Thank you for scheduling a call!</PageTitle>
            <div className={styles.subtitle}>
                A calendar invite has been sent to your email address - we look
                forward to chatting with you soon. If you need to reschedule,
                you can use the reschedule link in the confirmation email or
                calendar invite.
            </div>
            <div className={styles.buttonContainer}>
                <Button onClick={onClose}>Done</Button>
            </div>
        </div>
    );
}

ConfirmSuccessfulSchedule.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmSuccessfulSchedule;
