import React from "react";
import PropTypes from "prop-types";

function ValuationsSvg({ fill }) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 3V7"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 7H6C5.44772 7 5 7.44772 5 8V12C5 12.5523 5.44772 13 6 13H8C8.55228 13 9 12.5523 9 12V8C9 7.44772 8.55228 7 8 7Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 13V15"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 1V3"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 3H14C13.4477 3 13 3.44772 13 4V10C13 10.5523 13.4477 11 14 11H16C16.5523 11 17 10.5523 17 10V4C17 3.44772 16.5523 3 16 3Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 11V14"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 1V19H19"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ValuationsSvg.propTypes = {
    fill: PropTypes.string.isRequired,
};

export default ValuationsSvg;
