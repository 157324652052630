import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectDateRange,
    serializeDateRange,
} from "../../../redux/slices/dateRange";
import { useGetDataForTopExpensesGraphQuery as useAdminGetDataForTopExpensesGraphQuery } from "../../../redux/slices/adminApi";
import { useGetDataForTopExpensesGraphQuery as useProviderGetDataForTopExpensesGraphQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetDataForTopExpensesGraphQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const dateRange = useSelector(selectDateRange);

    if (isAdminDashboard) {
        return useAdminGetDataForTopExpensesGraphQuery({
            providerId,
            businessEntityId,
            ...serializeDateRange(dateRange),
        });
    }

    return useProviderGetDataForTopExpensesGraphQuery({
        businessEntityId,
        ...serializeDateRange(dateRange),
    });
};

export default useGetDataForTopExpensesGraphQuery;
