import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Stat({ title, subtitle }) {
    return (
        <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
        </div>
    );
}

Stat.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default Stat;
