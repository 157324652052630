import { useParams } from "react-router-dom";
import { useGetMonthlyExpensesByCategoryQuery as useAdminGetMonthlyExpensesByCategoryQuery } from "../../../redux/slices/adminApi";
import { useGetMonthlyExpensesByCategoryQuery as useProviderGetMonthlyExpensesByCategoryQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetMonthlyExpensesByCategoryQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetMonthlyExpensesByCategoryQuery({
            providerId,
            businessEntityId,
        });
    }

    return useProviderGetMonthlyExpensesByCategoryQuery(businessEntityId);
};

export default useGetMonthlyExpensesByCategoryQuery;
