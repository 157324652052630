import * as Sentry from "@sentry/react";
import { THEME, THEMES } from "../themes";

const getSentryDsn = () => {
    switch (THEME) {
        case THEMES.FLYCHAIN:
            return process.env.REACT_APP_FLYCHAIN_SENTRY_DSN;
        case THEMES.JUNIPER:
            return process.env.REACT_APP_JUNIPER_SENTRY_DSN;
        case THEMES.COACHCARE:
            return process.env.REACT_APP_COACHCARE_SENTRY_DSN;
        case THEMES.HEALTHREV:
            return process.env.REACT_APP_HEALTHREV_SENTRY_DSN;
        case THEMES.RETHINK:
            return process.env.REACT_APP_RETHINK_SENTRY_DSN;
        case THEMES.PARIO:
            return process.env.REACT_APP_PARIO_SENTRY_DSN;
        case THEMES.ENDUE:
            return process.env.REACT_APP_ENDUE_SENTRY_DSN;
        default:
            throw new Error("Invalid theme");
    }
};

Sentry.init({
    dsn: getSentryDsn(),
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                "https://flychain-backend-production.herokuapp.com/api",
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
