import firebase from "firebase/compat/app";

const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

export async function blobResponseHandler(response) {
    const blob = await response.blob();
    // blob is not serializable so need to convert to base64 before storing in redux.
    const base64 = await blobToBase64(blob);
    return base64;
}

export const prepareHeaders = async (headers) => {
    const idToken = await firebase.auth().currentUser.getIdToken();
    headers.set("authorization", `Bearer ${idToken}`);
};
