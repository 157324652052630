import React from "react";
import PropTypes from "prop-types";
import Table from "../Table";

export const qualificationRequirementsTitles = {
    TIME_IN_BUSINESS: "Time In Business",
    FICO: "Business Owner FICO",
    CLAIMS: "Claims Data",
};

function QualificationRequirements({ rows }) {
    return <Table title="Qualification Requirements" rows={rows} />;
}

QualificationRequirements.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    ).isRequired,
};

export default QualificationRequirements;
