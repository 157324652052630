import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function AccountsConnected({ numberOfAccounts }) {
    return (
        <div className={styles.numberOfAccounts}>
            {`${numberOfAccounts} connected`}
        </div>
    );
}

AccountsConnected.propTypes = {
    numberOfAccounts: PropTypes.number.isRequired,
};

export default AccountsConnected;
