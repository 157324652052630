import React from "react";
import SharedCashFlow from "../../../FinancialReports/CashFlow";
import SelectedProvider from "../SelectedProvider";

function CashFlow() {
    return (
        <div>
            <SelectedProvider />
            <SharedCashFlow />
        </div>
    );
}

export default CashFlow;
