import React from "react";
import { useGetDataForAdvancedPaymentOnClaimsQuery } from "../../../../redux/slices/api";
import AdvancedPaymentOnClaimsPresentational from "../../../Capital/AdvancedPaymentOnClaims";

function LiveAdvancedPaymentOnClaims() {
    const { data } = useGetDataForAdvancedPaymentOnClaimsQuery();
    return data ? <AdvancedPaymentOnClaimsPresentational data={data} /> : null;
}

export default LiveAdvancedPaymentOnClaims;
