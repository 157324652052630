/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getFinancialReportsValidUpUntil as getFinancialReportsValidUpUntilAdmin } from "../adminApi";
import { getFinancialReportsValidUpUntil } from "../api";
import resetProviderState from "../actions";

export const serializeDateRange = (dateRange) => {
    const { startDate, endDate } = dateRange;
    return {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
    };
};

const getDateRangeFromValidUpUntil = (state, action) => {
    if (state.startDate && state.endDate) {
        return state;
    }
    const validUpUntilDate = dayjs(action.payload.valid_up_until);
    const startDate = validUpUntilDate.startOf("month").format("YYYY-MM-DD");
    const endDate = validUpUntilDate.format("YYYY-MM-DD");
    return {
        startDate,
        endDate,
    };
};

const initialState = {
    startDate: null,
    endDate: null,
};

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState,
    reducers: {
        updateDateRange(state, action) {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                getFinancialReportsValidUpUntilAdmin.matchFulfilled,
                getDateRangeFromValidUpUntil
            )
            .addMatcher(
                getFinancialReportsValidUpUntil.matchFulfilled,
                getDateRangeFromValidUpUntil
            )
            .addMatcher(
                (action) => action.type === resetProviderState.type,
                () => initialState
            );
    },
});

export const selectDateRange = createSelector(
    (state) => state.dateRange,
    (dateRange) => {
        const { startDate, endDate } = dateRange;
        return {
            startDate: dayjs(startDate),
            endDate: dayjs(endDate),
        };
    }
);

export const { updateDateRange } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
