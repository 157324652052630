import { useParams } from "react-router-dom";
import { useGetPlaidItemsQuery as useAdminGetPlaidItemsQuery } from "../../../redux/slices/adminApi";
import { useGetPlaidItemsQuery as useProviderGetPlaidItemsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetPlaidItemsQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetPlaidItemsQuery(providerId);
    }

    return useProviderGetPlaidItemsQuery();
};

export default useGetPlaidItemsQuery;
