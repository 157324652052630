import React from "react";
import PropTypes from "prop-types";
import { useGetBusinessEntitiesQuery } from "../../../../redux/slices/adminApi";
import SectionTitle from "../../../SectionTitle";
import BusinessEntity from "./BusinessEntity";
import styles from "./styles.module.css";

function Provider({ provider }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery(provider.id);

    return businessEntities ? (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>{provider.legal_name}</SectionTitle>
            </div>
            {businessEntities.map((businessEntity) => (
                <BusinessEntity
                    key={businessEntity.id}
                    businessEntity={businessEntity}
                />
            ))}
        </div>
    ) : null;
}

Provider.propTypes = {
    provider: PropTypes.object.isRequired,
};

export default Provider;
