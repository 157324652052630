import { useParams } from "react-router-dom";
import { useLazyDownloadExpensesByCategoryQuery as useAdminLazyDownloadExpensesByCategoryQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadExpensesByCategoryQuery as useProviderLazyDownloadExpensesByCategoryQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyDownloadExpensesByCategoryQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] =
        useAdminLazyDownloadExpensesByCategoryQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadExpensesByCategoryQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const triggerWithParams = (dateRange) => {
        const finalParams = { ...preloadParams, ...dateRange };

        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }

        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadExpensesByCategoryQuery;
