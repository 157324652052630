import { useEffect } from "react";
import useGetReportFilename from "../useGetReportFilename";

const useDownloadFile = (fileBase64) => {
    const filename = useGetReportFilename();

    useEffect(() => {
        if (fileBase64) {
            // Create a link to download the base64 data
            const link = document.createElement("a");
            link.href = fileBase64;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            // Clean up
            link.parentNode.removeChild(link);
        }
    }, [fileBase64]);
};

export default useDownloadFile;
