import React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import SectionTitle from "../../SectionTitle";
import DateRangePicker from "../../DateRangePicker";
import ReportBlock from "../ReportBlock";
import ExportReportButton from "../ExportReportButton";
import Summary from "./Summary";
import {
    updateDateRange,
    serializeDateRange,
    selectDateRange,
} from "../../../redux/slices/dateRange";
import useGetDataForCashFlowReportQuery from "../../../hooks/queriesAndMutations/useGetDataForCashFlowReportQuery";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetCashFlowGraph from "./NetCashFlowGraph";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import styles from "./styles.module.css";

function CashFlow() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: cashFlowReportData } = useGetDataForCashFlowReportQuery();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Net Cash Flow by Month</SectionTitle>
            </div>
            <div className={styles.netCashFlowGraphContainer}>
                <NetCashFlowGraph />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Cash Flow Report</SectionTitle>
            </div>
            <div className={styles.controlsContainer}>
                <div className={styles.dateRangePickerContainer}>
                    <DateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                    />
                </div>
                <ExportReportButton />
            </div>
            {cashFlowReportData ? (
                <>
                    <div className={styles.summaryContainer}>
                        <Summary data={cashFlowReportData.summary} />
                    </div>
                    {cashFlowReportData.report_blocks.map(
                        (reportLines, index) => (
                            <div
                                className={styles.reportBlockContainer}
                                key={index}
                            >
                                <ReportBlock
                                    reportLines={reportLines}
                                    maxRecordCategory="subtype"
                                />
                            </div>
                        )
                    )}
                </>
            ) : null}
        </div>
    );
}

export default CashFlow;
