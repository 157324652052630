import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import themeStyles from "../../../../themes/styles";

function Subtab({ path, label }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(path);
    };

    const location = useLocation();
    const containerStyle = classNames(styles.container, {
        [themeStyles.currentTab]: location.pathname.startsWith(path),
    });

    return (
        <div className={containerStyle} onClick={onClick}>
            <div className={`${styles.label} ${themeStyles.tabText}`}>
                {label}
            </div>
        </div>
    );
}

Subtab.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Subtab;
