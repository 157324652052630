import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import REQUIRED_MESSAGE from "../Inputs/constants";
import { MONTHS, STATES, ENTITY_TYPES } from "./constants";
import Button from "../Button";
import { useUpsertProviderMutation } from "../../redux/slices/api";
import { THEME } from "../../themes";
import SectionTitle from "../SectionTitle";
import Alert from "../Alert";
import styles from "./styles.module.css";

const schema = yup
    .object({
        legalName: yup.string().required(REQUIRED_MESSAGE),
        dba: yup.string(),
        taxId: yup
            .string()
            .matches(/^\d{9}$/, "Must be 9 numbers (no hyphens)")
            .required(REQUIRED_MESSAGE),
        npi: yup
            .string()
            .matches(/^\d{10}$/, "Must be 10 numbers (no hyphens)")
            .required(REQUIRED_MESSAGE),
        businessStartDateMonth: yup.string().required(REQUIRED_MESSAGE),
        businessStartDateYear: yup
            .string()
            .matches(/^\d{4}$/, "Must be YYYY")
            .required(REQUIRED_MESSAGE),
        businessEntityType: yup.string().required(REQUIRED_MESSAGE),
        streetAddress: yup.string().required(REQUIRED_MESSAGE),
        city: yup.string().required(REQUIRED_MESSAGE),
        state: yup.string().required(REQUIRED_MESSAGE),
        postalCode: yup
            .string()
            .matches(/^\d{5}$/, "Must be 5 numbers")
            .required(REQUIRED_MESSAGE),
        contactFullName: yup.string().required(REQUIRED_MESSAGE),
        contactEmail: yup
            .string()
            .email("Invalid email address")
            .required(REQUIRED_MESSAGE),
        contactPhone: yup
            .string()
            .matches(
                /^\d{10,11}$/,
                "Must be 10 or 11 numbers (no parentheses or hypens)"
            )
            .required(REQUIRED_MESSAGE),
    })
    .required();

// const defaultValues = {
//     legalName: "Flychain, Inc.",
//     dba: "",
//     taxId: "987654321",
//     npi: "1234567890",
//     businessStartDateMonth: "June",
//     businessStartDateYear: "2020",
//     businessEntityType: "CORPORATION",
//     streetAddress: "141 E 55th St, Apt 11C",
//     city: "New York",
//     state: "NY",
//     postalCode: "10017",
//     contactFullName: "Jaime Deverall",
//     contactEmail: "jaime@flychain.us",
//     contactPhone: "2012141168",
// };

const defaultValues = {
    legalName: "",
    dba: "",
    taxId: "",
    npi: "",
    businessStartDateMonth: "",
    businessStartDateYear: "",
    businessEntityType: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    contactFullName: "",
    contactEmail: "",
    contactPhone: "",
};

function ProviderDetails() {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const [upsertProvider] = useUpsertProviderMutation();

    const onSubmit = (formData) => {
        const body = {
            legal_name: formData.legalName,
            dba: formData.dba,
            tax_id: formData.taxId,
            npi: formData.npi,
            business_start_date_month: formData.businessStartDateMonth,
            business_start_date_year: formData.businessStartDateYear,
            business_entity_type: formData.businessEntityType,
            street_address: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            postal_code: formData.postalCode,
            contact_full_name: formData.contactFullName,
            contact_email: formData.contactEmail,
            contact_phone: formData.contactPhone,
            partner_internal_name: THEME,
        };
        upsertProvider(body);
    };

    const triggerSubmit = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <div className={styles.container}>
            <Alert
                severity="info"
                variant="outlined"
                title="Multiple business entities?"
                body="If you have more than one business entity, please fill out the information below for the parent entity."
            />
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Business Overview</SectionTitle>
            </div>
            <div className={styles.input}>
                <TextInput
                    name="legalName"
                    label="Business legal name *"
                    control={control}
                    error={errors.legalName}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="dba"
                    label="Doing business as (DBA)"
                    control={control}
                    error={errors.dba}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="taxId"
                    label="Business Tax ID/EIN *"
                    control={control}
                    error={errors.taxId}
                    placeholder="XXXXXXXXX"
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="npi"
                    label="National Provider Identifier (NPI) *"
                    control={control}
                    error={errors.npi}
                    placeholder="XXXXXXXXXX"
                />
            </div>
            <div className={styles.input}>
                <SelectInput
                    name="businessStartDateMonth"
                    label="Business start date (month) *"
                    options={MONTHS}
                    control={control}
                    error={errors.businessStartDateMonth}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="businessStartDateYear"
                    label="Business start date (year) *"
                    control={control}
                    error={errors.businessStartDateYear}
                />
            </div>
            <div className={styles.input}>
                <SelectInput
                    name="businessEntityType"
                    label="Business entity type *"
                    options={ENTITY_TYPES}
                    control={control}
                    error={errors.businessEntityType}
                />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Business Address</SectionTitle>
            </div>
            <div className={styles.input}>
                <TextInput
                    name="streetAddress"
                    label="Street address *"
                    control={control}
                    error={errors.streetAddress}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="city"
                    label="City *"
                    control={control}
                    error={errors.city}
                />
            </div>
            <div className={styles.input}>
                <SelectInput
                    name="state"
                    label="State *"
                    options={STATES}
                    control={control}
                    error={errors.state}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="postalCode"
                    label="Postal code *"
                    control={control}
                    error={errors.postalCode}
                />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Contact Information</SectionTitle>
            </div>
            <div className={styles.input}>
                <TextInput
                    name="contactFullName"
                    label="Contact full name *"
                    control={control}
                    error={errors.contactFullName}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="contactEmail"
                    label="Contact email *"
                    control={control}
                    error={errors.contactEmail}
                />
            </div>
            <div className={styles.input}>
                <TextInput
                    name="contactPhone"
                    label="Contact phone number *"
                    control={control}
                    error={errors.contactPhone}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button color="primary" onClick={triggerSubmit}>
                    Submit
                </Button>
            </div>
            {Object.keys(errors).length > 0 && (
                <div className={styles.errorContainer}>
                    <div className={styles.error}>
                        There was an error in the form. Errors are shown under
                        each field.
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProviderDetails;
