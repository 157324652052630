export const getUTCDateString = (date) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
    };
    return date.toLocaleString("en-US", options);
};

export const getDateString = (date) => {
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return date.toLocaleString("en-US", options);
};

export const getCurrencyString = (cents) => {
    const dollars = cents / 100;
    return dollars.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};
