/* eslint-disable camelcase */

const getInternalPlaidItemId = (plaidItems, externalPlaidAccountId) => {
    const plaidItem = plaidItems.find((item) => {
        const { accounts } = item;
        const plaidAccount = accounts.find(
            (account) => account.account_id === externalPlaidAccountId
        );
        if (plaidAccount) {
            return true;
        }
        return false;
    });

    if (!plaidItem) {
        throw new Error(
            `No plaid item found that contains an account with id = ${externalPlaidAccountId}`
        );
    }

    return plaidItem.internal_plaid_item_id;
};

const getPostBody = (plaidItems, externalPlaidAccountId) => {
    const internalPlaidItemId = getInternalPlaidItemId(
        plaidItems,
        externalPlaidAccountId
    );
    const body = {
        internal_plaid_item_id: internalPlaidItemId,
        external_plaid_account_id: externalPlaidAccountId,
    };
    return body;
};

export default getPostBody;
