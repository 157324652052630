import React, { useState } from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ExpensesByCategory from "./ExpensesByCategory";
import HorizontalTabs from "../../HorizontalTabs";
import ExpensesByMerchant from "./ExpensesByMerchant";
import Badge from "../../Badge";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import StackedBarGraph from "./StackedBarGraph";
import SectionTitle from "../../SectionTitle";
import PageTitle from "../../PageTitle";
import styles from "./styles.module.css";

function Expenses() {
    /*
        TODO: have to figure out how to deal with back navigation
        i.e. when the user goes to MerchantDetailView and then clicks back,
        the router forgets that By Merchant was the active tab.
        The fix likely involves storing a query param in the url.
    */
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const tabs = [
        "By Category",
        <div className={styles.tab}>
            <span className={styles.tabText}>By Merchant</span>
            <Badge color="green">
                <div className={styles.badgeText}>Beta</div>
            </Badge>
        </div>,
    ];

    const isAdmin = useIsAdminDashboard();

    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.pageTitleContainer}>
                <PageTitle>Expenses By Category</PageTitle>
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>By Month</SectionTitle>
            </div>
            <div className={styles.stackedBarGraphContainer}>
                <StackedBarGraph />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>For Date Range</SectionTitle>
            </div>
            {
                // TODO: remove the conditional code below once we've refined ExpensesByMerchant
            }
            {isAdmin ? (
                <>
                    <div className={styles.tabsContainer}>
                        <HorizontalTabs
                            tabs={tabs}
                            onChange={setActiveTabIndex}
                        />
                    </div>
                    {activeTabIndex === 0 ? (
                        <ExpensesByCategory />
                    ) : (
                        <ExpensesByMerchant />
                    )}
                </>
            ) : (
                <ExpensesByCategory />
            )}
        </div>
    );
}

export default Expenses;
