import React from "react";
import SectionTitle from "../../SectionTitle";
import Table from "./Table";
import styles from "./styles.module.css";

function AssetClasses() {
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Optimize Your Idle Cash</SectionTitle>
            </div>
            <div className={styles.subtitle}>
                Your cash should work for you instead of sitting in a checking
                or savings account. With treasury management, you can earn a
                return on your idle cash by investing it in following
                institutional assets.
            </div>
            <Table />
            <div className={styles.disclaimers}>
                <p>
                    * Based on historical performance. Past performance is not a
                    guarantee of future returns.
                </p>
                <p>
                    ** Investment involves risk including potential loss of
                    principal.
                </p>
            </div>
        </div>
    );
}

export default AssetClasses;
