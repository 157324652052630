import * as Sentry from "@sentry/react";
import { setError } from "../index";

const getPOJOError = (error) => {
    try {
        const pojoError = JSON.parse(
            JSON.stringify(error, Object.getOwnPropertyNames(error))
        );
        return pojoError;
    } catch (jsonError) {
        Sentry.captureException(jsonError);
        console.error(jsonError);
        return {
            message: "An error occured.",
        };
    }
};

const raiseError = (error) => (dispatch) => {
    // this should always be of type Error
    Sentry.captureException(error);
    console.error(error);

    const pojoError = getPOJOError(error);

    dispatch(setError(pojoError));
};

export default raiseError;
