import { useParams } from "react-router-dom";
import { useLazyDownloadBalanceSheetQuery as useAdminLazyDownloadBalanceSheetQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadBalanceSheetQuery as useProviderLazyDownloadBalanceSheetQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../useGetFinancialReportsValidUpUntilQuery";

const useLazyDownloadBalanceSheetQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] = useAdminLazyDownloadBalanceSheetQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadBalanceSheetQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const finalParams = {
        ...preloadParams,
        endDate: validUpUntilData.valid_up_until,
    };

    const triggerWithParams = () => {
        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }

        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadBalanceSheetQuery;
