import React from "react";
import PropTypes from "prop-types";
import Label from "../Label";
import styles from "./styles.module.css";

function LabeledText({ label, text }) {
    return (
        <div className={styles.container}>
            <div className={styles.labelContainer}>
                <Label>{label}</Label>
            </div>
            <div className={styles.text}>{text}</div>
        </div>
    );
}

LabeledText.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default LabeledText;
