import React from "react";
import PropTypes from "prop-types";
import AlertMui from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

function Alert({ severity, variant, title, body }) {
    return (
        <Stack sx={{ width: "100%" }} spacing={2}>
            <AlertMui severity={severity} variant={variant}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {body}
            </AlertMui>
        </Stack>
    );
}

Alert.propTypes = {
    severity: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.node.isRequired,
};

Alert.defaultProps = {
    title: undefined,
};

export default Alert;
