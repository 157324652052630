import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { useInitInlineWidget, useOnCalendlyEventScheduled } from "./utils";
import { useGetOrCreateUserQuery } from "../../redux/slices/api";
import ConfirmSuccessfulSchedule from "./ConfirmSuccessfulSchedule";
import { STATES, CALENDLY_PARENT_ELEMENT_ID } from "./constants";
import styles from "./styles.module.css";

function CalendlyModal({ onClose, calendlyUrl, customAnswersPrefill }) {
    const [state, setState] = useState(STATES.SCHEDULE_CALL);
    const { data: user } = useGetOrCreateUserQuery();

    useInitInlineWidget(user, calendlyUrl, customAnswersPrefill);

    useOnCalendlyEventScheduled(() =>
        setState(STATES.CONFIRM_SUCCESSFUL_SCHEDULE)
    );

    return (
        <Modal onClose={onClose}>
            {state === STATES.SCHEDULE_CALL && (
                <div
                    id={CALENDLY_PARENT_ELEMENT_ID}
                    className={styles.container}
                />
            )}
            {state === STATES.CONFIRM_SUCCESSFUL_SCHEDULE && (
                <ConfirmSuccessfulSchedule onClose={onClose} />
            )}
        </Modal>
    );
}

CalendlyModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    calendlyUrl: PropTypes.string.isRequired,
    customAnswersPrefill: PropTypes.object,
};

CalendlyModal.defaultProps = {
    customAnswersPrefill: {},
};

export default CalendlyModal;
