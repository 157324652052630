import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HelpSvg from "../../../assets/Help.svg";
import SignOutSvg from "../../../assets/SignOut.svg";
import SupportModal from "../../Modal/components/SupportModal";
import signOut from "../../../redux/slices/authentication/thunks/signOut";
import styles from "./styles.module.css";

function Bottom() {
    const [showSupportModal, setShowSupportModal] = useState(false);

    const openSupportModal = () => {
        setShowSupportModal(true);
    };

    const closeSupportModal = () => {
        setShowSupportModal(false);
    };

    const isUserSignedIn = useSelector(
        (state) => state.authentication.isUserSignedIn
    );

    const dispatch = useDispatch();

    const onClickSignOut = () => {
        dispatch(signOut());
    };

    return (
        <div className={styles.container}>
            <div className={styles.row} onClick={openSupportModal}>
                <img className={styles.icon} src={HelpSvg} alt="Help" />
                <div className={styles.text}>Help</div>
            </div>
            {showSupportModal && <SupportModal onClose={closeSupportModal} />}
            {isUserSignedIn ? (
                <div className={styles.row} onClick={onClickSignOut}>
                    <img
                        className={styles.icon}
                        src={SignOutSvg}
                        alt="Sign Out"
                    />
                    <div className={styles.text}>Sign Out</div>
                </div>
            ) : null}
        </div>
    );
}

export default Bottom;
