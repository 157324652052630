/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";
import SavingsTable from "../SavingsTable";
import styles from "./styles.module.css";

function RealizedSavings({ data }) {
    return (
        <div>
            <div className={styles.titleContainer}>
                <div className={styles.totalAmoutSaved}>Total Amount Saved</div>
                <div className={styles.number}>
                    {getCurrencyString(data.total_amount_saved_cents)}
                </div>
            </div>
            {data.savings.length > 0 && <SavingsTable savings={data.savings} />}
        </div>
    );
}

RealizedSavings.propTypes = {
    data: PropTypes.shape({
        total_amount_saved_cents: PropTypes.number.isRequired,
        savings: PropTypes.arrayOf(
            PropTypes.shape({
                category: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                created_at: PropTypes.string.isRequired,
                amount_cents: PropTypes.number.isRequired,
            }).isRequired
        ).isRequired,
    }).isRequired,
};

export default RealizedSavings;
