import React from "react";
import PropTypes from "prop-types";
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
} from "recharts";
import { getCurrencyString } from "../../../../utils";
import { tickStyle, formatMonthYearStringForXAxis } from "../../utils";
import GraphTooltip from "./GraphTooltip";
import { useSharedDefs } from "../../SharedDefsContextProvider";

function MonthlyGraph({ data }) {
    // Get the most recent 12 months
    const dataPoints = data.slice(-12);

    const defs = useSharedDefs();

    return (
        <ResponsiveContainer width="100%" height={200}>
            <BarChart data={dataPoints}>
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip cursor={{ opacity: 0.4 }} content={<GraphTooltip />} />
                {defs}
                <Bar
                    dataKey="amount_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        amount_cents: PropTypes.number.isRequired,
    }).isRequired
);

MonthlyGraph.propTypes = {
    data: dataProps.isRequired,
};

export default MonthlyGraph;
