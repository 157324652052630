import { useParams } from "react-router-dom";
import { useAddPlaidItemMutation as useAdminAddPlaidItemMutation } from "../../../redux/slices/adminApi";
import { useAddPlaidItemMutation as useProviderAddPlaidItemMutation } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useAddPlaidItemMutation = () => {
    const isAdminDashboard = useIsAdminDashboard();
    const { providerId } = useParams();

    const [adminTrigger, adminResult, adminReset] =
        useAdminAddPlaidItemMutation();

    const [providerTrigger, providerResult, providerReset] =
        useProviderAddPlaidItemMutation();

    const trigger = (publicToken) => {
        const body = {
            public_token: publicToken,
        };

        if (isAdminDashboard) {
            return adminTrigger({
                providerId,
                body,
            });
        }

        return providerTrigger(body);
    };

    return [
        trigger,
        isAdminDashboard ? adminResult : providerResult,
        isAdminDashboard ? adminReset : providerReset,
    ];
};

export default useAddPlaidItemMutation;
