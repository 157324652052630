import React from "react";
import SharedTable from "../../../Table";
import AssetClass from "./AssetClass";

const HEADERS = [
    "Asset",
    "Expected 12 Month Return *",
    "Liquidity",
    "Principal Risk **",
];

const rows = [
    [
        <AssetClass assetClass="MANAGED_MONEY_MARKET" />,
        "4.93% - 5.43%",
        "3-5 Business Days",
        "Near Zero",
    ],
    [
        <AssetClass assetClass="MANAGED_TREASURIES" />,
        "4.84% - 5.84%",
        "3-5 Business Days",
        "Very Low",
    ],
    [
        <AssetClass assetClass="MANAGED_INCOME" />,
        "4.50% - 12.50%",
        "3-5 Business Days",
        "Low",
    ],
];

function Table() {
    return <SharedTable headers={HEADERS} rows={rows} />;
}

export default Table;
