import React, { useState } from "react";
import PropTypes from "prop-types";
import YearPicker from "./YearPicker";
import PeriodPicker from "./PeriodPicker";
import { PERIODS, getDateRangeForAnnual } from "../utils";
import QuarterPicker from "./QuarterPicker";
import MonthPicker from "./MonthPicker";
import styles from "./styles.module.css";

function Menu({ year, onChange, maxDate }) {
    const [selectedYear, setSelectedYear] = useState(year);
    const [period, setPeriod] = useState(null);

    const onPeriodChange = (newPeriod) => {
        if (newPeriod === PERIODS.ANNUAL) {
            const dateRange = getDateRangeForAnnual(selectedYear, maxDate);
            onChange(dateRange);
        } else {
            setPeriod(newPeriod);
        }
    };

    return (
        <div className={styles.container}>
            <YearPicker
                year={selectedYear}
                onChange={setSelectedYear}
                maxDate={maxDate}
            />
            <div className={styles.periodPickerContainer}>
                <PeriodPicker period={period} onChange={onPeriodChange} />
            </div>
            {period === PERIODS.QUARTERLY && (
                <div className={styles.pickerContainer}>
                    <QuarterPicker
                        year={selectedYear}
                        onChange={onChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
            {period === PERIODS.MONTHLY && (
                <div className={styles.pickerContainer}>
                    <MonthPicker
                        year={selectedYear}
                        onChange={onChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    );
}

Menu.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default Menu;
