import React from "react";
import PropTypes from "prop-types";
import SharedModal from "../../../../../Modal";
import PageTitle from "../../../../../PageTitle";
import DownloadSvg from "../../../../../../assets/Download.svg";
import styles from "./styles.module.css";

function Modal({ onClose }) {
    return (
        <SharedModal onClose={onClose}>
            <div>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>How to Recategorize Transactions</PageTitle>
                </div>
                <div className={styles.subtitle}>
                    We&apos;re currently working on a feature that will allow
                    you to recategorize transactions directly on the platform.
                    In the meantime, please follow the steps below:
                </div>
                <div className={styles.stepsContainer}>
                    <ol>
                        <li className={styles.step}>
                            Download an excel file of the report by clicking on
                            the button that looks like{" "}
                            <img
                                src={DownloadSvg}
                                alt="Download"
                                className={styles.downloadIcon}
                            />
                        </li>
                        <li className={styles.step}>
                            In the excel file, add a comment next to each
                            transaction that you&apos;d like to recategorize
                            e.g. &quot;Move to Supplies Expense&quot;
                        </li>
                        <li className={styles.step}>
                            Send an email to{" "}
                            <strong>accounting@flychain.us</strong> with the
                            subject <strong>Recategorize Transactions</strong>{" "}
                            and the excel file attached.
                        </li>
                    </ol>
                </div>
            </div>
        </SharedModal>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Modal;
