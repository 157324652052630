import React from "react";
import PropTypes from "prop-types";
import CalendarSvg from "../../../assets/Calendar.svg";
import ChevronsUpDownSvg from "../../../assets/ChevronsUpDown.svg";
import styles from "./styles.module.css";

function Display({ date, onClick }) {
    return (
        <div className={styles.container} onClick={onClick}>
            <img
                className={styles.calendarIcon}
                src={CalendarSvg}
                alt="Calendar"
            />
            <div className={styles.date}>{date.format("MMMM D, YYYY")}</div>
            <img
                className={styles.chevronsUpDownIcon}
                src={ChevronsUpDownSvg}
                alt="ChevronsUpDown"
            />
        </div>
    );
}

Display.propTypes = {
    date: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Display;
