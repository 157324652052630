/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import plaidErrorIsItemLoginRequired from "../utils";
import InstitutionHeader from "../InstitutionHeader";
import ConnectionBroken from "../ConnectionBroken";
import AccountsConnected from "../AccountsConnected";
import PlaidLinkButton from "../../PlaidLinkButton";
import styles from "./styles.module.css";

const getPlaidItemStatusAndCallToAction = (plaidItem) => {
    const {
        is_admin_linked,
        raw_plaid_error,
        accounts,
        internal_plaid_item_id,
    } = plaidItem;

    // Plaid item is fine so there's nothing to do.
    if (!raw_plaid_error) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    if (is_admin_linked) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    if (plaidErrorIsItemLoginRequired(raw_plaid_error)) {
        return {
            plaidItemStatus: <ConnectionBroken />,
            callToAction: (
                <PlaidLinkButton
                    plaidItemId={internal_plaid_item_id}
                    color="secondary"
                />
            ),
        };
    }

    return {
        plaidItemStatus: (
            <AccountsConnected numberOfAccounts={accounts.length} />
        ),
        callToAction: null,
    };
};

function ProviderPlaidItemHeader({ plaidItem }) {
    const { plaidItemStatus, callToAction } =
        getPlaidItemStatusAndCallToAction(plaidItem);

    return (
        <div className={styles.container}>
            <InstitutionHeader
                plaidItem={plaidItem}
                plaidItemStatus={plaidItemStatus}
            />
            <div className={styles.callToActionContainer}>{callToAction}</div>
        </div>
    );
}

ProviderPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default ProviderPlaidItemHeader;
