import flychain from "./themes/flychain.svg";
import juniper from "./themes/juniper.svg";
import coachcare from "./themes/coachcare.svg";
import healthrev from "./themes/healthrev.svg";
import rethink from "./themes/rethink.svg";
import pario from "./themes/pario.svg";
import endue from "./themes/endue.svg";
import { THEMES, getFile } from "../../index";

const mapping = {
    [THEMES.FLYCHAIN]: flychain,
    [THEMES.JUNIPER]: juniper,
    [THEMES.COACHCARE]: coachcare,
    [THEMES.HEALTHREV]: healthrev,
    [THEMES.RETHINK]: rethink,
    [THEMES.PARIO]: pario,
    [THEMES.ENDUE]: endue,
};

export default getFile(mapping);
