import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Text from "../../Text";
import CompleteSvg from "../../../themes/assets/Complete";
import CurrentSvg from "../../../themes/assets/Current";
import IncompleteSvg from "../../../themes/assets/Incomplete";
import themeStyles from "../../../themes/styles";

function Complete() {
    return (
        <img className={styles.stateBadge} src={CompleteSvg} alt="Complete" />
    );
}

function Current() {
    return <img className={styles.stateBadge} src={CurrentSvg} alt="Current" />;
}

function Incomplete() {
    return (
        <img
            className={styles.stateBadge}
            src={IncompleteSvg}
            alt="Incomplete"
        />
    );
}

export const TAB_STATES = {
    COMPLETE: "COMPLETE",
    CURRENT: "CURRENT",
    INCOMPLETE: "INCOMPLETE",
    LAST: "LAST",
};

const getStateBadge = (state) => {
    switch (state) {
        case TAB_STATES.COMPLETE:
            return <Complete />;
        case TAB_STATES.CURRENT:
            return <Current />;
        case TAB_STATES.INCOMPLETE:
            return <Incomplete />;
        case TAB_STATES.LAST:
            return <Complete />;
        default:
            return <Complete />;
    }
};

const getTabClassName = (state) => {
    let className = styles.container;
    if (state === TAB_STATES.CURRENT || state === TAB_STATES.LAST) {
        className = `${className} ${themeStyles.currentTab}`;
    }
    return className;
};

const getTabTextClassName = (state) => {
    let className = themeStyles.tabText;
    if (state === TAB_STATES.CURRENT || state === TAB_STATES.LAST) {
        className = `${className} ${themeStyles.currentTabText}`;
    }
    return className;
};

function Tab({ children, state }) {
    const stateBadge = getStateBadge(state);

    const tabClassName = getTabClassName(state);

    const tabTextClassName = getTabTextClassName(state);

    return (
        <div className={tabClassName}>
            {stateBadge}
            <Text>
                <div className={tabTextClassName}>{children}</div>
            </Text>
        </div>
    );
}

Tab.propTypes = {
    children: PropTypes.string.isRequired,
    state: PropTypes.oneOf(Object.values(TAB_STATES)).isRequired,
};

export default Tab;
