const COLLECTION_FLOW_STATES = {
    SELECTION: "SELECTION",
    LAST_PAYMENT_CHECK: "LAST_PAYMENT_CHECK",
    BALANCE_CHECK: "BALANCE_CHECK",
    PRE_COLLECTION: "PRE_COLLECTION",
    COLLECTION: "COLLECTION",
    POST_COLLECTION: "POST_COLLECTION",
};

export default COLLECTION_FLOW_STATES;
