import React from "react";
import PropTypes from "prop-types";
import themeStyles from "../../themes/styles";
import LogoSvg from "../../themes/assets/Logo";
import Bottom from "./Bottom";
import styles from "./styles.module.css";

function SideBar({ children, showLogo }) {
    return (
        <div className={`${styles.container} ${themeStyles.sideBar}`}>
            {showLogo && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={LogoSvg} alt="Logo" />
                </div>
            )}
            {children}
            <div className={styles.bottomContainer}>
                <Bottom />
            </div>
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
    showLogo: PropTypes.bool,
};

SideBar.defaultProps = {
    children: undefined,
    showLogo: true,
};

export default SideBar;
