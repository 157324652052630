import React from "react";
import PropTypes from "prop-types";

function SavingsSvg({ fill }) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.08913 12.7001H10.8867C11.3635 12.7001 11.8207 12.5105 12.1578 12.1729C12.4949 11.8353 12.6843 11.3775 12.6843 10.9001C12.6843 10.4227 12.4949 9.96487 12.1578 9.62731C11.8207 9.28974 11.3635 9.1001 10.8867 9.1001H8.19034C7.65106 9.1001 7.20167 9.2801 6.93203 9.6401L1.8988 14.5001"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.4939 18.1001L6.93196 16.8401C7.2016 16.4801 7.65099 16.3001 8.19027 16.3001H11.7854C12.7741 16.3001 13.6729 15.9401 14.302 15.2201L18.4365 11.2601C18.7833 10.9319 18.9857 10.4792 18.9992 10.0016C19.0127 9.5239 18.8361 9.06045 18.5084 8.71315C18.1806 8.36585 17.7285 8.16315 17.2515 8.14965C16.7745 8.13614 16.3116 8.31294 15.9648 8.64115L12.1899 12.1511"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 13.6001L6.39274 19.0001"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5831 9.91019C15.0226 9.91019 16.1895 8.74165 16.1895 7.30019C16.1895 5.85872 15.0226 4.69019 13.5831 4.69019C12.1435 4.69019 10.9766 5.85872 10.9766 7.30019C10.9766 8.74165 12.1435 9.91019 13.5831 9.91019Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.59518 6.4C6.08434 6.4 7.29155 5.19117 7.29155 3.7C7.29155 2.20883 6.08434 1 4.59518 1C3.10601 1 1.8988 2.20883 1.8988 3.7C1.8988 5.19117 3.10601 6.4 4.59518 6.4Z"
                stroke={fill}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

SavingsSvg.propTypes = {
    fill: PropTypes.string.isRequired,
};

export default SavingsSvg;
