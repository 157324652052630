import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Bullet({ number, description }) {
    return (
        <div className={styles.container}>
            <div className={styles.bullet}>{number}</div>
            <div className={styles.description}>{description}</div>
        </div>
    );
}

Bullet.propTypes = {
    number: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
};

export default Bullet;
