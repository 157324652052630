import { useParams } from "react-router-dom";
import { useLazyDownloadIncomeStatementQuery as useAdminLazyDownloadIncomeStatementQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadIncomeStatementQuery as useProviderLazyDownloadIncomeStatementQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyDownloadIncomeStatementQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] =
        useAdminLazyDownloadIncomeStatementQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadIncomeStatementQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const triggerWithParams = (dateRange) => {
        const finalParams = { ...preloadParams, ...dateRange };
        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }
        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadIncomeStatementQuery;
