import React from "react";
import PropTypes from "prop-types";
import Table from "../Table";

export const productSummaryTitles = {
    USE_OF_FUNDS: "Use of Funds",
    LOAN_AMOUNT: "Loan Amount",
    TIME_TO_FUND: "Time to Fund",
    REPAYMENT_PERIOD: "Repayment Period",
    INTEREST_RATE: "Interest Rate",
};

function ProductSummary({ rows }) {
    return <Table title="Product Summary" rows={rows} />;
}

ProductSummary.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    ).isRequired,
};

export default ProductSummary;
