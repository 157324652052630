import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useClickOutside from "../../hooks/useClickOutside";
import Display from "./Display";
import Dropdown from "./Dropdown";
import styles from "./styles.module.css";

function Select({ label, onChange, value, options, alignment }) {
    const [showOptions, setShowOptions] = useState(false);

    const onClickOutside = () => {
        setShowOptions(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onDisplayClick = () => {
        setShowOptions(!showOptions);
    };

    const onOptionClick = (option) => {
        onChange(option);
        setShowOptions(false);
    };

    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.labelContainer, styles[alignment])}
            >
                {label}
            </div>
            <div ref={containerRef}>
                <Display
                    label={label}
                    value={value}
                    options={options}
                    onClick={onDisplayClick}
                />
                {showOptions && (
                    <Dropdown
                        value={value}
                        options={options}
                        onClick={onOptionClick}
                        alignment={alignment}
                    />
                )}
            </div>
        </div>
    );
}

Select.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    alignment: PropTypes.oneOf(["left", "right"]),
};

Select.defaultProps = {
    alignment: "left",
};

export default Select;
