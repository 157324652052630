import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import COLLECTION_FLOW_STATES from "../states";
import BalanceCheckTable from "./BalanceCheckTable";
import Button from "../../../../Button";
import { getCurrencyString } from "../../../../../utils";
import { useGetProviderBankAccountDataQuery } from "../../../../../redux/slices/adminApi";
import SectionTitle from "../../../../SectionTitle";
import styles from "./styles.module.css";

const getProviderBankAccountBalanceCents = (providerBankAccountData) => {
    const providerBankAccount = providerBankAccountData.provider_bank_account;
    const balance = providerBankAccount.balances.available;
    return Math.round(balance * 100);
};

const hasSufficientBalance = (providerBankAccountData, totalPaymentCents) => {
    const providerBankAccountBalanceCents = getProviderBankAccountBalanceCents(
        providerBankAccountData
    );
    return providerBankAccountBalanceCents >= totalPaymentCents;
};

function BalanceCheck({ payments, setCollectionFlowState }) {
    // assumes there is at least one payment because of checks in Selection
    const { data: providerBankAccountData } =
        useGetProviderBankAccountDataQuery(payments[0].provider_id);

    const onClickContinue = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.COLLECTION);
    };

    const onClickBack = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.LAST_PAYMENT_CHECK);
    };

    // add up all the payments
    const totalPaymentsCents = payments.reduce(
        (total, payment) => total + payment.payment_amount,
        0
    );

    return providerBankAccountData ? (
        <div className={styles.container}>
            <Button onClick={onClickBack}>Back</Button>
            <SectionTitle>Balance Check</SectionTitle>
            <p> Total Payments: {getCurrencyString(totalPaymentsCents)} </p>
            {Object.keys(providerBankAccountData).length === 0 ? (
                <p>
                    No bank balance for provider! You&aposre flying blind! Watch
                    out!
                </p>
            ) : (
                <div>
                    <p>
                        Current Bank Balance:{" "}
                        {getCurrencyString(
                            getProviderBankAccountBalanceCents(
                                providerBankAccountData
                            )
                        )}
                    </p>
                    {hasSufficientBalance(
                        providerBankAccountData,
                        totalPaymentsCents
                    ) ? (
                        <p className={styles.green}>
                            The provider has sufficient balance to cover the
                            collections
                        </p>
                    ) : (
                        <p className={styles.red}>
                            The provider does not have sufficient balance to
                            cover the collections.
                        </p>
                    )}
                    <p>
                        Fetch Date:{" "}
                        {dayjs(providerBankAccountData.fetch_date).format(
                            "MMMM D, YYYY"
                        )}
                    </p>
                </div>
            )}
            <BalanceCheckTable payments={payments} />
            <div className={styles.nextButtonContainer}>
                <Button onClick={onClickContinue}>Continue</Button>
            </div>
        </div>
    ) : null;
}

BalanceCheck.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setCollectionFlowState: PropTypes.func.isRequired,
};

export default BalanceCheck;
