import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function SectionTitle({ children }) {
    return <div className={styles.container}>{children}</div>;
}

SectionTitle.propTypes = {
    children: PropTypes.string.isRequired,
};

export default SectionTitle;
