import { useParams } from "react-router-dom";
import { useGetDataForNetProfitGraphQuery as useAdminGetDataForNetProfitGraphQuery } from "../../../redux/slices/adminApi";
import { useGetDataForNetProfitGraphQuery as useProviderGetDataForNetProfitGraphQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetDataForNetProfitGraphQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetDataForNetProfitGraphQuery({
            providerId,
            businessEntityId,
        });
    }

    return useProviderGetDataForNetProfitGraphQuery(businessEntityId);
};

export default useGetDataForNetProfitGraphQuery;
