import React from "react";
import { Header, Subheading } from "../../../../components/Text";
import EMAIL from "../../../copy/email";

function SupportModal() {
    return (
        <>
            <Header>Contact us</Header>
            <Subheading>
                Please contact your CoachCare account manager or{" "}
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </Subheading>
        </>
    );
}

export default SupportModal;
