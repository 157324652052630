import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import REQUIRED_MESSAGE from "../../../../Inputs/constants";
import TextInput from "../../../../Inputs/TextInput";
import SelectInput from "../../../../Inputs/SelectInput";
import Button from "../../../../Button";
import { useSetupBusinessEntityMutation } from "../../../../../redux/slices/adminApi";

import styles from "./styles.module.css";

const BUSINESS_ENTITY_TYPES = [
    "LLC",
    "SOLE_PROPRIETOR",
    "PARTNERSHIP",
    "CORPORATION",
    "COOPERATIVE",
    "NON_PROFIT",
    "NOT_SURE",
    "NOT_REGISTERED_YET",
];

const schema = yup
    .object({
        legalName: yup.string().required(REQUIRED_MESSAGE),
        businessEntityType: yup.string().oneOf(BUSINESS_ENTITY_TYPES),
    })
    .required();

const defaultValues = {
    legalName: "",
    businessEntityType: BUSINESS_ENTITY_TYPES[0],
};

function Entities({ providerId }) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const [setupBusinessEntity] = useSetupBusinessEntityMutation();

    const onSubmit = (formData) => {
        const body = {
            legal_name: formData.legalName,
            business_entity_type: formData.businessEntityType,
        };
        setupBusinessEntity({ providerId, body });
    };

    const onClick = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <TextInput
                    name="legalName"
                    label="Legal Name"
                    control={control}
                    error={errors.legalName}
                />
            </div>
            <div className={styles.input}>
                <SelectInput
                    name="businessEntityType"
                    label="Business Entity Type"
                    options={BUSINESS_ENTITY_TYPES}
                    control={control}
                    error={errors.businessEntityType}
                />
            </div>
            <Button onClick={onClick}>Create Entity</Button>
            {Object.keys(errors).length > 0 && (
                <div className={styles.errorContainer}>
                    <div className={styles.error}>
                        Please enter a valid legal name.
                    </div>
                </div>
            )}
        </div>
    );
}

Entities.propTypes = {
    providerId: PropTypes.string.isRequired,
};

export default Entities;
