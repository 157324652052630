import React from "react";
import PropTypes from "prop-types";
import CalendarSvg from "../../../assets/Calendar.svg";
import ChevronsUpDownSvg from "../../../assets/ChevronsUpDown.svg";
import styles from "./styles.module.css";

function Control({ dateRange, onClick }) {
    return (
        <div className={styles.container} onClick={onClick}>
            <img
                className={styles.calendarIcon}
                src={CalendarSvg}
                alt="Calendar"
            />
            <div className={styles.dateRange}>{`${dateRange.startDate.format(
                "MMM DD, YYYY"
            )} - ${dateRange.endDate.format("MMM DD, YYYY")}`}</div>
            <img
                className={styles.chevronsUpDownIcon}
                src={ChevronsUpDownSvg}
                alt="ChevronsUpDown"
            />
        </div>
    );
}

export const dateRangeProps = PropTypes.shape({
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
});

Control.propTypes = {
    dateRange: dateRangeProps.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Control;
