import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../PageTitle";
import Button from "../../../Button";
import CashFlowGraphicSvg from "../../../../assets/CashFlowGraphic.svg";
import NetProfitGraphicSvg from "../../../../assets/NetProfitGraphic.svg";
import TopExpensesGraphicSvg from "../../../../assets/TopExpensesGraphic.svg";
import styles from "./styles.module.css";

function HeaderSection({ onClick }) {
    return (
        <div className={styles.container}>
            <div className={styles.leftSectionContainer}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>File Your Taxes With Confidence</PageTitle>
                </div>
                <div className={styles.body}>
                    <a
                        href="https://www.rivet.tax/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Rivet
                    </a>
                    , our tax partner, is a tax prep firm built by
                    technologists. Rivet is designed to make tax season as
                    seamless as possible while minimizing your tax burden. Rivet
                    bills for fixed price engagements so that costs are upfront,
                    transparent, and low.
                </div>
                <div>
                    <Button color="primary" onClick={onClick}>
                        Schedule a Free Intro Call
                    </Button>
                </div>
            </div>
            <div className={styles.rightSectionContainer}>
                <div className={styles.circle} />
                {
                    // TODO: replace these graphics - Christine to send.
                }
                <img
                    className={styles.cashFlowGraphic}
                    src={CashFlowGraphicSvg}
                    alt="CashFlowGraphic"
                />
                <img
                    className={styles.netProfitGraphic}
                    src={NetProfitGraphicSvg}
                    alt="NetProfitGraphic"
                />
                <img
                    className={styles.topExpensesGraphic}
                    src={TopExpensesGraphicSvg}
                    alt="TopExpensesGraphic"
                />
            </div>
        </div>
    );
}

HeaderSection.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default HeaderSection;
