import React from "react";
import PropTypes from "prop-types";
import Banner from "../../../Banner";

function TaxIntroCall({ onClick }) {
    return (
        <Banner
            title="Interested In Learning More?"
            body="If you'd like to learn more, the team at Rivet would love to hop on a call to chat! We're here to support
        your tax filing needs no matter how simple or complicated. If you have additional requests related to tax filing or tax planning, please don't hesitate to let us know and we'd be happy to help."
            buttonText="Schedule A Free Intro Call"
            buttonOnClick={onClick}
        />
    );
}

TaxIntroCall.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default TaxIntroCall;
