import React from "react";
import { useParams } from "react-router-dom";
import { useGetDataForAdvancedPaymentOnClaimsQuery } from "../../../../redux/slices/adminApi";
import AdvancedPaymentOnClaimsPresentational from "../../../Capital/AdvancedPaymentOnClaims";

function LiveAdvancedPaymentOnClaims() {
    const { providerId } = useParams();
    const { data } = useGetDataForAdvancedPaymentOnClaimsQuery(providerId);
    return data ? <AdvancedPaymentOnClaimsPresentational data={data} /> : null;
}

export default LiveAdvancedPaymentOnClaims;
