const COLORS = [
    "#e6194b", // Red
    "#3cb44b", // Green
    "#f4a300", // Dark Gold
    "#0082c8", // Blue
    "#f58231", // Orange
    "#911eb4", // Purple
    "#46a5b8", // Teal
    "#a72e6c", // Dark Magenta
    "#3b7833", // Olive Green
    "#b24b77", // Dark Pink
    "#003f5c", // Deep Blue
    "#6a0dad", // Dark Purple
    "#8c5e3c", // Dark Brown
    "#4b5d41", // Moss Green
    "#d12d00", // Crimson Red
    "#004d40", // Dark Cyan
    "#ff4500", // Orange Red
    "#008080", // Teal
    "#a9a9a9", // Dark Gray
    "#5a3f2b", // Coffee
    "#dc143c", // Crimson
    "#003366", // Navy Blue
    "#1f77b4", // Dark Sky Blue
    "#556b2f", // Dark Olive Green
    "#8b4513", // Saddle Brown
    "#9b9b77", // Khaki
    "#00ff7f", // Spring Green
    "#d2691e", // Chocolate
    "#800000", // Maroon
    "#2e8b57", // Sea Green
    "#800080", // Purple
    "#b22222", // Firebrick
    "#6a5acd", // Slate Blue
    "#4682b4", // Steel Blue
    "#9932cc", // Dark Orchid
    "#8b0000", // Dark Red
    "#2e8b57", // Sea Green
    "#3d5afe", // Blue
    "#ff6347", // Tomato
    "#7d3f1b", // Dark Red Brown
    "#7cfc00", // Lawn Green
    "#ff4500", // Orange Red
    "#7a7a7a", // Gray
    "#228b22", // Forest Green
    "#8b0000", // Dark Red
    "#2f4f4f", // Dark Slate Gray
    "#a52a2a", // Brown
    "#008000", // Green
    "#4b0082", // Indigo
    "#0000ff", // Blue
    "#2e8b57", // Sea Green
    "#4b0082", // Indigo
    "#556b2f", // Dark Olive Green
    "#191970", // Midnight Blue
    "#ff1493", // Deep Pink
    "#b22222", // Firebrick
    "#000000", // Black
    "#6a5acd", // Slate Blue
    "#5f9ea0", // Cadet Blue
    "#3b3b3b", // Dark Gray
    "#800080", // Purple
    "#4682b4", // Steel Blue
    "#9acd32", // Yellow Green
    "#00fa9a", // Medium Spring Green
    "#006400", // Dark Green
    "#d3d3d3", // Light Gray
    "#ff8c00", // Dark Orange
    "#800000", // Maroon
    "#ff4500", // Orange Red
    "#7cfc00", // Lawn Green
    "#800080", // Purple
    "#00008b", // Dark Blue
    "#191970", // Midnight Blue
    "#ff6347", // Tomato
    "#ff1493", // Deep Pink
    "#d2691e", // Chocolate
    "#4b0082", // Indigo
    "#4682b4", // Steel Blue
    "#8a2be2", // Blue Violet
    "#a52a2a", // Brown
    "#006400", // Dark Green
];

export default COLORS;
