export const ASSET_CLASS_COLORS = {
    TREASURE_CASH: "#F8CE39",
    MANAGED_TREASURIES: "#110823",
    MANAGED_MONEY_MARKET: "#CDE6F0",
    MANAGED_INCOME: "#DBEAC3",
};

export const ASSET_CLASSES_READABLE = {
    TREASURE_CASH: "Treasure Cash",
    MANAGED_TREASURIES: "Managed Treasuries",
    MANAGED_MONEY_MARKET: "Managed Money Market",
    MANAGED_INCOME: "Managed Income",
};
