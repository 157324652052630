import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function PageTitle({ children, className }) {
    return <div className={`${styles.container} ${className}`}>{children}</div>;
}

PageTitle.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
};

PageTitle.defaultProps = {
    className: "",
};

export default PageTitle;
