import REPORT_TYPES from "./constants";

const getReadableReportType = (reportType) => {
    switch (reportType) {
        case REPORT_TYPES.INCOME_STATEMENT:
            return "Income Statement";
        case REPORT_TYPES.BALANCE_SHEET:
            return "Balance Sheet";
        case REPORT_TYPES.CASH_FLOW:
            return "Cash Flow";
        case REPORT_TYPES.EXPENSES_BY_CATEGORY:
            return "Expenses By Category";
        default:
            throw new Error(`Unknown report type: ${reportType}`);
    }
};

export default getReadableReportType;
