import React from "react";
import { useGetProviderQuery } from "../../redux/slices/api";
import ProviderDashboard from "../ProviderDashboard";
import Onboarding from "../Onboarding";
import { getState } from "../Onboarding/utils";
import PLATFORM_STATES from "../Onboarding/states";

const getProviderDashboardOrOnboarding = (provider) => {
    const onboardingState = getState(provider);
    if (onboardingState === PLATFORM_STATES.PLATFORM_ACTIVE) {
        return <ProviderDashboard />;
    }
    return <Onboarding state={onboardingState} />;
};

function ProviderAuthenticated() {
    const { data: provider } = useGetProviderQuery();

    return provider !== undefined
        ? getProviderDashboardOrOnboarding(provider)
        : null;
}

export default ProviderAuthenticated;
