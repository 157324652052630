import React from "react";
import COLLECTION_FLOW_STATES from "./states";
import Selection from "./Selection";
import LastPaymentCheck from "./LastPaymentCheck";
import Collection from "./Collection";
import BalanceCheck from "./BalanceCheck";
import PostCollection from "./PostCollection";

const getContent = (
    payments,
    setPayments,
    collectionFlowState,
    setCollectionFlowState
) => {
    switch (collectionFlowState) {
        case COLLECTION_FLOW_STATES.SELECTION:
            return (
                <Selection
                    payments={payments}
                    setPayments={setPayments}
                    setCollectionFlowState={setCollectionFlowState}
                />
            );
        case COLLECTION_FLOW_STATES.LAST_PAYMENT_CHECK:
            return (
                <LastPaymentCheck
                    payments={payments}
                    setPayments={setPayments}
                    setCollectionFlowState={setCollectionFlowState}
                />
            );
        case COLLECTION_FLOW_STATES.BALANCE_CHECK:
            return (
                <BalanceCheck
                    payments={payments}
                    setCollectionFlowState={setCollectionFlowState}
                />
            );
        case COLLECTION_FLOW_STATES.COLLECTION:
            return (
                <Collection
                    payments={payments}
                    setCollectionFlowState={setCollectionFlowState}
                />
            );
        case COLLECTION_FLOW_STATES.POST_COLLECTION:
            return <PostCollection payments={payments} />;
        default:
            throw new Error("No content for state");
    }
};

export default getContent;
