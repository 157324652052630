import React from "react";
import HeaderSection from "./HeaderSection";
import Disclaimer from "./Disclaimer";
import AssetClasses from "./AssetClasses";
import Security from "./Security";
import TrustedAdvisor from "./TrustedAdvisor";
import styles from "./styles.module.css";

function Treasury() {
    return (
        <div className={styles.container}>
            <HeaderSection />
            <AssetClasses />
            <Security />
            <TrustedAdvisor />
            <Disclaimer />
        </div>
    );
}

export default Treasury;
