import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../../../utils";
import SectionTitle from "../../../../../../SectionTitle";
import styles from "./styles.module.css";

function DrawsTable({ draws }) {
    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <SectionTitle>Draws</SectionTitle>
                <table>
                    <thead>
                        <tr>
                            <th>Draw ID</th>
                            <th>Draw Fee</th>
                            <th>ACH Sent Date</th>
                            <th>Principal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {draws.map((draw) => (
                            <tr key={draw.id}>
                                <td>{draw.id}</td>
                                <td>{getCurrencyString(draw.fee)}</td>
                                <td>{draw.ach_sent_date}</td>
                                <td>{getCurrencyString(draw.principal)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

DrawsTable.propTypes = {
    draws: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default DrawsTable;
