import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { getDateRangeForQuarter } from "../../../utils";
import styles from "./styles.module.css";

function QuarterButton({ year, quarter, onClick, maxDate }) {
    const dateRange = getDateRangeForQuarter(year, quarter, maxDate);

    const disabled = dateRange.startDate.isAfter(maxDate, "day");

    const handleClick = () => {
        if (!disabled) {
            onClick(dateRange);
        }
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.disabled]: disabled,
            })}
            onClick={handleClick}
        >
            {quarter}
        </div>
    );
}

QuarterButton.propTypes = {
    year: PropTypes.number.isRequired,
    quarter: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default QuarterButton;
