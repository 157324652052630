import React, { useState } from "react";
import PageTitle from "../../PageTitle";
import TreasuryGraphic from "../../../assets/TreasuryGraphic.svg";
import Button from "../../Button";
import CalendlyModal from "../../CalendlyModal";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";
import styles from "./styles.module.css";

function HeaderSection() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const onClickSignUp = () => {
        window.open("https://app.treasurefi.com/sign-up/Flychain", "_blank");
    };

    const onClickLogin = () => {
        window.open("https://app.treasurefi.com/sign-in", "_blank");
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftSectionContainer}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>Institutional Treasury Management</PageTitle>
                </div>
                <div className={styles.body}>
                    Traditional financial institutions typically don&apos;t
                    offer sophisticated treasury services to small to
                    medium-sized healthcare providers. Our treasury management
                    service offers the yield, safety, and liquidity of
                    institutional-grade solutions within a simple platform.
                    Treasury management and investments are provided by Treasure
                    Investment Management (“Treasure”).
                </div>
                <div className={styles.buttonsContainer}>
                    <div className={styles.buttonContainer}>
                        <Button
                            color="primary"
                            onClick={() => setShowCalendlyModal(true)}
                        >
                            Learn More
                        </Button>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button color="secondary" onClick={onClickSignUp}>
                            Sign Up
                        </Button>
                    </div>
                    <Button color="secondary" onClick={onClickLogin}>
                        Login
                    </Button>
                </div>
            </div>
            <div className={styles.rightSectionContainer}>
                <img
                    className={styles.graphic}
                    src={TreasuryGraphic}
                    alt="TreasurySvg"
                />
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.TREASURY_CALL}
                />
            )}
        </div>
    );
}

export default HeaderSection;
