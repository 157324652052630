import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../../../../Button";
import styles from "./styles.module.css";

function Card({ icon, title, body, navigationPath }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(navigationPath);
    };

    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>{icon}</div>
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                <div className={styles.body}>{body}</div>
                <div>
                    <Button color="secondary" onClick={onClick}>
                        Learn More
                    </Button>
                </div>
            </div>
        </div>
    );
}

Card.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    navigationPath: PropTypes.string.isRequired,
};

export default Card;
