import React from "react";
import SharedCashBalanceGraph from "../../../Graphs/CashBalanceGraph";
import useGetDataForCashBalanceGraphQuery from "../../../../hooks/queriesAndMutations/useGetDataForCashBalanceGraphQuery";

function CashBalanceGraph() {
    const { data: cashBalanceGraphData } = useGetDataForCashBalanceGraphQuery();

    return cashBalanceGraphData ? (
        <SharedCashBalanceGraph data={cashBalanceGraphData} />
    ) : null;
}

export default CashBalanceGraph;
