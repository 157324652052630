import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
} from "recharts";
import dayjs from "dayjs";
import { getCurrencyString } from "../../../../utils";
import { tickStyle } from "../../utils";
import GraphTooltip from "./GraphTooltip";
import CustomLegend from "./Legend";
import { useSharedDefs } from "../../SharedDefsContextProvider";

const tickFormatter = (tick) => {
    const date = dayjs(tick, "MMM DD YYYY");
    return date.format("MMM 'YY");
};

function CashBalanceGraph({ data }) {
    const dataPoints = useMemo(() => {
        if (data.length <= 365) {
            return data;
        }
        return data.slice(-365);
    }, [data]);

    const firstDaysOfMonth = dataPoints
        .map((entry) => entry.date)
        .filter((date) => dayjs(date, "MMM DD YYYY").date() === 1);

    const defs = useSharedDefs();

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart data={dataPoints} stackOffset="sign">
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="date"
                    tick={tickStyle}
                    ticks={firstDaysOfMonth}
                    tickFormatter={tickFormatter}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip cursor={{ opacity: 0.4 }} content={<GraphTooltip />} />
                <Legend content={<CustomLegend />} />
                {defs}
                <Line
                    type="monotone"
                    dataKey="cash_balance_cents"
                    stroke="#8D58FF"
                    strokeWidth={1}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

export const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        date: PropTypes.string.isRequired,
        cash_balance_cents: PropTypes.number.isRequired,
    }).isRequired
);

CashBalanceGraph.propTypes = {
    data: dataProps.isRequired,
};

export default CashBalanceGraph;
