import React from "react";
import { useNavigate } from "react-router-dom";
import LeftCaretSvg from "../../assets/LeftCaret.svg";
import styles from "./styles.module.css";

function BackIcon() {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(-1);
    };

    return (
        <div className={styles.container} onClick={onClick}>
            <img
                className={styles.backIcon}
                src={LeftCaretSvg}
                alt="LeftCaret"
            />
        </div>
    );
}

export default BackIcon;
