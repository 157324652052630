import React from "react";
import PropTypes from "prop-types";
import { useGetDrawPaymentCrosscheckQuery } from "../../../../../../redux/slices/adminApi";

const crosscheckPassed = (unbalancedDraws, setCanEndCollection) => {
    if (unbalancedDraws.length === 0) {
        setCanEndCollection(true);
        return true;
    }
    return false;
};

function Crosscheck({ providerId, setCanEndCollection }) {
    const { data: crosscheckData } =
        useGetDrawPaymentCrosscheckQuery(providerId);

    return crosscheckData ? (
        <div>
            <h2>Crosscheck</h2>
            {crosscheckPassed(
                crosscheckData.unbalanced_draws,
                setCanEndCollection
            ) ? (
                <p>All payments are balanced</p>
            ) : (
                <p>Payments are unbalanced</p>
            )}
            <p>Draws checked: {crosscheckData.draws_checked}</p>
        </div>
    ) : null;
}

Crosscheck.propTypes = {
    providerId: PropTypes.string.isRequired,
    setCanEndCollection: PropTypes.func.isRequired,
};

export default Crosscheck;
