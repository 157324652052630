import React, { useState } from "react";
import PropTypes from "prop-types";
import YearPicker from "./YearPicker";
import Periods from "./Periods";
import PERIODS from "./Periods/constants";
import MonthPicker from "./MonthPicker";
import DayPicker from "./DayPicker";
import styles from "./styles.module.css";

function Dropdown({ date, onChange, maxDate }) {
    const [draftDate, setDraftDate] = useState(date);

    const [period, setPeriod] = useState(PERIODS.MONTH);

    const onMonthChange = (newDate) => {
        setDraftDate(newDate);
        setPeriod(PERIODS.DAY);
    };

    const onDayChange = (newDate) => {
        // Selecting the day finalizes the date and therefore we call onChange, which closes the Menu
        setDraftDate(newDate);
        onChange(newDate);
    };

    return (
        <div className={styles.container}>
            <YearPicker
                date={draftDate}
                onChange={setDraftDate}
                maxDate={maxDate}
            />
            <div className={styles.periodsContainer}>
                <Periods period={period} onChange={setPeriod} />
            </div>
            {period === PERIODS.MONTH && (
                <div className={styles.pickerContainer}>
                    <MonthPicker
                        date={draftDate}
                        onChange={onMonthChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
            {period === PERIODS.DAY && (
                <div className={styles.pickerContainer}>
                    <DayPicker
                        date={draftDate}
                        onChange={onDayChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    );
}

Dropdown.propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default Dropdown;
