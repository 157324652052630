import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../assets/Close.svg";
import styles from "./styles.module.css";

function Modal({ onClose, children }) {
    return (
        <div className={styles.container}>
            <div className={styles.sideBarSpacer} />
            <div className={styles.modalContainer}>
                <div className={styles.modal}>
                    <img
                        className={styles.closeIcon}
                        src={CloseSvg}
                        alt="Close"
                        onClick={onClose}
                    />
                    {children}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default Modal;
