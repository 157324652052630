import { useParams } from "react-router-dom";
import { useGetExpensesByMerchantQuery as useAdminGetExpensesByMerchantQuery } from "../../../redux/slices/adminApi";
import { useGetExpensesByMerchantQuery as useProviderGetExpensesByMerchantQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetExpensesByMerchantQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetExpensesByMerchantQuery({
            providerId,
            businessEntityId,
        });
    }

    return useProviderGetExpensesByMerchantQuery(businessEntityId);
};

export default useGetExpensesByMerchantQuery;
