import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";
import LabeledText from "../../../LabeledText";
import styles from "./styles.module.css";

function Summary({ data }) {
    return (
        <div className={styles.container}>
            {data.map((summary) => (
                <div className={styles.labeledTextContainer} key={summary.name}>
                    <LabeledText
                        label={summary.name}
                        text={getCurrencyString(summary.amount_cents)}
                    />
                </div>
            ))}
        </div>
    );
}

Summary.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Summary;
