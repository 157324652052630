import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Button from "../../../../../Button";
import EntriesStartPicker from "./EntriesStartPicker";
import { useUpdateEntriesStartMutation } from "../../../../../../redux/slices/adminApi";
import styles from "./styles.module.css";

function UpdateEntriesStart({ entriesStart, businessEntity }) {
    const [entriesStartDate, setEntriesStartDate] = useState(
        dayjs(entriesStart)
    );

    const onChange = (newEntriesStartDate) => {
        setEntriesStartDate(newEntriesStartDate);
    };

    const [updateEntriesStart] = useUpdateEntriesStartMutation();

    const onClick = () => {
        updateEntriesStart({
            providerId: businessEntity.provider_id,
            businessEntityId: businessEntity.id,
            body: {
                entries_start: entriesStartDate.format("YYYY-MM-DD"),
            },
        });
    };

    return (
        <div className={styles.container}>
            <EntriesStartPicker value={entriesStartDate} onChange={onChange} />
            <div className={styles.buttonContainer}>
                <Button
                    color="primary"
                    onClick={onClick}
                    disabled={
                        entriesStart === entriesStartDate.format("YYYY-MM-DD")
                    }
                >
                    Update
                </Button>
            </div>
        </div>
    );
}

UpdateEntriesStart.propTypes = {
    entriesStart: PropTypes.string.isRequired,
    businessEntity: PropTypes.object.isRequired,
};

export default UpdateEntriesStart;
