import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Modal from "../../index";
import SupportModalContent from "../../../../themes/components/SupportModal";

function SupportModal({ onClose }) {
    return (
        <Modal onClose={onClose}>
            <div className={styles.container}>
                <SupportModalContent />
            </div>
        </Modal>
    );
}

SupportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SupportModal;
