import React from "react";
import { PropTypes } from "prop-types";
import COLORS from "../../styles/colors";
import styles from "./styles.module.css";

const getLabelFontColor = (color) => {
    switch (color) {
        case "primary":
            return "#FFFFFF";
        case "secondary":
            return "#000000";
        default:
            throw new Error("Invalid color");
    }
};

const getBackgroundColor = (color, disabled) => {
    switch (color) {
        case "primary":
            return disabled ? "#9B92FF" : COLORS.BLUE_PURPLE;
        case "secondary":
            return "#F2F2F2";
        default:
            throw new Error("Invalid color");
    }
};

const getButtonStyle = (color, disabled) => ({
    backgroundColor: getBackgroundColor(color, disabled),
    cursor: disabled ? "not-allowed" : "pointer",
});

function Button({ onClick, disabled, children, color }) {
    const buttonStyle = getButtonStyle(color, disabled);

    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };
    const labelStyle = {
        color: getLabelFontColor(color),
    };
    return (
        <div
            className={styles.container}
            style={buttonStyle}
            onClick={handleClick}
        >
            <div className={styles.label} style={labelStyle}>
                {children}
            </div>
        </div>
    );
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    color: "primary",
};

export default Button;
