import React from "react";
import LiveAdvancedPaymentOnClaims from "./LiveAdvancedPaymentOnClaims";
import { useGetLineForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/api";
import AdvancedPaymentOnClaimsLanding from "../../Capital/AdvancedPaymentOnClaims/Landing";

const displayLiveOrLanding = (line) => {
    if (line.id) {
        return <LiveAdvancedPaymentOnClaims />;
    }
    return <AdvancedPaymentOnClaimsLanding />;
};

function AdvancedPaymentOnClaims() {
    const { data: line } = useGetLineForAdvancedPaymentOnClaimsQuery();
    return line ? displayLiveOrLanding(line) : null;
}

export default AdvancedPaymentOnClaims;
