export const THEMES = {
    FLYCHAIN: "FLYCHAIN",
    JUNIPER: "JUNIPER",
    COACHCARE: "COACHCARE",
    HEALTHREV: "HEALTHREV",
    RETHINK: "RETHINK",
    PARIO: "PARIO",
    ENDUE: "ENDUE",
};

if (!Object.values(THEMES).includes(process.env.REACT_APP_THEME)) {
    throw new Error("Invalid REACT_APP_THEME");
}

export const THEME = process.env.REACT_APP_THEME;

export const getFile = (mapping) => {
    if (!(THEME in mapping)) {
        throw new Error("Missing theme in mapping");
    }
    return mapping[THEME];
};
