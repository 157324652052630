import React from "react";
import PropTypes from "prop-types";

function Video({ src, title }) {
    return (
        <iframe
            width="100%"
            height="100%"
            src={src}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
}

Video.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Video;
