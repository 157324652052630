import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
} from "recharts";
import {
    serializeDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import { getCurrencyString } from "../../../../utils";
import { tickStyle, formatMonthYearStringForXAxis } from "../../utils";
import GraphTooltip from "./GraphTooltip";
import { useSharedDefs } from "../../SharedDefsContextProvider";
import COLORS from "./colors";

const getDataPoints = (data) =>
    data.slice(-12).map((monthData) => {
        const ledgersObject = monthData.ledgers.reduce(
            (acc, ledger) => ({
                ...acc,
                [ledger.ledger_id]: ledger.amount_cents,
                [`${ledger.ledger_id} Name`]: ledger.ledger_name,
                [`${ledger.ledger_id} Proportion`]: ledger.proportion,
            }),
            {}
        );
        return {
            month: monthData.month,
            ...ledgersObject,
        };
    });

const getOrderedLedgerIds = (data) => {
    const ledgerTotals = new Map();

    data.forEach((monthData) => {
        monthData.ledgers.forEach((ledger) => {
            if (ledgerTotals.has(ledger.ledger_id)) {
                ledgerTotals.set(
                    ledger.ledger_id,
                    ledgerTotals.get(ledger.ledger_id) + ledger.amount_cents
                );
            } else {
                ledgerTotals.set(ledger.ledger_id, ledger.amount_cents);
            }
        });
    });

    const orderedLedgerIds = Array.from(ledgerTotals.entries())
        .sort((a, b) => b[1] - a[1]) // Sort by total amount_cents
        .map(([ledgerId]) => ledgerId); // Extract ledger ids

    return orderedLedgerIds;
};

const getLedgerColorMap = (ledgerIds) =>
    ledgerIds.reduce((acc, ledgerId, index) => {
        acc[ledgerId] = COLORS[index % COLORS.length]; // Cycle through colors if more ledgers than colors
        return acc;
    }, {});

function StackedBarGraph({ data }) {
    const dataPoints = getDataPoints(data);

    const defs = useSharedDefs();

    const ledgerIds = getOrderedLedgerIds(data);

    const ledgerColorMap = getLedgerColorMap(ledgerIds);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const handleClick = (graphData, ledgerId) => {
        const { month } = graphData;
        const date = dayjs(month, "MMMM YYYY");
        const startDate = date.startOf("month");
        const endDate = date.endOf("month");

        setDateRange({ startDate, endDate });

        navigate(`ledgers/${ledgerId}`);
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dataPoints}>
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                    domain={[0, "dataMax"]}
                />
                <Tooltip
                    cursor={{ opacity: 0.4 }}
                    content={<GraphTooltip />}
                    shared={false}
                />
                {defs}
                {ledgerIds.map((ledgerId) => (
                    <Bar
                        key={ledgerId}
                        dataKey={ledgerId}
                        stackId="stack"
                        barSize={24}
                        fill={ledgerColorMap[ledgerId]}
                        onClick={(graphData) =>
                            handleClick(graphData, ledgerId)
                        }
                        style={{ cursor: "pointer" }}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

const ledgerShape = PropTypes.shape({
    ledger_name: PropTypes.string.isRequired,
    amount_cents: PropTypes.number.isRequired,
    proportion: PropTypes.number.isRequired,
    ledger_id: PropTypes.string.isRequired,
});

export const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        ledgers: PropTypes.arrayOf(ledgerShape).isRequired,
    }).isRequired
);

StackedBarGraph.propTypes = {
    data: dataProps.isRequired,
};

export default StackedBarGraph;
