import { THEME, THEMES } from "../../index";

const getEmail = () => {
    switch (THEME) {
        case THEMES.FLYCHAIN:
            return "jaime@flychain.us";
        case THEMES.JUNIPER:
            return "financing@juniperplatform.com";
        case THEMES.COACHCARE:
            return "support@coachcare.com";
        case THEMES.HEALTHREV:
            return "mgreenlee@healthrevpartners.com";
        case THEMES.RETHINK:
            return "jamie.pagliaro@rethinkfirst.com";
        case THEMES.PARIO:
            return "jaime@flychain.us";
        case THEMES.ENDUE:
            return "jaime@flychain.us";
        default:
            throw new Error("Missing case for theme");
    }
};

const EMAIL = getEmail();

export default EMAIL;
