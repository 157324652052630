import firebase from "firebase/compat/app";
import { createAsyncThunk } from "@reduxjs/toolkit";

const signOut = createAsyncThunk("authentication/signOut", async () => {
    await firebase.auth().signOut();
    // This reloads the dom so it will refresh redux state.
    window.location.href = "/";
});

export default signOut;
