import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const TAB_COLORS = {
    green: "linear-gradient(35.22deg, #CCE6F0 0%, #DBEAC3 100%)",
    yellow: "linear-gradient(56.11deg, #DBEAC3 0%, #F8CE39 129.84%)",
    purple: "linear-gradient(35.34deg, #EAE5F2 0%, #BF9FFF 100%)",
    "blue-purple": "linear-gradient(288.15deg, #BF9FFF -8.11%, #CDE6F0 62.55%)",
    grey: "#F2F2F2",
    blue: "#CDE6F0",
};

function Card({ children, showTab, tabText, tabColor }) {
    return (
        <div className={styles.container}>
            {showTab && (
                <div
                    className={styles.tab}
                    style={{ background: TAB_COLORS[tabColor] || tabColor }}
                >
                    {tabText}
                </div>
            )}
            <div
                className={classNames(styles.contentContainer, {
                    [styles.contentContainerWithTab]: showTab,
                })}
            >
                {children}
            </div>
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    showTab: PropTypes.bool.isRequired,
    tabText: PropTypes.string,
    tabColor: PropTypes.string,
};

Card.defaultProps = {
    tabText: undefined,
    tabColor: undefined,
};

export default Card;
