import { isRejected } from "@reduxjs/toolkit";
import raiseError from "../slices/error/thunks/raiseError";

const convertResponseToError = (response) =>
    new Error(
        `Response error: ${response.status} ${response.statusText} at ${response.url}`
    );

const convertToError = (thing) => {
    if (thing instanceof Error) {
        return thing;
    }
    if (thing instanceof Response) {
        return convertResponseToError(thing);
    }
    if (typeof thing === "string") {
        return new Error(thing);
    }
    if (thing instanceof Object) {
        return new Error(JSON.stringify(thing));
    }
    const error = Error("Error of unknown type");
    error.data = thing;
    return error;
};

const asyncThunkErrorMonitor = (store) => (next) => (action) => {
    // bypass global error handling for collectPayment on Admin Collection Flow
    if (
        action.type.startsWith("adminApi") &&
        action.meta?.arg?.endpointName === "collectPayment"
    ) {
        return next(action);
    }
    if (isRejected(action)) {
        if (action.meta?.baseQueryMeta?.response) {
            store.dispatch(
                raiseError(convertToError(action.meta.baseQueryMeta.response))
            );
        } else {
            store.dispatch(raiseError(convertToError(action)));
        }
    }
    return next(action);
};

export default asyncThunkErrorMonitor;
