import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function Redirect() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    const navigate = useNavigate();

    useEffect(() => {
        if (businessEntities) {
            navigate(
                `businessEntity/${businessEntities[0].id}/financialReports`,
                {
                    replace: true,
                }
            );
        }
    }, [businessEntities, navigate]);

    return null;
}

export default Redirect;
