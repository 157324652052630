import React from "react";
import PropTypes from "prop-types";
import MonthButton from "./MonthButton";
import { MONTHS } from "../../utils";
import styles from "./styles.module.css";

function MonthPicker({ year, onChange, maxDate }) {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.JANUARY}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.FEBRUARY}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.MARCH}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.APRIL}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.MAY}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.JUNE}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.JULY}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.AUGUST}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.SEPTEMBER}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.OCTOBER}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.NOVEMBER}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
                <div className={styles.monthButtonContainer}>
                    <MonthButton
                        year={year}
                        month={MONTHS.DECEMBER}
                        onClick={onChange}
                        maxDate={maxDate}
                    />
                </div>
            </div>
        </div>
    );
}

MonthPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default MonthPicker;
