import dayjs from "dayjs";

export const formatMonthYearStringForXAxis = (monthYearString) => {
    const date = dayjs(monthYearString, ["MMMM YYYY", "MMM YYYY"]);
    return date.format("MMM 'YY");
};

export const tickStyle = {
    fill: "black",
    fontSize: 10,
    fontWeight: 400,
};
