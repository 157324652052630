import React from "react";
import PropTypes from "prop-types";
import Period from "./Period";
import PERIODS from "./constants";
import styles from "./styles.module.css";

function Periods({ period, onChange }) {
    return (
        <div className={styles.container}>
            <div className={styles.periodContainer}>
                <Period
                    value={PERIODS.MONTH}
                    isSelected={period === PERIODS.MONTH}
                    onClick={onChange}
                />
            </div>
            <div className={styles.periodContainer}>
                <Period
                    value={PERIODS.DAY}
                    isSelected={period === PERIODS.DAY}
                    onClick={onChange}
                />
            </div>
        </div>
    );
}

Periods.propTypes = {
    period: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Periods;
