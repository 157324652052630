/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import styles from "./styles.module.css";

function GraphTooltip({ active, payload }) {
    if (active && payload.length > 0) {
        const dataPoint = payload[0].payload;
        const { month, revenue_cents, expense_cents, net_profit_cents } =
            dataPoint;
        return (
            <div className={styles.container}>
                <div className={styles.month}>{month}</div>
                <div className={styles.revenueAndExpensesContainer}>
                    <div className={styles.revenueContainer}>
                        <div className={styles.revenue}>Revenue</div>
                        <div className={styles.number}>
                            {getCurrencyString(revenue_cents)}
                        </div>
                    </div>
                    <div>
                        <div className={styles.expenses}>Expenses</div>
                        <div className={styles.number}>
                            {getCurrencyString(expense_cents * -1)}
                            {/* match the convention of showing expenses as a positive number */}
                        </div>
                    </div>
                </div>
                <div className={styles.netProfit}>Net Profit</div>
                <div className={styles.number}>
                    {getCurrencyString(net_profit_cents)}
                </div>
            </div>
        );
    }
    return null;
}

GraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                month: PropTypes.string.isRequired,
                revenue_cents: PropTypes.number.isRequired,
                expense_cents: PropTypes.number.isRequired,
                net_profit_cents: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired
    ),
};

GraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default GraphTooltip;
