/* eslint-disable camelcase */
import React, { useMemo } from "react";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Table from "../../../Table";
import { getCurrencyString } from "../../../../utils";
import styles from "./styles.module.css";

const HEADERS = ["Category", "Description", "Date", "Amount"];

const CATEGORY_TO_GRADIENT = {
    FINANCING: "linear-gradient(56.11deg, #DBEAC3 0%, #F8CE39 129.84%)",
    INSURANCE: "linear-gradient(35.22deg, #CCE6F0 0%, #DBEAC3 100%)",
    SUPPLIES: "linear-gradient(35.34deg, #EAE5F2 0%, #BF9FFF 100%)",
};

function getCategoryGradient(category) {
    let linearGradient = CATEGORY_TO_GRADIENT[category];
    if (!linearGradient) {
        Sentry.captureException(
            new Error(
                `No specified linear gradient for savings category ${category}`
            )
        );
        linearGradient = CATEGORY_TO_GRADIENT.FINANCING;
    }
    return linearGradient;
}

function SavingsTable({ savings }) {
    const rows = useMemo(
        () =>
            savings.map((saving) => [
                <div className={styles.categoryContainer}>
                    <div
                        className={styles.categoryCircle}
                        style={{
                            background: getCategoryGradient(saving.category),
                        }}
                    />
                    <div className={`${styles.category} ${styles.noWrap}`}>
                        {saving.category}
                    </div>
                </div>,
                saving.description,
                <div className={styles.noWrap}>
                    {dayjs(saving.created_at).format("MMM D, YYYY")}
                </div>,
                getCurrencyString(saving.amount_cents),
            ]),
        [savings]
    );
    return <Table headers={HEADERS} rows={rows} />;
}

SavingsTable.propTypes = {
    savings: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            amount_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default SavingsTable;
