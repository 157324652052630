import React from "react";
import { Header, Subheading } from "../../../../components/Text";
import EMAIL from "../../../copy/email";

function SupportModal() {
    return (
        <>
            <Header>Contact us</Header>
            <Subheading>
                If you need any help, please don&apos;t hesitate to reach out to{" "}
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a> and we&apos;ll get back
                to you as soon as possible.
            </Subheading>
        </>
    );
}

export default SupportModal;
