import React from "react";
import styles from "./styles.module.css";

function Disclaimer() {
    return (
        <div className={styles.container}>
            <p className={styles.bold}>Disclaimer</p>
            <p>
                Flychain is a client of Treasure and is not compensated for
                client referrals.{" "}
                <a
                    href="https://www.treasurefi.com/disclosure/flychain-solicitation-disclosure"
                    target="_blank"
                >
                    Learn more
                </a>
                .
            </p>
            <p>
                Investment advisory services offered by Treasure Investment
                Management, LLC (“Treasure”), an investment adviser registered
                with the U.S. Securities and Exchange Commission ("SEC"). For
                important information and disclosures related to the services
                Treasure provides, please see Treasure's{" "}
                <a
                    href="https://www.treasurefi.com/adv-brochure"
                    target="_blank"
                >
                    Form ADV Brochure
                </a>
                . Brokerage services are provided by Apex Clearing Corporation
                ("Apex"), member FINRA/SIPC. Investing involves risk, including
                loss of principal. Past performance is not a guarantee of future
                returns. Treasure Cash accounts are held at Grasshopper Bank,
                N.A., member FDIC.
            </p>
        </div>
    );
}

export default Disclaimer;
