import { useEffect } from "react";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { useDispatch } from "react-redux";
import raiseError from "../../redux/slices/error/thunks/raiseError";

function RouterError() {
    const error = useRouteError();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isRouteErrorResponse(error)) {
            dispatch(raiseError(error.error));
        } else {
            dispatch(raiseError(error));
        }
    }, [error]);

    return null;
}

export default RouterError;
