import { useParams } from "react-router-dom";
import { useGetMonthlyLedgerTotalsQuery as useAdminGetMonthlyLedgerTotalsQuery } from "../../../redux/slices/adminApi";
import { useGetMonthlyLedgerTotalsQuery as useProviderGetMonthlyLedgerTotalsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetMonthlyLedgerTotalsQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId, ledgerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetMonthlyLedgerTotalsQuery({
            providerId,
            businessEntityId,
            ledgerId,
        });
    }

    return useProviderGetMonthlyLedgerTotalsQuery({
        businessEntityId,
        ledgerId,
    });
};

export default useGetMonthlyLedgerTotalsQuery;
