import React from "react";
import SharedIncomeStatement from "../../../FinancialReports/IncomeStatement";
import SelectedProvider from "../SelectedProvider";

function IncomeStatement() {
    return (
        <div>
            <SelectedProvider />
            <SharedIncomeStatement />
        </div>
    );
}

export default IncomeStatement;
