import React from "react";
import Dot from "../../../Legend/Dot";
import styles from "./styles.module.css";

function Legend() {
    return (
        <div className={styles.container}>
            <div className={styles.itemContainer}>
                <Dot fill="#8D58FF" />
                <div className={`${styles.label} ${styles.revenue}`}>
                    Revenue
                </div>
            </div>
            <div className={styles.itemContainer}>
                <Dot fill="#757575" />
                <div className={`${styles.label} ${styles.expenses}`}>
                    Expenses
                </div>
            </div>
            <div className={styles.itemContainer}>
                <Dot fill="#F8CE39" />
                <div className={`${styles.label} ${styles.netProfit}`}>
                    Net Profit
                </div>
            </div>
        </div>
    );
}

export default Legend;
