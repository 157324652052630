import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const shouldDisableDate = (date) => {
    const firstDayOfMonth = date.startOf("month").date();
    return date.date() !== firstDayOfMonth;
};

function EntriesStartPicker({ value, onChange }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Select Entries Start Date"
                value={value}
                onChange={onChange}
                shouldDisableDate={shouldDisableDate}
            />
        </LocalizationProvider>
    );
}

EntriesStartPicker.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EntriesStartPicker;
