import React from "react";
import Banner from "../../../Banner";

function ValuationsCall() {
    const onClick = () => {
        // TODO: replace this with a Calendly link once Michael creates it.
        window.open("https://calendar.app.google/DktvgyNeuoQnBGMW8", "_blank");
    };
    return (
        <Banner
            title="Schedule a Free Valuation"
            body="If you'd like a comprehensive valuation of your business,
            please schedule some time with one of our M&A experts. Given your valuation today, we'll work
            with you to develop a strategic playbook to best position
            yourself for a future sale. All valuations are performed
            using real time data from the healthcare marketplace."
            buttonText="Schedule a Free Valuation"
            buttonOnClick={onClick}
        />
    );
}

export default ValuationsCall;
