import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Subheading, Header } from "../../Text";
import {
    useGetProvidersQuery,
    useGetProvidersOnboardedToTealQuery,
    adminApiSlice,
} from "../../../redux/slices/adminApi";
import resetProviderState from "../../../redux/slices/actions";
import SectionTitle from "../../SectionTitle";
import styles from "./styles.module.css";

function Providers() {
    const { data: providerIdToProvider } = useGetProvidersQuery();

    const { data: providersOnboardedToTeal } =
        useGetProvidersOnboardedToTealQuery();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    return (
        <div className={styles.container}>
            {providersOnboardedToTeal && (
                <div className={styles.providerListContainer}>
                    <div className={styles.sectionTitleContainer}>
                        <SectionTitle>Provider Financials</SectionTitle>
                    </div>
                    {providersOnboardedToTeal.map((provider) => {
                        const onClick = () => {
                            dispatch(resetProviderState());
                            dispatch(adminApiSlice.util.resetApiState());
                            navigate(`/provider/${provider.id}`);
                        };
                        return (
                            <div
                                key={provider.id}
                                onClick={onClick}
                                className={styles.row}
                            >
                                {provider.legal_name}
                            </div>
                        );
                    })}
                </div>
            )}
            {providerIdToProvider && (
                <div className={styles.providerListContainer}>
                    <div className={styles.sectionTitleContainer}>
                        <SectionTitle>Plaid Connections</SectionTitle>
                    </div>
                    {Object.values(providerIdToProvider).map((provider) => {
                        const onClick = () => {
                            navigate(`/provider/${provider.id}/connections`);
                        };
                        return (
                            <div
                                key={provider.id}
                                onClick={onClick}
                                className={styles.row}
                            >
                                {provider.legal_name}
                            </div>
                        );
                    })}
                </div>
            )}
            {providerIdToProvider && (
                <div>
                    <Header>Provider Bank Balances</Header>
                    <Subheading>Please select a provider below.</Subheading>
                    {Object.values(providerIdToProvider).map((provider) => {
                        const onClick = () => {
                            navigate(`/providers/${provider.id}`);
                        };
                        return (
                            <div
                                key={provider.id}
                                onClick={onClick}
                                className={styles.row}
                            >
                                {provider.legal_name}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default Providers;
