import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import LabeledText from "../../../../LabeledText";
import styles from "./styles.module.css";

function Summary({ merchantDetail }) {
    return (
        <div className={styles.container}>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label="Past 6 Months Total"
                    text={getCurrencyString(
                        merchantDetail.six_month_total_cents
                    )}
                />
            </div>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label="Recurring"
                    text={merchantDetail.recurring_period || "Not Recurring"}
                />
            </div>
        </div>
    );
}

Summary.propTypes = {
    merchantDetail: PropTypes.object.isRequired,
};

export default Summary;
