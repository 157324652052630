import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseFullScreenSvg from "../../assets/CloseFullScreen.svg";
import OpenFullScreenSvg from "../../assets/OpenFullScreen.svg";
import styles from "./styles.module.css";

function Iframe({ src, title }) {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleIsFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    let containerStyles = {
        width: "100%",
        height: "100%",
    };

    if (isFullScreen) {
        const additionalContainerStyles = {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9998,
        };
        containerStyles = { ...additionalContainerStyles, ...containerStyles };
    }

    const svg = isFullScreen ? CloseFullScreenSvg : OpenFullScreenSvg;

    return (
        <div style={containerStyles}>
            <div className={styles.iframeContainer}>
                <div
                    onClick={toggleIsFullScreen}
                    className={styles.toggleIconContainer}
                >
                    <img className={styles.toggleIcon} src={svg} alt="Toggle" />
                </div>
                <iframe src={src} title={title} width="100%" height="100%" />
            </div>
        </div>
    );
}

Iframe.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Iframe;
