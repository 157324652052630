import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useGlobalLoading from "./hooks/useGlobalLoading";
import styles from "./styles.module.css";

function Loading() {
    const isLoading = useGlobalLoading();
    return isLoading ? (
        <div className={styles.container}>
            <CircularProgress />
        </div>
    ) : null;
}

export default Loading;
