import React from "react";
import useGetMonthlyRevenueByCategoryQuery from "../../../../hooks/queriesAndMutations/useGetMonthlyRevenueByCategoryQuery";
import SharedStackedBarGraph from "../../../Graphs/StackedBarGraph";

function StackedBarGraph() {
    const { data } = useGetMonthlyRevenueByCategoryQuery();

    return data ? <SharedStackedBarGraph data={data} /> : null;
}

export default StackedBarGraph;
