import React from "react";
import PropTypes from "prop-types";
import Tab, { TAB_STATES } from "../../../../SideBar/Tab";
import COLLECTION_FLOW_STATES from "../states";

const TAB_TEXT = {
    SELECTION: "Select Payments to Collect",
    LAST_PAYMENT_CHECK: "Perform Last Payment Check",
    BALANCE_CHECK: "Perform Bank Balance Check",
    COLLECTION: "Initiate Oatfi Collection",
    POST_COLLECTION: "Perform Post-Collection Checks",
};

function Tabs({ collectionFlowState }) {
    switch (collectionFlowState) {
        case COLLECTION_FLOW_STATES.SELECTION:
            return (
                <>
                    <Tab state={TAB_STATES.CURRENT}>{TAB_TEXT.SELECTION}</Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.LAST_PAYMENT_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.BALANCE_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COLLECTION}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.POST_COLLECTION}
                    </Tab>
                </>
            );
        case COLLECTION_FLOW_STATES.LAST_PAYMENT_CHECK:
            return (
                <>
                    <Tab state={TAB_STATES.COMPLETE}>{TAB_TEXT.SELECTION}</Tab>
                    <Tab state={TAB_STATES.CURRENT}>
                        {TAB_TEXT.LAST_PAYMENT_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.BALANCE_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COLLECTION}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.POST_COLLECTION}
                    </Tab>
                </>
            );
        case COLLECTION_FLOW_STATES.BALANCE_CHECK:
            return (
                <>
                    <Tab state={TAB_STATES.COMPLETE}>{TAB_TEXT.SELECTION}</Tab>
                    <Tab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.LAST_PAYMENT_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.CURRENT}>
                        {TAB_TEXT.BALANCE_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.COLLECTION}
                    </Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.POST_COLLECTION}
                    </Tab>
                </>
            );
        case COLLECTION_FLOW_STATES.COLLECTION:
            return (
                <>
                    <Tab state={TAB_STATES.COMPLETE}>{TAB_TEXT.SELECTION}</Tab>
                    <Tab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.LAST_PAYMENT_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.BALANCE_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.CURRENT}>{TAB_TEXT.COLLECTION}</Tab>
                    <Tab state={TAB_STATES.INCOMPLETE}>
                        {TAB_TEXT.POST_COLLECTION}
                    </Tab>
                </>
            );
        case COLLECTION_FLOW_STATES.POST_COLLECTION:
            return (
                <>
                    <Tab state={TAB_STATES.COMPLETE}>{TAB_TEXT.SELECTION}</Tab>
                    <Tab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.LAST_PAYMENT_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.COMPLETE}>
                        {TAB_TEXT.BALANCE_CHECK}
                    </Tab>
                    <Tab state={TAB_STATES.COMPLETE}>{TAB_TEXT.COLLECTION}</Tab>
                    <Tab state={TAB_STATES.LAST}>
                        {TAB_TEXT.POST_COLLECTION}
                    </Tab>
                </>
            );
        default:
            throw new Error("Invalid state");
    }
}

Tabs.propTypes = {
    collectionFlowState: PropTypes.oneOf(Object.values(COLLECTION_FLOW_STATES))
        .isRequired,
};

export default Tabs;
