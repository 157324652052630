import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders, blobResponseHandler } from "../apiUtils";
import {
    getDownloadIncomeStatementUrl,
    getDownloadBalanceSheetUrl,
    getDownloadExpensesByCategoryUrl,
    getTopExpensesGraphUrl,
    getRevenueByCategoryUrl,
    getFinancialReportsSummaryUrl,
    getIncomeStatementUrl,
    getBalanceSheetUrl,
    getCashFlowReportUrl,
    getDownloadCashFlowReportUrl,
    getLedgerLinesUrl,
    getLastPaymentCheckUrl,
    getNetProfitGraphUrl,
    getNetCashFlowGraphUrl,
    getCashBalanceGraphUrl,
    getFinancialReportsValidUpUntilUrl,
    getPlaidLinkTokenUrl,
    transformGetProvidersResponse,
    transformGetBankBalancesResponse,
    transformGetTasksResponse,
    transformGetTestUsersResponse,
    getExpensesByMerchantUrl,
    getMonthlyRevenueByCategoryUrl,
    getMonthlyExpensesByCategoryUrl,
    getMonthlyLedgerTotalsUrl,
    getEntriesStartUrl,
} from "./utils";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/admin`,
    prepareHeaders,
});

export const adminApiSlice = createApi({
    reducerPath: "adminApi",
    baseQuery,
    tagTypes: ["testUsers", "plaidItems", "validUpUntil"],
    endpoints: (builder) => ({
        getProviders: builder.query({
            query: () => "/providers",
            transformResponse: (data) => transformGetProvidersResponse(data),
        }),
        getProviderBankAccountData: builder.query({
            query: (providerId) =>
                `/provider/${providerId}/bank_account/balance`,
        }),
        getBankBalances: builder.query({
            query: (providerId) => `/provider/${providerId}/bank_balances`,
            transformResponse: (data) => transformGetBankBalancesResponse(data),
        }),
        getTasks: builder.query({
            query: () => "/tasks",
            transformResponse: (data) => transformGetTasksResponse(data),
        }),
        getTestUsers: builder.query({
            query: () => "/users/test",
            transformResponse: (data) => transformGetTestUsersResponse(data),
            providesTags: ["testUsers"],
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["testUsers"],
        }),
        getDataForNetCashFlowGraph: builder.query({
            query: (args) => getNetCashFlowGraphUrl(args),
        }),
        getDataForCashBalanceGraph: builder.query({
            query: (args) => getCashBalanceGraphUrl(args),
        }),
        getDataForNetProfitGraph: builder.query({
            query: (args) => getNetProfitGraphUrl(args),
        }),
        getDataForTopExpensesGraph: builder.query({
            query: (args) => getTopExpensesGraphUrl(args),
        }),
        getDataForFinancialReportsSummary: builder.query({
            query: (args) => getFinancialReportsSummaryUrl(args),
        }),
        getDataForIncomeStatement: builder.query({
            query: (args) => getIncomeStatementUrl(args),
        }),
        getDataForBalanceSheet: builder.query({
            query: (args) => getBalanceSheetUrl(args),
        }),
        getDataForCashFlowReport: builder.query({
            query: (args) => getCashFlowReportUrl(args),
        }),
        getDataForLedgerLines: builder.query({
            query: (args) => getLedgerLinesUrl(args),
        }),
        getProvidersOnboardedToTeal: builder.query({
            query: () => "/providers/teal",
        }),
        getProvider: builder.query({
            query: (providerId) => `/provider/${providerId}`,
        }),
        collectPayment: builder.mutation({
            query: (body) => ({
                url: `/oatfi/payment`,
                method: "POST",
                body,
            }),
        }),
        getFinanceReportForProvider: builder.query({
            query: (providerId) => `/provider/${providerId}/finance_report`,
        }),
        getDrawPaymentCrosscheck: builder.query({
            query: (providerId) =>
                `/provider/${providerId}/draw_payment_crosscheck`,
        }),
        lastPaymentCheck: builder.query({
            query: (paymentIds) => getLastPaymentCheckUrl(paymentIds),
        }),
        updateLastPayment: builder.mutation({
            query: (body) => ({
                url: `/last_payment_check/update`,
                method: "PUT",
                body,
            }),
        }),
        getExpiredPlaidItems: builder.query({
            query: () => "/plaid/items/expired",
        }),
        getActiveBookkeeperSubscriptions: builder.query({
            query: () => "/bookkeeper_subscriptions",
        }),
        getBusinessEntities: builder.query({
            query: (providerId) => `/provider/${providerId}/business_entities`,
            providesTags: ["businessEntities"],
        }),
        getTealInstances: builder.query({
            query: (providerId) => `/provider/${providerId}/teal_instances`,
            providesTags: ["tealInstances"],
        }),
        getFinancialReportsValidUpUntil: builder.query({
            query: (args) => getFinancialReportsValidUpUntilUrl(args),
            providesTags: ["validUpUntil"],
        }),
        getPlaidItems: builder.query({
            query: (providerId) => `/provider/${providerId}/plaid/items`,
            providesTags: ["plaidItems"],
        }),
        getPlaidLinkToken: builder.query({
            query: (args) => getPlaidLinkTokenUrl(args),
        }),
        addPlaidItem: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/provider/${providerId}/plaid/link`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        updatePlaidItem: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/provider/${providerId}/plaid/item`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["plaidItems"],
        }),
        getLineForAdvancedPaymentOnClaims: builder.query({
            query: (providerId) => `/provider/${providerId}/capital/line`,
        }),
        getDataForAdvancedPaymentOnClaims: builder.query({
            query: (providerId) => `/provider/${providerId}/capital`,
        }),
        downloadIncomeStatement: builder.query({
            query: (args) => ({
                url: getDownloadIncomeStatementUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadBalanceSheet: builder.query({
            query: (args) => ({
                url: getDownloadBalanceSheetUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadCashFlowReport: builder.query({
            query: (args) => ({
                url: getDownloadCashFlowReportUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        downloadExpensesByCategory: builder.query({
            query: (args) => ({
                url: getDownloadExpensesByCategoryUrl(args),
                responseHandler: blobResponseHandler,
            }),
        }),
        updateValidUpUntil: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: getFinancialReportsValidUpUntilUrl({
                    providerId,
                    businessEntityId,
                }),
                method: "PUT",
                body,
            }),
            invalidatesTags: ["validUpUntil"],
        }),
        getExpensesByMerchant: builder.query({
            query: (args) => ({
                url: getExpensesByMerchantUrl(args),
            }),
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        setupBusinessEntity: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/provider/${providerId}/business_entities`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["businessEntities"],
        }),
        createTealInstance: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/provider/${providerId}/teal_instances`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["tealInstances"],
        }),
        getRevenueByCategory: builder.query({
            query: (args) => getRevenueByCategoryUrl(args),
        }),
        getMonthlyRevenueByCategory: builder.query({
            query: (args) => getMonthlyRevenueByCategoryUrl(args),
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getMonthlyExpensesByCategory: builder.query({
            query: (args) => getMonthlyExpensesByCategoryUrl(args),
            keepUnusedDataFor: 86400, // Keep data in cache for 1 day
        }),
        getPlaidAccountMappings: builder.query({
            query: (providerId) =>
                `/provider/${providerId}/onboarding/mappings`,
            providesTags: ["plaidAccountMappings"],
        }),
        updatePlaidAccountMapping: builder.mutation({
            query: ({ providerId, body }) => ({
                url: `/provider/${providerId}/onboarding/mappings`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["plaidAccountMappings"],
        }),
        getMonthlyLedgerTotals: builder.query({
            query: (args) => getMonthlyLedgerTotalsUrl(args),
        }),
        onboardProviderToTeal: builder.mutation({
            query: ({ body }) => ({
                url: `/teal/onboard`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["plaidAccountMappings"],
        }),
        getEntriesStart: builder.query({
            query: ({ providerId, businessEntityId }) =>
                getEntriesStartUrl({ providerId, businessEntityId }),
            providesTags: ["entriesStart"],
        }),
        updateEntriesStart: builder.mutation({
            query: ({ providerId, businessEntityId, body }) => ({
                url: getEntriesStartUrl({ providerId, businessEntityId }),
                method: "PUT",
                body,
            }),
            invalidatesTags: ["entriesStart"],
        }),
    }),
});

export const {
    useGetProvidersQuery,
    useGetProviderBankAccountDataQuery,
    useGetBankBalancesQuery,
    useGetTasksQuery,
    useGetTestUsersQuery,
    useDeleteUserMutation,
    useGetDataForNetCashFlowGraphQuery,
    useGetDataForCashBalanceGraphQuery,
    useGetDataForNetProfitGraphQuery,
    useGetDataForTopExpensesGraphQuery,
    useGetRevenueByCategoryQuery,
    useGetDataForFinancialReportsSummaryQuery,
    useGetDataForIncomeStatementQuery,
    useGetDataForBalanceSheetQuery,
    useGetDataForCashFlowReportQuery,
    useGetDataForLedgerLinesQuery,
    useGetProvidersOnboardedToTealQuery,
    useGetProviderQuery,
    useCollectPaymentMutation,
    useGetFinanceReportForProviderQuery,
    useGetDrawPaymentCrosscheckQuery,
    useLastPaymentCheckQuery,
    useUpdateLastPaymentMutation,
    useGetExpiredPlaidItemsQuery,
    useGetActiveBookkeeperSubscriptionsQuery,
    useGetBusinessEntitiesQuery,
    useGetTealInstancesQuery,
    useGetFinancialReportsValidUpUntilQuery,
    useGetPlaidItemsQuery,
    useLazyGetPlaidLinkTokenQuery,
    useAddPlaidItemMutation,
    useUpdatePlaidItemMutation,
    useGetLineForAdvancedPaymentOnClaimsQuery,
    useGetDataForAdvancedPaymentOnClaimsQuery,
    useLazyDownloadIncomeStatementQuery,
    useLazyDownloadBalanceSheetQuery,
    useLazyDownloadExpensesByCategoryQuery,
    useLazyDownloadCashFlowReportQuery,
    useUpdateValidUpUntilMutation,
    useGetExpensesByMerchantQuery,
    useSetupBusinessEntityMutation,
    useCreateTealInstanceMutation,
    useGetMonthlyRevenueByCategoryQuery,
    useGetMonthlyExpensesByCategoryQuery,
    useGetPlaidAccountMappingsQuery,
    useUpdatePlaidAccountMappingMutation,
    useGetMonthlyLedgerTotalsQuery,
    useOnboardProviderToTealMutation,
    useGetEntriesStartQuery,
    useUpdateEntriesStartMutation,
} = adminApiSlice;

export const { getBusinessEntities, getFinancialReportsValidUpUntil } =
    adminApiSlice.endpoints;
