import React from "react";
import SectionTitle from "../../SectionTitle";
import Stat from "./Stat";
import styles from "./styles.module.css";

// TODO: add more stats when the Treasure compliance team gets back to us.
function TrustedAdvisor() {
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>A Trusted and Credible Advisor</SectionTitle>
            </div>
            <div className={styles.subtitle}>
                Our treasury management team has managed billions of dollars for
                large hedge funds, asset managers, and pension funds.
            </div>
            <div className={styles.statsContainer}>
                <Stat title="300+" subtitle="customers" />
                <Stat title="1,500+" subtitle="funds tracked" />
                <Stat
                    title="35+"
                    subtitle="years of combined investment experience"
                />
            </div>
        </div>
    );
}

export default TrustedAdvisor;
