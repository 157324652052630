import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.css";

function HorizontalTabs({ tabs, onChange }) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabClick = (index) => {
        setActiveTabIndex(index);
        onChange(index);
    };

    return (
        <div className={styles.container}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={classNames(styles.tab, {
                        [styles.active]: activeTabIndex === index,
                    })}
                    onClick={() => handleTabClick(index)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
}

HorizontalTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default HorizontalTabs;
