import React from "react";
import SelectedProvider from "../SelectedProvider";
import SharedBalanceSheet from "../../../FinancialReports/BalanceSheet";

function BalanceSheet() {
    return (
        <div>
            <SelectedProvider />
            <SharedBalanceSheet />
        </div>
    );
}

export default BalanceSheet;
