import { useParams } from "react-router-dom";
import { useUpdatePlaidItemMutation as useAdminUpdatePlaidItemMutation } from "../../../redux/slices/adminApi";
import { useUpdatePlaidItemMutation as useProviderUpdatePlaidItemMutation } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useUpdatePlaidItemMutation = () => {
    const isAdminDashboard = useIsAdminDashboard();
    const { providerId } = useParams();

    const [adminTrigger, adminResult, adminReset] =
        useAdminUpdatePlaidItemMutation();

    const [providerTrigger, providerResult, providerReset] =
        useProviderUpdatePlaidItemMutation();

    const trigger = (plaidItemId) => {
        const body = {
            plaid_item_id: plaidItemId,
        };

        if (isAdminDashboard) {
            return adminTrigger({
                providerId,
                body,
            });
        }

        return providerTrigger(body);
    };

    return [
        trigger,
        isAdminDashboard ? adminResult : providerResult,
        isAdminDashboard ? adminReset : providerReset,
    ];
};

export default useUpdatePlaidItemMutation;
