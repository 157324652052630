import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.css";

function Dropdown({ value, options, onClick, alignment }) {
    return (
        <div className={classNames(styles.container, styles[alignment])}>
            {options.map((option) => (
                <div
                    key={option.value}
                    onClick={() => onClick(option)}
                    className={classNames(styles.optionContainer, {
                        [styles.selected]: value === option.value,
                    })}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
}

Dropdown.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    alignment: PropTypes.oneOf(["left", "right"]).isRequired,
};

export default Dropdown;
