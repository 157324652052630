import React from "react";
import styles from "./styles.module.css";

function Complete() {
    return (
        <>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Thank you!</PageTitle>
            </div>
            <div className={styles.subtitle}>
                We will review your information and send your agreement to sign
                within two business days. If you do not hear back within two
                business days, please contact your CoachCare account manager.
            </div>
        </>
    );
}

export default Complete;
