import React from "react";
import SectionTitle from "../../SectionTitle";
import Card from "./Card";
import styles from "./styles.module.css";

function Security() {
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Your Funds Are Safe and Secure</SectionTitle>
            </div>
            <div className={styles.subtitle}>
                Our treasury management solution has multiple layers of
                protection to keep your funds safe and secure.
            </div>
            <div className={styles.cardGrid}>
                <Card
                    title="Your Assets Sit In Your Own Account"
                    subtitle="Your securities are safely stored under your own
                            name in your own account with our third-party
                            custodian, Apex. Apex has more than $100 Bn in
                            custody and is used by companies like Betterment,
                            SoFi, and M1 Finance."
                />
                <Card
                    title="SIPC Insured"
                    subtitle="SIPC Insurance is the equivalent of FDIC but for
                            brokerage accounts. SIPC protects against the loss
                            of cash and securities up to $500,000.00, which includes a $250,000.00 limit for cash."
                />
                <Card
                    title="Registered With the SEC"
                    subtitle="Treasury management and investment is provided by Treasure Investment Management (“Treasure”), which is registered with the SEC."
                />
                <Card
                    title="35+ Years of Combined Investment Experience"
                    subtitle="Our treasury management team has managed billions of
                            dollars for large hedge funds, asset managers, and
                            pension funds."
                />
            </div>
        </div>
    );
}

export default Security;
