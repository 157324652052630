import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../../Button";
import SectionTitle from "../../../../../SectionTitle";
import { useUpdateLastPaymentMutation } from "../../../../../../redux/slices/adminApi";
import { getCurrencyString } from "../../../../../../utils";
import styles from "./styles.module.css";

const handleButtonClick = (
    paymentId,
    drawOutstandingBalanceCents,
    updateLastPayment,
    setPayments
) => {
    const body = {
        payment_id: paymentId,
    };

    updateLastPayment(body).then(() => {
        setPayments((prevPayments) =>
            prevPayments.map((prevPayment) => {
                if (prevPayment.id === paymentId) {
                    return {
                        ...prevPayment,
                        payment_amount: drawOutstandingBalanceCents,
                    };
                }
                return prevPayment; // Return the unchanged payment if there's no ID match
            })
        );
    });
};

function ToUpdateTable({ payments, setPayments, lastPaymentData }) {
    const [updateLastPayment] = useUpdateLastPaymentMutation();
    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <SectionTitle>Update Last Payment</SectionTitle>
                <table>
                    <thead>
                        <tr>
                            <th>Payment ID</th>
                            <th>Earliest Payment Date</th>
                            <th>Provider ID</th>
                            <th>Payment Amount</th>
                            <th>Draw Outstanding Balance</th>
                            <th>Draw ID</th>
                            <th>Update Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => {
                            const lastPayment = lastPaymentData.find(
                                (p) => p.payment_id === payment.id
                            );
                            if (!lastPayment) {
                                return null; // Ignore payments with no matching lastPayment
                            }
                            return (
                                <tr key={payment.id}>
                                    <td>{payment.id}</td>
                                    <td>{payment.earliest_payment_date}</td>
                                    <td>{payment.provider_id}</td>
                                    <td>
                                        {getCurrencyString(
                                            payment.payment_amount
                                        )}
                                    </td>
                                    <td>
                                        {getCurrencyString(
                                            lastPayment.draw_outstanding_balance_cents
                                        )}
                                    </td>
                                    <td>{payment.draw_id}</td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                handleButtonClick(
                                                    payment.id,
                                                    lastPayment.draw_outstanding_balance_cents,
                                                    updateLastPayment,
                                                    setPayments
                                                )
                                            }
                                            disabled={
                                                lastPayment.draw_outstanding_balance_cents ===
                                                payment.payment_amount
                                            }
                                        >
                                            Update Payment Amount
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

ToUpdateTable.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setPayments: PropTypes.func.isRequired,
    lastPaymentData: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default ToUpdateTable;
