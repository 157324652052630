import { useParams } from "react-router-dom";
import { useGetDataForBalanceSheetQuery as useAdminGetDataForBalanceSheetQuery } from "../../../redux/slices/adminApi";
import { useGetDataForBalanceSheetQuery as useProviderGetDataForBalanceSheetQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../useGetFinancialReportsValidUpUntilQuery";

const useGetDataForBalanceSheetQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const endDate = validUpUntilData.valid_up_until;

    if (isAdminDashboard) {
        return useAdminGetDataForBalanceSheetQuery({
            providerId,
            businessEntityId,
            endDate,
        });
    }

    return useProviderGetDataForBalanceSheetQuery({
        businessEntityId,
        endDate,
    });
};

export default useGetDataForBalanceSheetQuery;
