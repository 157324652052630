import { useParams } from "react-router-dom";
import { useGetMonthlyRevenueByCategoryQuery as useAdminGetMonthlyRevenueByCategoryQuery } from "../../../redux/slices/adminApi";
import { useGetMonthlyRevenueByCategoryQuery as useProviderGetMonthlyRevenueByCategoryQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetMonthlyRevenueByCategoryQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetMonthlyRevenueByCategoryQuery({
            providerId,
            businessEntityId,
        });
    }

    return useProviderGetMonthlyRevenueByCategoryQuery(businessEntityId);
};

export default useGetMonthlyRevenueByCategoryQuery;
