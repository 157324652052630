import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {
    useAcceptTermsMutation,
    useGetTermsQuery,
} from "../../redux/slices/api";
import Text, { Subheading, Header } from "../Text";
import Button from "../Button";
import Iframe from "../Iframe";
import styles from "./styles.module.css";

function Terms() {
    const { drawId } = useParams();
    const { data } = useGetTermsQuery(drawId);

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const navigate = useNavigate();

    const [acceptTerms] = useAcceptTermsMutation();

    const onSubmit = () => {
        const body = {
            draw_id: drawId,
            terms_url: data.terms_url,
        };
        acceptTerms(body);
        // We don't have to await for acceptTerms because we don't actually do anything in the POST request.
        navigate("/terms");
    };

    return data ? (
        <div className={styles.container}>
            <Header>Terms</Header>
            <Subheading>
                Please read the terms below and tick the box at the bottom of
                the page.
            </Subheading>
            <div className={styles.iframeContainer}>
                <Iframe
                    src={data.terms_url}
                    title="Terms"
                    width="100%"
                    height="100%"
                />
            </div>
            <div className={styles.checkboxContainer}>
                <Checkbox checked={checked} onChange={handleChange} />
                <Text>I have read and agreed to the terms above.</Text>
            </div>
            <div className={styles.buttonContainer}>
                <Button color="primary" onClick={onSubmit} disabled={!checked}>
                    Submit
                </Button>
            </div>
        </div>
    ) : null;
}

export default Terms;
