import React from "react";
import Card from "../../../../Card";
import styles from "./styles.module.css";

function ValuationExplanation() {
    return (
        <Card showTab={false}>
            <div className={styles.container}>
                <div className={styles.explanationTitle}>
                    How is the Valuation Range calculated?
                </div>
                <div className={styles.stepContainer}>
                    <div className={styles.stepTitle}>
                        1. Calculate Adjusted EBITDA for the past year
                    </div>
                    <div className={styles.stepFigureContainer}>
                        <span className={styles.blur}>$3,000,000</span>
                    </div>
                </div>
                <div className={styles.stepContainer}>
                    <div className={styles.stepTitle}>2. Apply multipliers</div>
                    <div className={styles.stepFigureContainer}>
                        <span className={styles.blur}>2</span>x to{" "}
                        <span className={styles.blur}>3</span>x of Adjusted
                        EBITDA
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default ValuationExplanation;
