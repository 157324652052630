import React from "react";
import { useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../../../redux/slices/adminApi";
import PageTitle from "../../../PageTitle";
import styles from "./styles.module.css";

function SelectedProvider() {
    const { providerId } = useParams();
    const { data: provider } = useGetProviderQuery(providerId);
    return provider ? (
        <div className={styles.container}>
            <div className={styles.label}>Currently Selected Provider</div>
            <PageTitle>{provider.legal_name}</PageTitle>
        </div>
    ) : null;
}

export default SelectedProvider;
