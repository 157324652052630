import firebase from "firebase/compat/app";

const firebaseConfig = {
    apiKey: "AIzaSyAQcC9AMTSlaVz4Mtpq0t-irw-E0rJb3OE",
    authDomain: "flychain-juniper.firebaseapp.com",
    projectId: "flychain-juniper",
    storageBucket: "flychain-juniper.appspot.com",
    messagingSenderId: "94146836234",
    appId: "1:94146836234:web:54efaa9fae74a630082cf3",
    measurementId: "G-E1EN7WJ89S",
};

firebase.initializeApp(firebaseConfig);
