import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Label({ children }) {
    return <span className={styles.label}>{children}</span>;
}

Label.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Label;
