import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import PERIODS from "../constants";
import styles from "./styles.module.css";

const displayValue = (value) => {
    switch (value) {
        case PERIODS.MONTH:
            return "Month";
        case PERIODS.DAY:
            return "Day";
        default:
            throw new Error("Unknown period");
    }
};

function Period({ value, isSelected, onClick }) {
    const handleClick = () => {
        onClick(value);
    };
    return (
        <div
            className={classNames(styles.container, {
                [styles.selected]: isSelected,
            })}
            onClick={handleClick}
        >
            {displayValue(value)}
        </div>
    );
}

Period.propTypes = {
    value: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Period;
