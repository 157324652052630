import React from "react";
import PageTitle from "../../../PageTitle";
import styles from "./styles.module.css";

function Check() {
    // placeholder for now
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle> Check </PageTitle>
            </div>
            <div className={styles.subtitle}>
                {" "}
                Perform automated and manual checks to ensure the platform is
                ready to view.
            </div>
            <div className={styles.subtitle}>
                {" "}
                1. Go to admin financial reports and ensure all entities and
                accounts look connected. Run debug calls to see all transaction
                source accounts etc.
            </div>
            {/* add url nav here */}
            <div className={styles.subtitle}>
                {" "}
                2. Communicate to Blubooks that bookkeeping can begin. When
                bookkeeping is complete then proceed ...
            </div>
            <div className={styles.subtitle}>
                {" "}
                3. Make signed_saas_agreement_url none null for provider
            </div>
            <div className={styles.subtitle}> 4. Set to platform_active</div>
            {/* update here? show if platform active */}
        </div>
    );
}

export default Check;
