import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const shouldDisableDate = (date, onlyAllowLastDayOfMonth) => {
    if (!onlyAllowLastDayOfMonth) {
        return false;
    }
    const lastDayOfMonth = date.endOf("month").date();
    return date.date() !== lastDayOfMonth;
};

function ValidUpUntilPicker({ value, onChange, onlyAllowLastDayOfMonth }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Select Valid Up Until"
                value={value}
                onChange={onChange}
                shouldDisableDate={(date) =>
                    shouldDisableDate(date, onlyAllowLastDayOfMonth)
                }
            />
        </LocalizationProvider>
    );
}

ValidUpUntilPicker.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onlyAllowLastDayOfMonth: PropTypes.bool,
};

ValidUpUntilPicker.defaultProps = {
    onlyAllowLastDayOfMonth: true,
};

export default ValidUpUntilPicker;
