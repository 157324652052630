import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import styles from "./styles.module.css";

function Banner({ title, body, buttonText, buttonOnClick }) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.body}>{body}</div>
            <div>
                <Button color="primary" onClick={buttonOnClick}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonOnClick: PropTypes.func.isRequired,
};

export default Banner;
