import REPORT_TYPES from "../../components/FinancialReports/constants";
import useLazyDownloadIncomeStatementQuery from "../queriesAndMutations/useLazyDownloadIncomeStatementQuery";
import useLazyDownloadCashFlowReportQuery from "../queriesAndMutations/useLazyDownloadCashFlowReportQuery";
import useLazyDownloadBalanceSheetQuery from "../queriesAndMutations/useLazyDownloadBalanceSheetQuery";
import useLazyDownloadExpensesByCategoryQuery from "../queriesAndMutations/useLazyDownloadExpensesByCategoryQuery";
import useGetReportType from "../useGetReportType";

const useLazyDownloadReportQuery = () => {
    const reportType = useGetReportType();

    switch (reportType) {
        case REPORT_TYPES.INCOME_STATEMENT:
            return useLazyDownloadIncomeStatementQuery();
        case REPORT_TYPES.CASH_FLOW:
            return useLazyDownloadCashFlowReportQuery();
        case REPORT_TYPES.BALANCE_SHEET:
            return useLazyDownloadBalanceSheetQuery();
        case REPORT_TYPES.EXPENSES_BY_CATEGORY:
            return useLazyDownloadExpensesByCategoryQuery();
        default:
            throw new Error(`Unknown report type: ${reportType}`);
    }
};

export default useLazyDownloadReportQuery;
