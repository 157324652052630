import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Content({ children }) {
    const containerRef = useRef(null);

    // Whenever the url changes i.e. user navigates to a new page, we want to scroll to the top of the container
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [window.location.href]);

    return (
        <div ref={containerRef} className={styles.container}>
            {children}
        </div>
    );
}

Content.propTypes = {
    children: PropTypes.node,
};

Content.defaultProps = {
    children: undefined,
};

export default Content;
