import { useLocation } from "react-router-dom";
import REPORT_TYPES from "../../components/FinancialReports/constants";

const useGetReportType = () => {
    const location = useLocation();
    const { pathname } = location;

    if (pathname.includes("/incomeStatement")) {
        return REPORT_TYPES.INCOME_STATEMENT;
    }
    if (pathname.includes("/cashFlow")) {
        return REPORT_TYPES.CASH_FLOW;
    }
    if (pathname.includes("/balanceSheet")) {
        return REPORT_TYPES.BALANCE_SHEET;
    }
    if (pathname.includes("/expenses")) {
        return REPORT_TYPES.EXPENSES_BY_CATEGORY;
    }
    if (pathname.includes("/revenue")) {
        return REPORT_TYPES.REVENUE_BY_CATEGORY;
    }

    throw new Error(`Unknown report type for pathname ${pathname}`);
};

export default useGetReportType;
