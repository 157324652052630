import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Table({ headers, rows }) {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {headers.map((header, headerIndex) => (
                        <th key={headerIndex}>
                            <div className={styles.headerContainer}>
                                {header}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>
                                <div className={styles.cellContainer}>
                                    {cell}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

Table.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
    ).isRequired,
};

export default Table;
