import React from "react";
import PropTypes from "prop-types";
import PeriodButton from "./PeriodButton";
import { PERIODS } from "../../utils";
import styles from "./styles.module.css";

function PeriodPicker({ period, onChange }) {
    return (
        <div className={styles.container}>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.MONTHLY}
                    isSelectedPeriod={period === PERIODS.MONTHLY}
                    onClick={onChange}
                />
            </div>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.QUARTERLY}
                    isSelectedPeriod={period === PERIODS.QUARTERLY}
                    onClick={onChange}
                />
            </div>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.ANNUAL}
                    isSelectedPeriod={period === PERIODS.ANNUAL}
                    onClick={onChange}
                />
            </div>
        </div>
    );
}

PeriodPicker.propTypes = {
    period: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

PeriodPicker.defaultProps = {
    period: null,
};

export default PeriodPicker;
