/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../utils";
import styles from "./styles.module.css";

const getFlex = (outstandingBalanceCents, availableBalanceCents) => {
    const percentage =
        outstandingBalanceCents /
        (outstandingBalanceCents + availableBalanceCents);
    // Round to two decimal places
    const rounded = Math.round(percentage * 100) / 100;
    // Cap the value at 1
    return Math.min(rounded, 1);
};

function Line({ outstandingBalanceCents, availableBalanceCents }) {
    const outstandingBalanceLineStyle = {
        flex: getFlex(outstandingBalanceCents, availableBalanceCents),
    };
    return (
        <div className={styles.container}>
            <div className={styles.lineContainer}>
                <div
                    className={styles.outstandingBalanceLine}
                    style={outstandingBalanceLineStyle}
                />
            </div>
            <div className={styles.labelsContainer}>
                <div>
                    <div className={styles.label}>Outstanding balance</div>
                    <div className={styles.number}>
                        {getCurrencyString(outstandingBalanceCents)}
                    </div>
                </div>
                <div className={styles.textAlignRight}>
                    <div className={styles.label}>Available funding</div>
                    <div className={styles.number}>
                        {getCurrencyString(availableBalanceCents)}
                    </div>
                </div>
            </div>
        </div>
    );
}

Line.propTypes = {
    outstandingBalanceCents: PropTypes.number.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
};

export default Line;
