import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Card({ title, body }) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.body}>{body}</div>
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
};

export default Card;
