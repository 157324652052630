import React from "react";
import Card from "../../../Card";
import SectionTitle from "../../../SectionTitle";
import styles from "./styles.module.css";

function ValuationFactors() {
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>What Factors Affect Your Valuation?</SectionTitle>
            </div>
            <div className={styles.subheading}>
                Within healthcare, we see businesses trading from 3x to as high
                as 10x their adjusted EBITDA. The multiplier that is ultimately
                applied depends on multiple factors including:
            </div>
            <div className={styles.row}>
                <div className={styles.cardContainer}>
                    <Card showTab={false}>
                        <div className={styles.content}>
                            <div className={styles.title}>Financial Health</div>
                            <div className={styles.body}>
                                Revenue, profit margin, expenses, locations,
                                payer mix, revenue cycle, and types of services
                                offered are all factors that affect the
                                financial health of your business.
                            </div>
                        </div>
                    </Card>
                </div>
                <div className={styles.cardContainer}>
                    <Card showTab={false}>
                        <div className={styles.content}>
                            <div className={styles.title}>Tenure of Staff</div>
                            <div className={styles.body}>
                                Having a mature management staff that can take
                                over following your exit is an important
                                consideration for buyers. In addition, buyers
                                may analyze your organization&apos;s ability to
                                retain and hire employees especially given the
                                staffing shortage within healthcare.
                            </div>
                        </div>
                    </Card>
                </div>
                <Card showTab={false}>
                    <div className={styles.content}>
                        <div className={styles.title}>Quality of Services</div>
                        <div className={styles.body}>
                            Demonstrating positive patient satisfaction results
                            can increase your valuation. It is helpful to show
                            quality assurance data and other similar information
                            that can quantify the strength of your services and
                            the people delivering them.
                        </div>
                    </div>
                </Card>
            </div>
            <div className={styles.row}>
                <div className={styles.cardContainer}>
                    <Card showTab={false}>
                        <div className={styles.content}>
                            <div className={styles.title}>Location</div>
                            <div className={styles.body}>
                                The state(s) you operate in can play into your
                                valuation due to factors such as local
                                reimbursement rates, availability of staff to
                                hire at an affordable rate, and the friendliness
                                of that state for running a business.
                            </div>
                        </div>
                    </Card>
                </div>
                <Card showTab={false}>
                    <div className={styles.content}>
                        <div className={styles.title}>Healthcare Vertical</div>
                        <div className={styles.body}>
                            This is a moving target and the pendulum swings
                            based on the verticals that private equity firms are
                            most excited about. Working with our experts, who
                            have a pulse on real time transaction activity
                            within your specific vertical can help.
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default ValuationFactors;
