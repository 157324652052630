import React from "react";
import PageTitle from "../../../PageTitle";
import Card from "./Card";
import styles from "./styles.module.css";

const firstRowCards = [
    {
        title: "Deep Expertise",
        body: "The team at Rivet has spent a decade working as auditors at the IRS and as heads of private practice.",
    },
    {
        title: "Fixed Price Engagements",
        body: "Rivet gives upfront and transparent quotes so incentives are aligned and you don't receive any surprise invoices.",
    },
    {
        title: "Corporate & Personal",
        body: "Both corporate & personal tax returns can be combined into one engagement for any legal entity including C Corps, S Corps, LLCs, and Sole Prop LLCs.",
    },
];

const secondRowCards = [
    {
        title: "R&D Credits",
        body: "Secure R&D credits and other tax refunds at both the federal & state level to maximize your tax savings.",
    },
    {
        title: "Audit Defenses",
        body: "Rivet will help with audit defenses even if a different CPA prepared the original return.",
    },
    {
        title: "Seamless Filing",
        body: "Flychain works directly with Rivet to take as much of the tax filing process off your plate.",
    },
];

function TaxBenefits() {
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>A Seamless Tax Filing Experience</PageTitle>
            </div>
            <div className={styles.row}>
                {firstRowCards.map((card, i) => (
                    <Card key={i} title={card.title} body={card.body} />
                ))}
            </div>
            <div className={styles.row}>
                {secondRowCards.map((card, i) => (
                    <Card key={i} title={card.title} body={card.body} />
                ))}
            </div>
        </div>
    );
}

export default TaxBenefits;
