import { useParams } from "react-router-dom";
import { useGetBusinessEntitiesQuery as useAdminGetBusinessEntitiesQuery } from "../../../redux/slices/adminApi";
import { useGetBusinessEntitiesQuery as useProviderGetBusinessEntitiesQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetBusinessEntitiesQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetBusinessEntitiesQuery(providerId);
    }

    return useProviderGetBusinessEntitiesQuery();
};

export default useGetBusinessEntitiesQuery;
