import React from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../PageTitle";
import CapitalProduct from "./CapitalProduct";
import styles from "./styles.module.css";

// TODO: replace copy after chatting with Christine
function CapitalProducts() {
    const navigate = useNavigate();

    const onClickForAdvancedPaymentOnClaims = () => {
        navigate("advancedPaymentOnClaims");
    };

    const onClickForLineOfCredit = () => {
        navigate("lineOfCredit");
    };

    const onClickForTermLoan = () => {
        navigate("termLoan");
    };

    const onClickForSBAPrograms = () => {
        navigate("sbaPrograms");
    };

    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Our Capital Products</PageTitle>
            </div>
            <div className={styles.row}>
                <div className={styles.capitalProductContainer}>
                    <CapitalProduct
                        title="Advanced Payment on Claims"
                        body="Enroll in our Advanced Payment on Claims program to bring forward your insurance reimbursements so you don't have to wait 1-4 months to get paid. Almost all providers qualify and we can fund within 2 business days."
                        onClick={onClickForAdvancedPaymentOnClaims}
                    />
                </div>
                <CapitalProduct
                    title="Line of Credit"
                    body="Secure a line of credit for a rainy day. Draw down on your line whenever you need funding: no origination fees, no unused line fees, no prepayment penalties!"
                    onClick={onClickForLineOfCredit}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.capitalProductContainer}>
                    <CapitalProduct
                        title="Term Loan"
                        body="Get a term loan to fund your next big growth initiative. We offer term loans with repayment periods of 6 months to 2 years and we can fund within 3 business days."
                        onClick={onClickForTermLoan}
                    />
                </div>
                <CapitalProduct
                    title="SBA Programs"
                    body="Apply for an SBA loan to get access to some of the most favorable rates in the country! In addition, the SBA Express program can get you funded within 5 business days."
                    onClick={onClickForSBAPrograms}
                />
            </div>
            <div className={styles.row}>
                <CapitalProduct
                    title="Acquisition Financing"
                    body="Expand your practice to new locations and states by leveraging our acquisition financing. Our program is purpose-built for healthcare and is intended to help take your practice to the next level."
                    comingSoon
                />
            </div>
        </div>
    );
}

export default CapitalProducts;
