import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import CapitalSvg from "../../../assets/Capital";
import FinancialReportsSvg from "../../../assets/FinancialReports";
import SavingsSvg from "../../../assets/Savings";
import ValuationsSvg from "../../../assets/Valuations";
import TaxesSvg from "../../../assets/Taxes";
import WalletSvg from "../../../assets/Wallet";
import ConnectionsSvg from "../../../assets/Connections";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

const financialReportsSubtabs = [
    {
        label: "Income Statement",
        path: "/incomeStatement",
    },
    {
        label: "Cash Flow",
        path: "/cashFlow",
    },
    {
        label: "Balance Sheet",
        path: "/balanceSheet",
    },
    {
        label: "Revenue",
        path: "/revenue",
    },
    {
        label: "Expenses",
        path: "/expenses",
    },
];

const capitalSubtabs = [
    {
        label: "Advanced Claims",
        path: "/advancedPaymentOnClaims",
    },
    {
        label: "Line Of Credit",
        path: "/lineOfCredit",
    },
    {
        label: "Term Loan",
        path: "/termLoan",
    },
    {
        label: "SBA",
        path: "/sbaPrograms",
    },
];

function SideBarAndContent() {
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    businessEntityId =
        businessEntityId || (businessEntities && businessEntities[0].id);

    return businessEntityId ? (
        <>
            <SideBar>
                <NavigationTab
                    label="Financials"
                    path={`/businessEntity/${businessEntityId}/financialReports`}
                    icon={<FinancialReportsSvg fill="white" />}
                    subtabs={financialReportsSubtabs}
                />
                <NavigationTab
                    label="Capital"
                    path="/capital"
                    icon={<CapitalSvg fill="white" />}
                    subtabs={capitalSubtabs}
                />
                <NavigationTab
                    label="Taxes"
                    path="/taxes"
                    icon={<TaxesSvg fill="white" />}
                />
                <NavigationTab
                    label="Valuations"
                    path="/valuations"
                    icon={<ValuationsSvg fill="white" />}
                />
                <NavigationTab
                    label="Treasury"
                    path="/treasury"
                    icon={<WalletSvg fill="white" />}
                />
                <NavigationTab
                    label="Savings"
                    path="/savings"
                    icon={<SavingsSvg fill="white" />}
                />
                <NavigationTab
                    label="Connections"
                    path="/connections"
                    icon={<ConnectionsSvg fill="white" />}
                />
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    ) : null;
}

export default SideBarAndContent;
