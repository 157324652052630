/* eslint-disable camelcase */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Table from "../../../../Table";
import Badge from "../../../../Badge";
import { getCurrencyString } from "../../../../../utils";

const HEADERS = ["Amount", "Repayment date", "Status"];

const getBadgeColor = (status) => {
    switch (status) {
        case "Upcoming":
            return "grey";
        case "Repaid":
            return "green";
        case "Overdue":
            return "red";
        default:
            return "grey";
    }
};

function RepaymentsTable({ repayments }) {
    const rows = useMemo(
        () =>
            repayments.map((repayment) => [
                `${getCurrencyString(repayment.payment_amount_cents)}`,
                repayment.payment_collection_date,
                <Badge color={getBadgeColor(repayment.payment_status)}>
                    {repayment.payment_status}
                </Badge>,
            ]),
        [repayments]
    );
    return <Table headers={HEADERS} rows={rows} />;
}

RepaymentsTable.propTypes = {
    repayments: PropTypes.arrayOf(
        PropTypes.shape({
            payment_id: PropTypes.string.isRequired,
            payment_amount_cents: PropTypes.number.isRequired,
            payment_status: PropTypes.string.isRequired,
            payment_collection_date: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};

export default RepaymentsTable;
