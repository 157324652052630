import React from "react";
import PropTypes from "prop-types";
import QuarterButton from "./QuarterButton";
import { QUARTERS } from "../../utils";
import styles from "./styles.module.css";

function QuarterPicker({ year, onChange, maxDate }) {
    return (
        <div className={styles.container}>
            <div className={styles.quarterButtonContainer}>
                <QuarterButton
                    year={year}
                    quarter={QUARTERS.Q1}
                    onClick={onChange}
                    maxDate={maxDate}
                />
            </div>
            <div className={styles.quarterButtonContainer}>
                <QuarterButton
                    year={year}
                    quarter={QUARTERS.Q2}
                    onClick={onChange}
                    maxDate={maxDate}
                />
            </div>
            <div className={styles.quarterButtonContainer}>
                <QuarterButton
                    year={year}
                    quarter={QUARTERS.Q3}
                    onClick={onChange}
                    maxDate={maxDate}
                />
            </div>
            <div className={styles.quarterButtonContainer}>
                <QuarterButton
                    year={year}
                    quarter={QUARTERS.Q4}
                    onClick={onChange}
                    maxDate={maxDate}
                />
            </div>
        </div>
    );
}

QuarterPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default QuarterPicker;
