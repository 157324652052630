import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import Subtab from "./Subtab";
import styles from "./styles.module.css";
import themeStyles from "../../../themes/styles";

function NavigationTab({ path, label, icon, subtabs }) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(path);
    };

    const location = useLocation();
    const mainTabContainerStyle = classNames(styles.mainTabContainer, {
        [themeStyles.currentTab]: path === location.pathname,
    });

    const showSubtabs = location.pathname.startsWith(path);

    return (
        <div className={styles.container}>
            <div className={mainTabContainerStyle} onClick={onClick}>
                <div className={styles.iconContainer}>{icon}</div>
                <div className={`${styles.label} ${themeStyles.tabText}`}>
                    {label}
                </div>
            </div>
            {showSubtabs
                ? subtabs.map((subtab, i) => {
                      if (i === subtabs.length - 1) {
                          return (
                              <div
                                  className={styles.lastSubtabContainer}
                                  key={subtab.path}
                              >
                                  <Subtab
                                      label={subtab.label}
                                      path={`${path}${subtab.path}`}
                                  />
                              </div>
                          );
                      }
                      return (
                          <Subtab
                              key={subtab.path}
                              label={subtab.label}
                              path={`${path}${subtab.path}`}
                          />
                      );
                  })
                : null}
        </div>
    );
}

NavigationTab.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    subtabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }).isRequired
    ),
};

NavigationTab.defaultProps = {
    subtabs: [],
};

export default NavigationTab;
