import useGetExpensesByMerchantQuery from "../useGetExpensesByMerchantQuery";

const useGetMerchantDetailQuery = (merchantId) => {
    const result = useGetExpensesByMerchantQuery();

    const { data } = result;

    if (!data) {
        return result;
    }

    const merchantDetail = data.find((merchant) => merchant.id === merchantId);

    if (!merchantDetail) {
        throw new Error(`No merchant with id ${merchantId} found`);
    }

    return { ...result, data: merchantDetail };
};

export default useGetMerchantDetailQuery;
