import React from "react";
import { useGetProvidersOnboardedToTealQuery } from "../../../redux/slices/adminApi";
import PageTitle from "../../PageTitle";
import Provider from "./Provider";
import styles from "./styles.module.css";

function BookkeepingDates() {
    const { data: providersOnboardedToTeal } =
        useGetProvidersOnboardedToTealQuery();

    return providersOnboardedToTeal ? (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Edit Bookkeeping Dates</PageTitle>
            </div>
            {providersOnboardedToTeal.map((provider) => (
                <Provider key={provider.id} provider={provider} />
            ))}
        </div>
    ) : null;
}

export default BookkeepingDates;
