import React from "react";
import SharedDefsContextProvider from "../SharedDefsContextProvider";
import Graph, { dataProps } from "./Graph";

function MonthlyGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <Graph data={data} />
        </SharedDefsContextProvider>
    );
}

MonthlyGraph.propTypes = {
    data: dataProps.isRequired,
};

export default MonthlyGraph;
