import React from "react";
import PageTitle from "../../../PageTitle";
import styles from "./styles.module.css";

function Preconditions() {
    // placeholder for now
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle> Preconditions </PageTitle>
            </div>
            <div className={styles.subtitle}>
                {" "}
                Before continuing the onboarding process please ensure the
                following are complete:
            </div>
            <div className={styles.subtitle}>
                {" "}
                1. Discovery call with bookkeeper. Please have a copy of the
                spreadsheet listing out all entities, accounts and their
                relationships before proceeding.
            </div>
            {/* one day can have google drive wired up here */}
            <div className={styles.subtitle}>
                {" "}
                2. All expected plaid accounts are connected:
            </div>
            {/* add display of plaid items */}
            <div className={styles.subtitle}>
                {" "}
                If any are missing please navigate to the connections tab to add
                them:
            </div>
            {/* add link to connections page */}
        </div>
    );
}

export default Preconditions;
