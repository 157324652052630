import React, { useState } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../hooks/useClickOutside";
import Menu from "./Menu";
import Control, { dateRangeProps } from "./Control";
import styles from "./styles.module.css";

function DateRangePicker({ dateRange, onChange, maxDate }) {
    const [showMenu, setShowMenu] = useState(false);

    const onClickOutside = () => {
        setShowMenu(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onClick = () => {
        setShowMenu(!showMenu);
    };

    const onDateRangeChange = (newDateRange) => {
        onChange(newDateRange);
        setShowMenu(false);
    };

    return (
        <div ref={containerRef} className={styles.container}>
            <Control dateRange={dateRange} onClick={onClick} />
            {showMenu && (
                <Menu
                    year={dateRange.startDate.year()}
                    onChange={onDateRangeChange}
                    maxDate={maxDate}
                />
            )}
        </div>
    );
}

DateRangePicker.propTypes = {
    dateRange: dateRangeProps.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default DateRangePicker;
