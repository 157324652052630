import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Button from "../../../../../Button";
import ValidUpUntilPicker from "./ValidUpUntilPicker";
import { useUpdateValidUpUntilMutation } from "../../../../../../redux/slices/adminApi";
import styles from "./styles.module.css";

function UpdateValidUpUntil({ validUpUntil, businessEntity }) {
    const [validUpUntilDate, setValidUpUntilDate] = useState(
        dayjs(validUpUntil)
    );

    const onChange = (newValidUpUntilDate) => {
        setValidUpUntilDate(newValidUpUntilDate);
    };

    const [updateValidUpUntil] = useUpdateValidUpUntilMutation();

    const onClick = () => {
        updateValidUpUntil({
            providerId: businessEntity.provider_id,
            businessEntityId: businessEntity.id,
            body: {
                valid_up_until: validUpUntilDate.format("YYYY-MM-DD"),
            },
        });
    };

    return (
        <div className={styles.container}>
            <ValidUpUntilPicker
                value={validUpUntilDate}
                onChange={onChange}
                // If the provider is Westfork then allow any day to be selected
                // TODO: remove this at a later date.
                onlyAllowLastDayOfMonth={
                    businessEntity.provider_id !==
                    "48a9f4aa-a29a-4abe-a451-db9c84023f65"
                }
            />
            <div className={styles.buttonContainer}>
                <Button
                    color="primary"
                    onClick={onClick}
                    disabled={
                        validUpUntil === validUpUntilDate.format("YYYY-MM-DD")
                    }
                >
                    Update
                </Button>
            </div>
        </div>
    );
}

UpdateValidUpUntil.propTypes = {
    validUpUntil: PropTypes.string.isRequired,
    businessEntity: PropTypes.object.isRequired,
};

export default UpdateValidUpUntil;
