import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Line,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { getCurrencyString } from "../../../../utils";
import { formatMonthYearStringForXAxis, tickStyle } from "../../utils";
import GraphTooltip from "./GraphTooltip";
import CustomLegend from "./Legend";
import { useSharedDefs } from "../../SharedDefsContextProvider";

function NetProfitGraph({ data }) {
    const dataPoints = useMemo(() => {
        if (data.length <= 12) {
            return data;
        }
        return data.slice(-12);
    }, [data]);

    const defs = useSharedDefs();

    return (
        <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={dataPoints} stackOffset="sign">
                <CartesianGrid
                    stroke="#CCCCCC"
                    strokeWidth={1}
                    strokeDasharray="3 3"
                    horizontal
                    vertical={false}
                />
                <XAxis
                    dataKey="month"
                    tick={tickStyle}
                    tickFormatter={formatMonthYearStringForXAxis}
                />
                <YAxis
                    tickFormatter={getCurrencyString}
                    width={100}
                    tick={tickStyle}
                />
                <Tooltip content={<GraphTooltip />} />
                <Legend content={<CustomLegend />} />
                {defs}
                <Bar
                    dataKey="revenue_cents"
                    stackId="stack"
                    barSize={24}
                    fill="url(#flychainPurple)"
                />
                <Bar
                    dataKey="expense_cents"
                    stackId="stack"
                    barSize={24}
                    fill="#CCCCCC"
                />
                <Line
                    type="monotone"
                    dataKey="net_profit_cents"
                    stroke="#F8CE39"
                    strokeWidth={1}
                    dot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export const dataProps = PropTypes.arrayOf(
    PropTypes.shape({
        month: PropTypes.string.isRequired,
        revenue_cents: PropTypes.number.isRequired,
        expense_cents: PropTypes.number.isRequired,
        net_profit_cents: PropTypes.number.isRequired,
    }).isRequired
);

NetProfitGraph.propTypes = {
    data: dataProps.isRequired,
};

export default NetProfitGraph;
