import React from "react";

import PlaidItemsTable from "./PlaidItemsTable";
import { useGetExpiredPlaidItemsQuery } from "../../../redux/slices/adminApi";

function ExpiredPlaidItems() {
    const { data: expiredPlaidItems } = useGetExpiredPlaidItemsQuery();

    return expiredPlaidItems ? (
        <div>
            <p>
                {" "}
                Note to admin: Raw Plaid Error is only reset after Plaid Balance
                is Processed - 9AM ET. To avoid any issues, best to do this
                check right after the Balance script is run. In the future, we
                should update raw_plaid_error after succesful plaid link update.{" "}
            </p>
            <PlaidItemsTable plaidItems={expiredPlaidItems} />
        </div>
    ) : null;
}

export default ExpiredPlaidItems;
