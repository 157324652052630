import React from "react";
import PropTypes from "prop-types";
import DayButton from "./DayButton";
import styles from "./styles.module.css";

const DAYS_PER_ROW = 7;

function DayPicker({ date, onChange, maxDate }) {
    const daysInMonth = date.daysInMonth();
    const dayButtons = Array.from({ length: daysInMonth }, (_, i) => (
        <div className={styles.dayButtonContainer} key={i}>
            <DayButton
                date={date}
                value={i}
                onClick={onChange}
                maxDate={maxDate}
            />
        </div>
    ));

    const rows = Array.from(
        { length: Math.ceil(dayButtons.length / DAYS_PER_ROW) },
        (_, rowIndex) => (
            <div className={styles.row} key={rowIndex}>
                {dayButtons.slice(
                    rowIndex * DAYS_PER_ROW,
                    (rowIndex + 1) * DAYS_PER_ROW
                )}
            </div>
        )
    );

    return <div className={styles.container}>{rows}</div>;
}

DayPicker.propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default DayPicker;
