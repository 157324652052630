import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ChevronsUpDownSvg from "../../../assets/ChevronsUpDown.svg";
import styles from "./styles.module.css";

function Display({ label, value, options, onClick }) {
    const optionLabel = useMemo(() => {
        const option = options.find((option) => option.value === value);
        return option ? option.label : label;
    }, [value, options]);

    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.optionLabel}>{optionLabel}</div>
            <img
                className={styles.chevronsUpDownIcon}
                src={ChevronsUpDownSvg}
                alt="ChevronsUpDown"
            />
        </div>
    );
}

Display.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Display;
