import React from "react";
import SharedDefsContextProvider from "../SharedDefsContextProvider";
import Graph, { dataProps } from "./Graph";

function NetCashFlowGraph({ data }) {
    return (
        <SharedDefsContextProvider>
            <Graph data={data} />
        </SharedDefsContextProvider>
    );
}

NetCashFlowGraph.propTypes = {
    data: dataProps.isRequired,
};

export default NetCashFlowGraph;
