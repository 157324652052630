import React, { useState } from "react";
import PropTypes from "prop-types";
import STATES from "./states";
import SelectFundingAmountModal from "./SelectFundingAmountModal";
import CalendlyModal from "../../../CalendlyModal";
import { CALENDLY_URLS } from "../../../CalendlyModal/constants";
import { getCurrencyString } from "../../../../utils";

function RequestFundingModal({ onClose, availableBalanceCents }) {
    const [state, setState] = useState(STATES.SELECT_FUNDING_AMOUNT);

    const [requestedFundingAmountCents, setRequestedFundingAmountCents] =
        useState(availableBalanceCents);

    if (state === STATES.SELECT_FUNDING_AMOUNT) {
        return (
            <SelectFundingAmountModal
                onClose={onClose}
                availableBalanceCents={availableBalanceCents}
                setRequestedFundingAmountCents={setRequestedFundingAmountCents}
                setState={setState}
            />
        );
    }

    return (
        <CalendlyModal
            onClose={onClose}
            calendlyUrl={CALENDLY_URLS.FUNDING_CALL}
            customAnswersPrefill={{
                a1: `I'm scheduling a call to request ${getCurrencyString(
                    requestedFundingAmountCents
                )}`,
            }}
        />
    );
}

RequestFundingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
};

export default RequestFundingModal;
