import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import RightCaretSvg from "../../../../assets/RightCaret.svg";
import { getCurrencyString } from "../../../../utils";
import COLORS from "../../../../styles/colors";
import styles from "./styles.module.css";

const getBorderStyle = (position) => {
    switch (position) {
        case "single":
            return {
                borderRadius: "8px 8px 8px 8px",
            };
        case "top":
            return {
                borderRadius: "8px 8px 0px 0px",
            };
        case "middle":
            return {
                borderTop: "none",
            };
        case "bottom":
            return {
                borderTop: "none",
                borderRadius: "0px 0px 8px 8px",
            };
        default:
            throw new Error("Invalid position");
    }
};

const getNumberOfIndents = (recordCategory) => {
    switch (recordCategory) {
        case "type": // consider renaming, decouple from shit teal names
            return 0;
        case "subtype":
            return 1;
        case "ledger":
            return 2;
        default:
            throw new Error("Invalid record category");
    }
};

function ReportLine({
    title,
    recordCategory,
    position,
    amountCents,
    maxRecordCategory,
    ledgerId,
}) {
    const numberOfIndents = getNumberOfIndents(recordCategory);
    const titleContainerStyle = {
        marginLeft: `${34 * numberOfIndents}px`,
    };

    const navigate = useNavigate();

    // assign onclick to console log ledger id as placeholder for get ledger statement
    // we want to make sure that the onClick is only available for the last category and if there is a ledgerId available
    const onClick =
        recordCategory === maxRecordCategory && ledgerId
            ? () => {
                  navigate(`ledgers/${ledgerId}`);
              }
            : undefined;

    const iconContainerStyle = {
        visibility: onClick ? "visible" : "hidden",
    };

    // TODO: have separate fonts for each recordCategory
    const fontStyle = {
        color: onClick ? COLORS.BLUE_PURPLE : "#000000",
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.clickable]: !!onClick,
            })}
            style={getBorderStyle(position)}
            onClick={onClick}
        >
            <div
                className={`${styles.titleContainerStyle} ${styles.font}`}
                style={{ ...titleContainerStyle, ...fontStyle }}
            >
                {title}
            </div>
            {amountCents !== undefined && (
                <div
                    className={`${styles.amountContainer} ${styles.font}`}
                    style={fontStyle}
                >
                    {getCurrencyString(amountCents)}
                </div>
            )}
            <div className={styles.iconContainer} style={iconContainerStyle}>
                <img
                    className={styles.rightCaretIcon}
                    src={RightCaretSvg}
                    alt="RightCaret"
                />
            </div>
        </div>
    );
}

ReportLine.propTypes = {
    title: PropTypes.string.isRequired,
    recordCategory: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    amountCents: PropTypes.number,
    maxRecordCategory: PropTypes.string.isRequired,
    ledgerId: PropTypes.string,
};

ReportLine.defaultProps = {
    amountCents: undefined,
    ledgerId: undefined,
};

export default ReportLine;
