import React, { useState } from "react";
import DownloadSvg from "../../../assets/Download.svg";
import Modal from "./Modal";
import styles from "./styles.module.css";

function ExportReportButton() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClose = () => {
        setIsModalOpen(false);
    };

    const onClick = () => {
        setIsModalOpen(true);
    };

    return (
        <div>
            <img
                src={DownloadSvg}
                alt="Download"
                onClick={onClick}
                className={styles.exportReportButton}
            />
            {isModalOpen && <Modal onClose={onClose} />}
        </div>
    );
}

export default ExportReportButton;
