import React from "react";
import SelectedProvider from "../SelectedProvider";
import SharedFinancialReportsHome from "../../../FinancialReports/FinancialReportsHome";

function FinancialReportsHome() {
    return (
        <div>
            <SelectedProvider />
            <SharedFinancialReportsHome />
        </div>
    );
}

export default FinancialReportsHome;
