import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import Button from "../../../Button";
import SharedModal from "../../../Modal";
import PageTitle from "../../../PageTitle";
import useLazyDownloadReportQuery from "../../../../hooks/useLazyDownloadReportQuery";
import {
    selectDateRange,
    serializeDateRange,
} from "../../../../redux/slices/dateRange";
import getReadableReportType from "../../utils";
import useDownloadFile from "../../../../hooks/useDownloadFile";
import useGetReportType from "../../../../hooks/useGetReportType";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import LabeledText from "../../../LabeledText";
import Label from "../../../Label";
import REPORT_TYPES from "../../constants";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import styles from "./styles.module.css";

function Modal({ onClose }) {
    const reportType = useGetReportType();

    const dateRange = useSelector(selectDateRange);
    const [currentDateRange, setCurrentDateRange] = useState(dateRange);
    const handleDateRangeChange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
    };

    const { businessEntityId } = useParams();
    const { data: businessEntities } = useGetBusinessEntitiesQuery();
    const businessEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    const businessEntityName =
        businessEntity.display_name || businessEntity.legal_name;

    const [trigger, { data: fileBase64 }] = useLazyDownloadReportQuery();

    useDownloadFile(fileBase64);

    const handleDownload = () => {
        trigger(serializeDateRange(currentDateRange));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return (
        <SharedModal onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>{`Download ${getReadableReportType(
                        reportType
                    )}`}</PageTitle>
                </div>
                <div className={styles.labeledTextContainer}>
                    <LabeledText
                        label="Business Entity Name"
                        text={businessEntityName}
                    />
                </div>
                {reportType !== REPORT_TYPES.BALANCE_SHEET && (
                    <>
                        <div className={styles.labelContainer}>
                            <Label>
                                Please confirm the date range for the report
                            </Label>
                        </div>
                        <CustomDateRangePicker
                            dateRange={currentDateRange}
                            onChange={handleDateRangeChange}
                            maxDate={maxDate}
                        />
                    </>
                )}
                <div className={styles.buttonsContainer}>
                    <div className={styles.buttonContainer}>
                        <Button color="primary" onClick={handleDownload}>
                            Download
                        </Button>
                    </div>
                    <Button color="secondary" onClick={onClose}>
                        Done
                    </Button>
                </div>
            </div>
        </SharedModal>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Modal;
