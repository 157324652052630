import flychain from "./themes/flychain.module.css";
import juniper from "./themes/juniper.module.css";
import coachcare from "./themes/coachcare.module.css";
import healthrev from "./themes/healthrev.module.css";
import rethink from "./themes/rethink.module.css";
import pario from "./themes/pario.module.css";
import endue from "./themes/endue.module.css";
import { THEMES, getFile } from "../index";

const mapping = {
    [THEMES.FLYCHAIN]: flychain,
    [THEMES.JUNIPER]: juniper,
    [THEMES.COACHCARE]: coachcare,
    [THEMES.HEALTHREV]: healthrev,
    [THEMES.RETHINK]: rethink,
    [THEMES.PARIO]: pario,
    [THEMES.ENDUE]: endue,
};

export default getFile(mapping);
