/* eslint-disable camelcase */
import React from "react";
import PlaidLinkButton from "../PlaidLinkButton";
import PlaidAccount from "../PlaidAccount";
import PlaidItemHeader from "../PlaidItemHeader";
import useGetPlaidItemsQuery from "../../hooks/queriesAndMutations/useGetPlaidItemsQuery";
import styles from "./styles.module.css";

function Connections() {
    const { data: plaidItems } = useGetPlaidItemsQuery();

    return plaidItems ? (
        <div>
            {plaidItems.map((plaidItem) => {
                const {
                    item: { item_id },
                    accounts,
                } = plaidItem;
                return (
                    <div key={item_id} className={styles.plaidItemContainer}>
                        <div className={styles.plaidItemHeaderContainer}>
                            <PlaidItemHeader plaidItem={plaidItem} />
                        </div>
                        {accounts.map((account) => {
                            const { account_id } = account;
                            return (
                                <div
                                    className={styles.plaidAccountContainer}
                                    key={account_id}
                                >
                                    <PlaidAccount account={account} />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <PlaidLinkButton />
        </div>
    ) : null;
}

export default Connections;
