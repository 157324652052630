import React from "react";
import EMAIL from "../../../copy/email";
import PageTitle from "../../../../components/PageTitle";
import styles from "./styles.module.css";

function Complete() {
    return (
        <>
            <div className={styles.pageTitleContainer}>
                <PageTitle>
                    You&apos;ve submitted all the information we need!
                </PageTitle>
            </div>
            <div className={styles.subtitle}>
                We&apos;re currently reviewing your information and we&apos;ll
                reach out via email within two business days with next steps. If
                you don&apos;t hear back from us within two business days,
                please send an email to <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </div>
        </>
    );
}

export default Complete;
