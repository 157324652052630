import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PERIODS } from "../../../utils";
import styles from "./styles.module.css";

const formatPeriod = (period) => {
    switch (period) {
        case PERIODS.ANNUAL:
            return "Annual";
        case PERIODS.QUARTERLY:
            return "Quarterly";
        case PERIODS.MONTHLY:
            return "Monthly";
        default:
            throw new Error("Unknown period");
    }
};

function PeriodButon({ period, isSelectedPeriod, onClick }) {
    const handleClick = () => {
        onClick(period);
    };
    return (
        <div
            className={classNames(styles.container, {
                [styles.selected]: isSelectedPeriod,
            })}
            onClick={handleClick}
        >
            {formatPeriod(period)}
        </div>
    );
}

PeriodButon.propTypes = {
    period: PropTypes.string.isRequired,
    isSelectedPeriod: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PeriodButon;
