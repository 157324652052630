import shared from "./shared";
import coachcare from "./themes/coachcare";
import { THEMES, getFile } from "../../index";

const mapping = {
    [THEMES.FLYCHAIN]: shared,
    [THEMES.JUNIPER]: shared,
    [THEMES.COACHCARE]: coachcare,
    [THEMES.HEALTHREV]: shared,
    [THEMES.RETHINK]: shared,
    [THEMES.PARIO]: shared,
    [THEMES.ENDUE]: shared,
};

export default getFile(mapping);
