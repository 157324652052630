import React from "react";
import styles from "./styles.module.css";
import CompleteContent from "../../themes/components/Complete";

function Complete() {
    return (
        <div className={styles.container}>
            <CompleteContent />
        </div>
    );
}

export default Complete;
