import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../../utils";
import PostCollectionTable from "../PostCollectionTable";
import DrawsTable from "./DrawsTable";
import { useGetFinanceReportForProviderQuery } from "../../../../../../redux/slices/adminApi";

function FinanceReport({ provider }) {
    const { data: financeReport } = useGetFinanceReportForProviderQuery(
        provider.id
    );
    return financeReport ? (
        <div>
            <h2>{provider.legal_name} Finance Report</h2>
            <h3> Balance Overview </h3>
            <h4> Total Principal </h4>
            <p>
                {getCurrencyString(
                    financeReport.balance_overview.total_principal
                )}
            </p>
            <h4> Total Fee </h4>
            <p>{getCurrencyString(financeReport.balance_overview.total_fee)}</p>
            <h4> Total Payment </h4>
            <p>
                {getCurrencyString(
                    financeReport.balance_overview.total_payment
                )}
            </p>
            <h4> Outstanding Balance </h4>
            <p>{getCurrencyString(financeReport.balance_overview.balance)}</p>
            <DrawsTable draws={financeReport.draws} />
            <PostCollectionTable
                payments={financeReport.payments}
                tableTitle="Previous Collections"
            />
            <PostCollectionTable
                payments={financeReport.upcoming_payments}
                tableTitle="Upcoming Collections"
            />
        </div>
    ) : null;
}

FinanceReport.propTypes = {
    provider: PropTypes.shape({
        id: PropTypes.string.isRequired,
        legal_name: PropTypes.string.isRequired,
    }).isRequired,
};

export default FinanceReport;
