import dayjs from "dayjs";

export const PERIODS = {
    ANNUAL: "ANNUAL",
    QUARTERLY: "QUARTERLY",
    MONTHLY: "MONTHLY",
};

export const MONTHS = {
    JANUARY: "JANUARY",
    FEBRUARY: "FEBRUARY",
    MARCH: "MARCH",
    APRIL: "APRIL",
    MAY: "MAY",
    JUNE: "JUNE",
    JULY: "JULY",
    AUGUST: "AUGUST",
    SEPTEMBER: "SEPTEMBER",
    OCTOBER: "OCTOBER",
    NOVEMBER: "NOVEMBER",
    DECEMBER: "DECEMBER",
};

const monthMap = {
    [MONTHS.JANUARY]: "01",
    [MONTHS.FEBRUARY]: "02",
    [MONTHS.MARCH]: "03",
    [MONTHS.APRIL]: "04",
    [MONTHS.MAY]: "05",
    [MONTHS.JUNE]: "06",
    [MONTHS.JULY]: "07",
    [MONTHS.AUGUST]: "08",
    [MONTHS.SEPTEMBER]: "09",
    [MONTHS.OCTOBER]: "10",
    [MONTHS.NOVEMBER]: "11",
    [MONTHS.DECEMBER]: "12",
};

export const QUARTERS = {
    Q1: "Q1",
    Q2: "Q2",
    Q3: "Q3",
    Q4: "Q4",
};

const isDateInRange = (startDate, endDate, dateToCheck) =>
    dateToCheck.isSame(startDate, "day") ||
    dateToCheck.isSame(endDate, "day") ||
    (dateToCheck.isAfter(startDate, "day") &&
        dateToCheck.isBefore(endDate, "day"));

export const getDateRangeForAnnual = (year, maxDate) => {
    const startDate = dayjs(`${year}-01-01`).startOf("day");
    let endDate = startDate.endOf("year");

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};

const getStartDateForQuarter = (year, quarter) => {
    let startDate;
    switch (quarter) {
        case QUARTERS.Q1:
            startDate = dayjs(`${year}-01-01`);
            break;
        case QUARTERS.Q2:
            startDate = dayjs(`${year}-04-01`);
            break;
        case QUARTERS.Q3:
            startDate = dayjs(`${year}-07-01`);
            break;
        case QUARTERS.Q4:
            startDate = dayjs(`${year}-10-01`);
            break;
        default:
            throw new Error("Unknown quarter");
    }
    return startDate.startOf("day");
};

const getEndDateForQuarter = (year, quarter) => {
    let endDate;
    switch (quarter) {
        case QUARTERS.Q1:
            endDate = dayjs(`${year}-03-31`);
            break;
        case QUARTERS.Q2:
            endDate = dayjs(`${year}-06-30`);
            break;
        case QUARTERS.Q3:
            endDate = dayjs(`${year}-09-30`);
            break;
        case QUARTERS.Q4:
            endDate = dayjs(`${year}-12-31`);
            break;
        default:
            throw new Error("Unknown quarter");
    }
    return endDate.endOf("day");
};

export const getDateRangeForQuarter = (year, quarter, maxDate) => {
    const startDate = getStartDateForQuarter(year, quarter);
    let endDate = getEndDateForQuarter(year, quarter);

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};

export const getDateRangeForMonth = (year, month, maxDate) => {
    const monthNumber = monthMap[month];
    const startDate = dayjs(`${year}-${monthNumber}-01`).startOf("day");
    let endDate = startDate.endOf("month");

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};

// used for balance sheet. arbitrarily subtract 200 years
export const getDateRangeForAllTimeToEndDate = (endDateStr) => {
    const endDate = dayjs(endDateStr).endOf("day");
    const startDate = endDate.subtract(200, "years").startOf("day");
    return {
        startDate,
        endDate,
    };
};
