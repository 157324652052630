import React from "react";
import { useParams } from "react-router-dom";
import NavigationTab from "../../../SideBar/NavigationTab";
import CapitalSvg from "../../../../assets/Capital";
import FinancialReportsSvg from "../../../../assets/FinancialReports";
import ConnectionsSvg from "../../../../assets/Connections";
import WalletSvg from "../../../../assets/Wallet";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

const financialReportsSubtabs = [
    {
        label: "Income Statement",
        path: "/incomeStatement",
    },
    {
        label: "Cash Flow",
        path: "/cashFlow",
    },
    {
        label: "Balance Sheet",
        path: "/balanceSheet",
    },
    {
        label: "Revenue",
        path: "/revenue",
    },
    {
        label: "Expenses",
        path: "/expenses",
    },
];

const capitalSubtabs = [
    {
        label: "Advanced Claims",
        path: "/advancedPaymentOnClaims",
    },
    {
        label: "Line Of Credit",
        path: "/lineOfCredit",
    },
    {
        label: "Term Loan",
        path: "/termLoan",
    },
    {
        label: "SBA",
        path: "/sbaPrograms",
    },
];

const onboardingFlowSubtabs = [
    {
        label: "Entities",
        path: "/entities",
    },
    {
        label: "Mappings",
        path: "/mappings",
    },
    {
        label: "Linking",
        path: "/linking",
    },
    {
        label: "Check",
        path: "/check",
    },
];

// TODO: change specific icons for each tab.
function ProviderSelected() {
    const { providerId } = useParams();
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    businessEntityId =
        businessEntityId || (businessEntities && businessEntities[0].id);

    return businessEntityId ? (
        <>
            <NavigationTab
                label="Financials"
                path={`/provider/${providerId}/businessEntity/${businessEntityId}/financialReports`}
                icon={<FinancialReportsSvg fill="white" />}
                subtabs={financialReportsSubtabs}
            />
            <NavigationTab
                label="Capital"
                path={`/provider/${providerId}/capital`}
                icon={<CapitalSvg fill="white" />}
                subtabs={capitalSubtabs}
            />
            <NavigationTab
                label="Treasury"
                path={`/provider/${providerId}/treasury`}
                icon={<WalletSvg fill="white" />}
            />
            <NavigationTab
                label="Connections"
                path={`/provider/${providerId}/connections`}
                icon={<ConnectionsSvg fill="white" />}
            />
            <NavigationTab
                label="Onboarding"
                path={`/provider/${providerId}/onboard`}
                icon={<ConnectionsSvg fill="white" />}
                subtabs={onboardingFlowSubtabs}
            />
        </>
    ) : null;
}

export default ProviderSelected;
