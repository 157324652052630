import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../../PageTitle";
import Button from "../../Button";
import styles from "./styles.module.css";

function HeaderSection({ title, body, buttonText, onClick, graphic }) {
    return (
        <div className={styles.container}>
            <div className={styles.leftSectionContainer}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>{title}</PageTitle>
                </div>
                <div className={styles.body}>{body}</div>
                <Button color="primary" onClick={onClick}>
                    {buttonText}
                </Button>
            </div>
            <div className={styles.rightSectionContainer}>{graphic}</div>
        </div>
    );
}

HeaderSection.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    graphic: PropTypes.node.isRequired,
};

export default HeaderSection;
