import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import ErrorFallback from "../ErrorFallback";

function ErrorBoundary({ children }) {
    const error = useSelector((state) => state.error);

    return error ? (
        <ErrorFallback />
    ) : (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            {children}
        </Sentry.ErrorBoundary>
    );
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
