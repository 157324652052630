import React from "react";
import { useSelector } from "react-redux";
import FirebaseAuthenticated from "../FirebaseAuthenticated";
import Login from "../Login";

function FirebaseAuthInitialized() {
    const isUserSignedIn = useSelector(
        (state) => state.authentication.isUserSignedIn
    );

    return isUserSignedIn ? <FirebaseAuthenticated /> : <Login />;
}

export default FirebaseAuthInitialized;
