import React from "react";
import PageTitle from "../../PageTitle";
import SharedConnections from "../../Connections";
import styles from "./styles.module.css";

function Connections() {
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Connections</PageTitle>
            </div>
            <div className={styles.subtitle}>
                You&apos;ve connected the following accounts. If you have
                additional business bank accounts or corporate cards that
                aren&apos;t listed, please add a connection below.
            </div>
            <SharedConnections />
        </div>
    );
}

export default Connections;
