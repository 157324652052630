import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Button from "../../../../../Button";
import { getCurrencyString } from "../../../../../../utils";
import {
    useCollectPaymentMutation,
    useGetProvidersQuery,
} from "../../../../../../redux/slices/adminApi";
import SectionTitle from "../../../../../SectionTitle";
import styles from "./styles.module.css";

function CollectionTable({
    payments,
    setDisplayedResponse,
    collectedPaymentIds,
    setCollectedPaymentIds,
}) {
    const [collectPayment] = useCollectPaymentMutation();

    const { data: providerIdToProvider } = useGetProvidersQuery();

    const paymentIsCollected = (paymentId) =>
        collectedPaymentIds.includes(paymentId);

    const onClick = (paymentId) => {
        const paymentBody = {
            payment_id: paymentId,
        };
        collectPayment(paymentBody)
            .unwrap()
            .then((response) => {
                setDisplayedResponse(response);
                setCollectedPaymentIds((paymentIds) => [
                    ...paymentIds,
                    paymentId,
                ]);
            })
            .catch((collectPaymentError) => {
                setDisplayedResponse({ collectPaymentError });
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <SectionTitle>Initiate Collection</SectionTitle>
                <table>
                    <thead>
                        <tr>
                            <th>Payment ID</th>
                            <th>Provider</th>
                            <th>Payment Amount</th>
                            <th>Earliest Payment Date</th>
                            <th>Draw ID</th>
                            <th> Collect Payment</th>
                            <th>Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                                <td>
                                    {
                                        providerIdToProvider[
                                            payment.provider_id
                                        ].legal_name
                                    }
                                </td>
                                <td>
                                    {getCurrencyString(payment.payment_amount)}
                                </td>
                                <td>{payment.earliest_payment_date}</td>
                                <td>{payment.draw_id}</td>
                                <td>
                                    <Button
                                        onClick={() => onClick(payment.id)}
                                        disabled={paymentIsCollected(
                                            payment.id
                                        )} // can disable based on oatfi response in the future
                                    >
                                        Collect Payment
                                    </Button>
                                </td>
                                <td>
                                    <Checkbox
                                        checked={paymentIsCollected(payment.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

CollectionTable.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setDisplayedResponse: PropTypes.func.isRequired,
    collectedPaymentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setCollectedPaymentIds: PropTypes.func.isRequired,
};

export default CollectionTable;
