import React from "react";
import ProviderDetails from "../ProviderDetails";
import ConnectPlaidItems from "../ConnectPlaidItems";
import SelectBankAccount from "../SelectBankAccount";
import Complete from "../Complete";
import PLATFORM_STATES from "./states";

export const getState = (provider) => {
    if (!provider) {
        return PLATFORM_STATES.USER_CREATED;
    }
    return provider.platform_state;
};

export const getContent = (state) => {
    switch (state) {
        case PLATFORM_STATES.USER_CREATED:
            return <ProviderDetails />;
        case PLATFORM_STATES.PROVIDER_CREATED:
            return <ConnectPlaidItems />;
        case PLATFORM_STATES.PLAID_CONNECTED:
            return <SelectBankAccount />;
        case PLATFORM_STATES.PLATFORM_PENDING:
            return <Complete />;
        default:
            throw new Error("No content for state");
    }
};
