import React from "react";
import Dot from "../../../Legend/Dot";
import styles from "./styles.module.css";

function Legend() {
    return (
        <div className={styles.container}>
            <div className={styles.itemContainer}>
                <Dot fill="#8D58FF" />
                <div className={`${styles.cashBalance}`}>Cash Balance</div>
            </div>
        </div>
    );
}

export default Legend;
