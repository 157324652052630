import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectDateRange } from "../../../redux/slices/dateRange";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";

function DateRangeWrapper({ children }) {
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const { startDate, endDate } = useSelector(selectDateRange);

    return validUpUntilData && startDate && endDate ? children : null;
}

DateRangeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DateRangeWrapper;
