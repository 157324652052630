import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import useGetBusinessEntitiesQuery from "../queriesAndMutations/useGetBusinessEntitiesQuery";
import useGetReportType from "../useGetReportType";
import getReadableReportType from "../../components/FinancialReports/utils";

function sanitizeFilename(filename) {
    return filename
        .replace(/[^a-zA-Z0-9.-]/g, "_") // Replace invalid characters with underscores
        .replace(/_+/g, "_") // Replace multiple underscores with a single underscore
        .replace(/^_+|_+$/g, "") // Remove leading or trailing underscores
        .substring(0, 255); // Limit to 255 characters
}

const useGetReportFilename = () => {
    const { businessEntityId } = useParams();
    const { data: businessEntities } = useGetBusinessEntitiesQuery();
    const businessEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    const businessEntityName =
        businessEntity.display_name || businessEntity.legal_name;

    const reportType = useGetReportType();
    const readableReportType = getReadableReportType(reportType);

    const formattedDate = dayjs().format("YYYY-MM-DD");

    const filename = `${businessEntityName} ${readableReportType} ${formattedDate}.xlsx`;

    return sanitizeFilename(filename);
};

export default useGetReportFilename;
