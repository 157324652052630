import React from "react";
import PropTypes from "prop-types";
import {
    useGetFinancialReportsValidUpUntilQuery,
    useGetEntriesStartQuery,
} from "../../../../../redux/slices/adminApi";
import UpdateValidUpUntil from "./UpdateValidUpUntil";
import UpdateEntriesStart from "./UpdateEntriesStart";
import styles from "./styles.module.css";

function BusinessEntity({ businessEntity }) {
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });
    const { data: entriesStartData } = useGetEntriesStartQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });

    return (
        <div className={styles.container}>
            <div className={styles.legalName}>{businessEntity.legal_name}</div>
            {entriesStartData ? (
                <UpdateEntriesStart
                    entriesStart={entriesStartData.entries_start}
                    businessEntity={businessEntity}
                />
            ) : null}
            {validUpUntilData ? (
                <UpdateValidUpUntil
                    validUpUntil={validUpUntilData.valid_up_until}
                    businessEntity={businessEntity}
                />
            ) : null}
        </div>
    );
}

BusinessEntity.propTypes = {
    businessEntity: PropTypes.object.isRequired,
};

export default BusinessEntity;
