import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import COLORS from "../../../styles/colors";

const SharedDefsContext = createContext();

export const useSharedDefs = () => useContext(SharedDefsContext);

const defs = (
    <defs>
        <linearGradient id="flychainPurple" x1="0" y1="0" x2="0" y2="1">
            <stop
                offset="19.78%"
                stopColor={COLORS.BLUE_PURPLE}
                stopOpacity={1}
            />
            <stop offset="76.06%" stopColor="#8D58FF" stopOpacity={1} />
        </linearGradient>
    </defs>
);

function SharedDefsContextProvider({ children }) {
    return (
        <SharedDefsContext.Provider value={defs}>
            {children}
        </SharedDefsContext.Provider>
    );
}

SharedDefsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SharedDefsContextProvider;
