import React from "react";
import useGetDataForNetProfitGraphQuery from "../../../../hooks/queriesAndMutations/useGetDataForNetProfitGraphQuery";
import SharedNetProfitGraph from "../../../Graphs/NetProfitGraph";

function NetProfitGraph() {
    const { data: netProfitGraphData } = useGetDataForNetProfitGraphQuery();

    return netProfitGraphData ? (
        <SharedNetProfitGraph data={netProfitGraphData} />
    ) : null;
}

export default NetProfitGraph;
