import React from "react";
import Card from "../../../../Card";
import styles from "./styles.module.css";

function ValuationRange() {
    return (
        <Card showTab={false}>
            <div className={styles.container}>
                <div className={styles.valuationRangeTitle}>
                    Valuation Range
                </div>
                <div className={styles.valuationRange}>
                    $<span className={styles.blur}>1,000,000</span> to $
                    <span className={styles.blur}>2,000,000</span>
                </div>
                <div className={styles.disclaimer}>
                    *The business valuation range provided above is for
                    informational purposes only and does not constitute
                    financial, investment, or legal advice.
                </div>
            </div>
        </Card>
    );
}

export default ValuationRange;
