/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import MuiSlider from "@mui/material/Slider";
import { getCurrencyString } from "../../../../../../utils";
import COLORS from "../../../../../../styles/colors";

const valueLabelFormat = (value) => getCurrencyString(value);

function Slider({ value, onChange, max }) {
    const onValueChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <MuiSlider
            value={value}
            onChange={onValueChange}
            step={10000}
            min={100000}
            max={max}
            size="medium"
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="on"
            sx={{
                color: COLORS.BLUE_PURPLE,
            }}
        />
    );
}

Slider.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired,
};

export default Slider;
