import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../PageTitle";
import useGetMerchantDetailQuery from "../../../../hooks/queriesAndMutations/useGetMerchantDetailQuery";
import MonthlyGraph from "../../../Graphs/MonthlyGraph";
import Summary from "./Summary";
import BackIcon from "../../../BackIcon";
import Table from "./Table";
import SectionTitle from "../../../SectionTitle";
import styles from "./styles.module.css";

function MerchantDetailView() {
    const { merchantId } = useParams();

    const { data: merchantDetail } = useGetMerchantDetailQuery(merchantId);

    return merchantDetail ? (
        <div className={styles.container}>
            <BackIcon />
            <div className={styles.pageTitleContainer}>
                <PageTitle>{merchantDetail.expense_name}</PageTitle>
            </div>
            <div className={styles.summaryContainer}>
                <Summary merchantDetail={merchantDetail} />
            </div>
            <div className={styles.monthlyGraphContainer}>
                <div className={styles.graphTitleContainer}>
                    <SectionTitle>Total Spend Per Month</SectionTitle>
                </div>
                <MonthlyGraph data={merchantDetail.monthly_totals} />
            </div>
            <div className={styles.tableContainer}>
                <Table transactions={merchantDetail.transactions} />
            </div>
        </div>
    ) : null;
}

export default MerchantDetailView;
