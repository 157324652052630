import React from "react";
import useGetMonthlyLedgerTotalsQuery from "../../../../hooks/queriesAndMutations/useGetMonthlyLedgerTotalsQuery";
import SectionTitle from "../../../SectionTitle";
import SharedMonthlyGraph from "../../../Graphs/MonthlyGraph";
import styles from "./styles.module.css";

function MonthlyGraph() {
    const { data } = useGetMonthlyLedgerTotalsQuery();

    return data ? (
        <div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Monthly Totals</SectionTitle>
            </div>
            <SharedMonthlyGraph data={data} />
        </div>
    ) : null;
}

export default MonthlyGraph;
