/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import ReportLine from "./ReportLine";

const getPosition = (index, reportLinesLength) => {
    if (index === 0) {
        if (reportLinesLength === 1) {
            return "single";
        }
        return "top";
    }

    if (index === reportLinesLength - 1) {
        return "bottom";
    }

    return "middle";
};

function ReportBlock({ reportLines, maxRecordCategory }) {
    return (
        <div>
            {reportLines.map((reportLine, i) => {
                const position = getPosition(i, reportLines.length);
                const { name, amount_cents, record_category, ledger_id } =
                    reportLine;

                return (
                    <ReportLine
                        key={i}
                        title={name}
                        recordCategory={record_category}
                        position={position}
                        amountCents={amount_cents}
                        maxRecordCategory={maxRecordCategory}
                        ledgerId={ledger_id}
                    />
                );
            })}
        </div>
    );
}

ReportBlock.propTypes = {
    reportLines: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            ledger_id: PropTypes.string, // not strictly required
            amount_cents: PropTypes.number,
            record_category: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    maxRecordCategory: PropTypes.string.isRequired,
};

export default ReportBlock;
