import React from "react";
import PropTypes from "prop-types";
import Text, { Subheading } from "../../../../../Text";
import { getCurrencyString } from "../../../../../../utils";
import styles from "./styles.module.css";

function BalanceCheckTable({ payments }) {
    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <Subheading>Payments for Collection</Subheading>
                <p>
                    Please review the payments below to ensure they are ready
                    for collection.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <Text>Payment ID</Text>
                            </th>
                            <th>
                                <Text>Provider ID</Text>
                            </th>
                            <th>
                                <Text>Payment amount</Text>
                            </th>
                            <th>
                                <Text>Earliest payment date</Text>
                            </th>
                            <th>
                                <Text>Draw ID</Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>
                                    <Text>{payment.id}</Text>
                                </td>
                                <td>
                                    <Text>{payment.provider_id}</Text>
                                </td>
                                <td>
                                    <Text>
                                        {getCurrencyString(
                                            payment.payment_amount
                                        )}
                                    </Text>
                                </td>
                                <td>
                                    <Text>{payment.earliest_payment_date}</Text>
                                </td>
                                <td>
                                    <Text>{payment.draw_id}</Text>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

BalanceCheckTable.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default BalanceCheckTable;
