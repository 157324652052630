import React from "react";
import useGetMonthlyExpensesByCategoryQuery from "../../../../hooks/queriesAndMutations/useGetMonthlyExpensesByCategoryQuery";
import SharedStackedBarGraph from "../../../Graphs/StackedBarGraph";

function StackedBarGraph() {
    const { data } = useGetMonthlyExpensesByCategoryQuery();

    return data ? <SharedStackedBarGraph data={data} /> : null;
}

export default StackedBarGraph;
