import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import styles from "./styles.module.css";

function PlaidAccount({ account }) {
    const { name, mask, type } = account;

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <Text>{name}</Text>
                {mask && (
                    <div className={styles.mask}>
                        <Text>{`(••••${mask})`}</Text>
                    </div>
                )}
            </div>
            <Text>
                <div className={styles.type}>{type.toUpperCase()}</div>
            </Text>
        </div>
    );
}

PlaidAccount.propTypes = {
    account: PropTypes.object.isRequired,
};

export default PlaidAccount;
