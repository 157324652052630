import React from "react";
import PropTypes from "prop-types";
import { ASSET_CLASS_COLORS, ASSET_CLASSES_READABLE } from "../../constants";
import styles from "./styles.module.css";

function AssetClass({ assetClass }) {
    const circleStyle = { backgroundColor: ASSET_CLASS_COLORS[assetClass] };
    return (
        <div className={styles.container}>
            <span className={styles.circle} style={circleStyle} />
            <span className={styles.assetClass}>
                {ASSET_CLASSES_READABLE[assetClass]}
            </span>
        </div>
    );
}

AssetClass.propTypes = {
    assetClass: PropTypes.oneOf(Object.keys(ASSET_CLASSES_READABLE)).isRequired,
};

export default AssetClass;
