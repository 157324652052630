import React, { useState } from "react";
import Content from "../../../Content";
import SideBar from "../../../SideBar";
import Tabs from "./Tabs";
import COLLECTION_FLOW_STATES from "./states";
import getContent from "./utils";

// TODO: delete mockPayment below once we're done testing CollectionFlow

// const mockPayment = {
//     id: "43f43386-643e-4900-b210-ea7a6a3081ed",
//     provider_id: "fc423509-656d-4448-a3ad-dc0b7848e94d",
//     payment_amount: 210667,
//     earliest_payment_date: "2021-08-01",
//     draw_id: "8fba68f8-9ae8-4af9-871b-81fc875acf1b",
// };

function CollectionFlow() {
    const [payments, setPayments] = useState([]);
    const [collectionFlowState, setCollectionFlowState] = useState(
        COLLECTION_FLOW_STATES.SELECTION
    );

    return (
        <>
            {/* TODO: for UI purposes remake this into a milestone component that is horizontal */}
            <SideBar showLogo={false}>
                <Tabs collectionFlowState={collectionFlowState} />
            </SideBar>
            <Content>
                {getContent(
                    payments,
                    setPayments,
                    collectionFlowState,
                    setCollectionFlowState
                )}
            </Content>
        </>
    );
}

export default CollectionFlow;
