import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Content from "../../Content";
import SideBar from "../../SideBar";
import NavigationTab from "../../SideBar/NavigationTab";
import CapitalSvg from "../../../assets/Capital";
import FinancialReportsSvg from "../../../assets/FinancialReports";
import ProviderSelected from "./ProviderSelected";

// TODO: change specific icons for each tab.
function SideBarAndContent() {
    const { providerId } = useParams();
    return (
        <>
            <SideBar>
                <NavigationTab
                    path="/providers"
                    label="Providers"
                    icon={<CapitalSvg fill="white" />}
                />
                {providerId ? (
                    <ProviderSelected />
                ) : (
                    <NavigationTab
                        label="Bookkeeping Dates"
                        path="/bookkeepingDates"
                        icon={<FinancialReportsSvg fill="white" />}
                    />
                )}
            </SideBar>
            <Content>
                <Outlet />
            </Content>
        </>
    );
}

export default SideBarAndContent;
