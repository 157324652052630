import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const getColor = (color) => {
    switch (color) {
        case "green":
            return "#DBEAC3";
        case "grey":
            return "#F2F2F2";
        case "red":
            return "#FFD6CB";
        default:
            throw new Error("Invalid color specified");
    }
};

function Badge({ color, children }) {
    const containerStyle = {
        backgroundColor: getColor(color),
    };
    return (
        <div className={styles.container} style={containerStyle}>
            {children}
        </div>
    );
}

Badge.propTypes = {
    color: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Badge;
