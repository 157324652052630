import React from "react";
import SharedConnections from "../../../FinancialReports/Connections";
import SelectedProvider from "../SelectedProvider";

function Connections() {
    return (
        <div>
            <SelectedProvider />
            <SharedConnections />
        </div>
    );
}

export default Connections;
