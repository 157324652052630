/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState: {
        isFirebaseAuthInitialized: false,
        isUserSignedIn: false,
    },
    reducers: {
        setIsFirebaseAuthInitialized: (state, action) => {
            state.isFirebaseAuthInitialized = action.payload;
        },
        setIsUserSignedIn: (state, action) => {
            state.isUserSignedIn = action.payload;
        },
    },
});

export const { setIsFirebaseAuthInitialized, setIsUserSignedIn } =
    authenticationSlice.actions;

export default authenticationSlice.reducer;
