import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Text({ children }) {
    return <div className={styles.text}>{children}</div>;
}

Text.propTypes = {
    children: PropTypes.node.isRequired,
};

export function Header({ children }) {
    return (
        <Text>
            <div className={styles.header}>{children}</div>
        </Text>
    );
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
};

export function Subheading({ children }) {
    return (
        <Text>
            <div className={styles.subheading}>{children}</div>
        </Text>
    );
}

Subheading.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Text;
