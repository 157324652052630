/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "../../PageTitle";
import Line from "./Line";
import Button from "../../Button";
import DrawsTable from "./DrawsTable";
import RequestFundingModal from "./RequestFundingModal";
import DrawModal from "./DrawModal";
import SectionTitle from "../../SectionTitle";
import styles from "./styles.module.css";

function AdvancedPaymentOnClaims({ data }) {
    const [showRequestFundingModal, setShowRequestFundingModal] =
        useState(false);

    const requestFundingButtonOnClick = () => {
        setShowRequestFundingModal(true);
    };

    const requestFundingModalOnClose = () => {
        setShowRequestFundingModal(false);
    };

    const [showDrawModal, setShowDrawModal] = useState(false);

    const [drawIndex, setDrawIndex] = useState(null);

    const drawsTableOnClick = (i) => {
        setDrawIndex(i);
        setShowDrawModal(true);
    };

    const drawModalOnClose = () => {
        setShowDrawModal(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Advanced Payment on Claims</PageTitle>
            </div>
            <Line
                outstandingBalanceCents={data.line_outstanding_balance_cents}
                availableBalanceCents={data.line_available_balance_cents}
            />
            <div className={styles.buttonContainer}>
                <Button
                    onClick={requestFundingButtonOnClick}
                    disabled={data.line_available_balance_cents === 0}
                >
                    Request funding
                </Button>
            </div>
            {showRequestFundingModal && (
                <RequestFundingModal
                    onClose={requestFundingModalOnClose}
                    availableBalanceCents={data.line_available_balance_cents}
                />
            )}
            <div className={styles.drawsContainer}>
                <div className={styles.sectionTitleContainer}>
                    <SectionTitle>Your draws</SectionTitle>
                </div>
                <DrawsTable
                    draws={data.draws_for_display}
                    onClick={drawsTableOnClick}
                    includeSeeRepaymentsColumn
                />
            </div>
            {showDrawModal && (
                <DrawModal
                    draw={data.draws_for_display[drawIndex]}
                    onClose={drawModalOnClose}
                />
            )}
        </div>
    );
}

AdvancedPaymentOnClaims.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AdvancedPaymentOnClaims;
