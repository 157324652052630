import React from "react";
import ValuationFactors from "./ValuationFactors";
import ImproveFinancialHealth from "./ImproveFinancialHealth";
import ValuationEstimate from "./ValuationEstimate";
import ValuationsCall from "./ValuationsCall";
import styles from "./styles.module.css";

function Valuations() {
    return (
        <div className={styles.container}>
            <ValuationEstimate />
            <ValuationsCall />
            <ValuationFactors />
            <ImproveFinancialHealth />
        </div>
    );
}

export default Valuations;
