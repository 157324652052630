import React from "react";
import PageTitle from "../../../PageTitle";
import ValuationRange from "./ValuationRange";
import Badge from "../../../Badge";
import ValuationExplanation from "./ValuationExplanation";
import styles from "./styles.module.css";

function ValuationEstimate() {
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Valuation Range</PageTitle>
                <div className={styles.badgeContainer}>
                    <Badge color="green">
                        <div className={styles.badgeText}>Coming Soon</div>
                    </Badge>
                </div>
            </div>
            <div className={styles.subheading}>
                This is our best estimate of the valuation range of your
                business. If you&apos;d like a more comprehensive valuation, you
                can schedule a free consultation below with one of our M&A
                experts.
            </div>
            <div className={styles.cardsContainer}>
                <div className={styles.valuationRangeContainer}>
                    <ValuationRange />
                </div>
                <ValuationExplanation />
            </div>
        </div>
    );
}

export default ValuationEstimate;
