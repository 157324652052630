import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import Button from "../../../Button";
import SectionTitle from "../../../SectionTitle";
import styles from "./styles.module.css";

function CapitalProduct({ title, body, onClick, comingSoon }) {
    const content = (
        <div className={styles.container}>
            <SectionTitle>{title}</SectionTitle>
            <div className={styles.body}>{body}</div>
            {onClick ? (
                <div className={styles.buttonContainer}>
                    <Button color="secondary" onClick={onClick}>
                        Learn More
                    </Button>
                </div>
            ) : null}
        </div>
    );

    return comingSoon ? (
        <Card showTab tabText="Coming Soon" tabColor="green">
            {content}
        </Card>
    ) : (
        <Card showTab={false}>{content}</Card>
    );
}

CapitalProduct.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    comingSoon: PropTypes.bool,
};

CapitalProduct.defaultProps = {
    onClick: undefined,
    comingSoon: false,
};

export default CapitalProduct;
