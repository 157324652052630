import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../../../Card";
import styles from "./styles.module.css";

function Card({ title, subtitle }) {
    return (
        <SharedCard showTab={false}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
        </SharedCard>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default Card;
