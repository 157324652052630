import React from "react";
import { Header, Subheading } from "../Text";
import styles from "./styes.module.css";

function Mobile() {
    return (
        <div className={styles.container}>
            <Header>
                <div className={styles.header}>
                    Our application is currently only supported on desktop.
                </div>
            </Header>
            <Subheading>
                <div className={styles.subheading}>
                    Our application currently doesn&apos;t support mobile or
                    tablet. Please try again from a desktop computer.
                </div>
            </Subheading>
        </div>
    );
}

export default Mobile;
