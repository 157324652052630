import React from "react";
import useGetDataForNetCashFlowGraphQuery from "../../../../hooks/queriesAndMutations/useGetDataForNetCashFlowGraphQuery";
import SharedNetCashFlowGraph from "../../../Graphs/NetCashFlowGraph";

function NetCashFlowGraph() {
    const { data: netCashFlowGraphData } = useGetDataForNetCashFlowGraphQuery();

    return netCashFlowGraphData ? (
        <SharedNetCashFlowGraph data={netCashFlowGraphData} />
    ) : null;
}

export default NetCashFlowGraph;
