import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectDateRange,
    serializeDateRange,
} from "../../../redux/slices/dateRange";
import { useGetDataForFinancialReportsSummaryQuery as useAdminGetDataForFinancialReportsSummaryQuery } from "../../../redux/slices/adminApi";
import { useGetDataForFinancialReportsSummaryQuery as useProviderGetDataForFinancialReportsSummaryQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetDataForFinancialReportsSummaryQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const dateRange = useSelector(selectDateRange);

    if (isAdminDashboard) {
        return useAdminGetDataForFinancialReportsSummaryQuery({
            providerId,
            businessEntityId,
            ...serializeDateRange(dateRange),
        });
    }

    return useProviderGetDataForFinancialReportsSummaryQuery({
        businessEntityId,
        ...serializeDateRange(dateRange),
    });
};

export default useGetDataForFinancialReportsSummaryQuery;
