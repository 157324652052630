import React from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import SectionTitle from "../../SectionTitle";
import ReportBlock from "../ReportBlock";
import ExportReportButton from "../ExportReportButton";
import useGetDataForBalanceSheetQuery from "../../../hooks/queriesAndMutations/useGetDataForBalanceSheetQuery";
import styles from "./styles.module.css";

function BalanceSheet() {
    const { data: balanceSheetData } = useGetDataForBalanceSheetQuery();

    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <div className={styles.controlsContainer}>
                <SectionTitle>Balance Sheet</SectionTitle>
                <ExportReportButton />
            </div>
            {balanceSheetData
                ? balanceSheetData.report_blocks.map((reportLines, index) => (
                      <div className={styles.reportBlockContainer} key={index}>
                          <ReportBlock
                              reportLines={reportLines}
                              maxRecordCategory="ledger"
                          />
                      </div>
                  ))
                : null}
        </div>
    );
}

export default BalanceSheet;
