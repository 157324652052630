import { useSelector } from "react-redux";

function useGlobalLoading() {
    const isLoading = useSelector((state) => {
        const queriesAndMutations = {
            ...state.api.queries,
            ...state.adminApi.queries,
            ...state.api.mutations,
            ...state.adminApi.mutations,
        };

        const isQueryOrMutationLoading = Object.values(
            queriesAndMutations
        ).some((queryOrMutation) => queryOrMutation.status === "pending");

        return isQueryOrMutationLoading;
    });
    return isLoading;
}

export default useGlobalLoading;
