import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function CollectPaymentLog({ displayedResponse }) {
    return (
        <div className={styles.textbox}>
            <h3>Collect Payment Response:</h3>
            <p>{JSON.stringify(displayedResponse)}</p>
        </div>
    );
}

CollectPaymentLog.propTypes = {
    displayedResponse: PropTypes.object.isRequired,
};

export default CollectPaymentLog;
